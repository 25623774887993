import React from 'react';
import {Image, Row, Col, Input, Button} from "antd";
import {ArrowRightOutlined} from '@ant-design/icons';
import {marginTop} from '../../common/script'
import SelectModelDialog from "../../dialog/web/SelectModelDialog";
import {getDataFromServer} from "../../api/request";
import {judgeParamsError} from "../../common/utils";


export default class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight - 208,
            selectModelStatus: false,
            userName: '',
            passWord: '',
            loading: false,
            fastStatus: false,
            tailStatus: false,
        }
        this.navigationToMainPage = this.navigationToMainPage.bind(this);
    }

    inputChange(res, type) {
        this.setState({
            [type]: res.target.value
        })
    }

    navigationToMainPage(e) {

        if (judgeParamsError(this.state.userName, "account is not null !")) {
            return;
        }

        if (judgeParamsError(this.state.passWord, "password is not null !")) {
            return;
        }

        getDataFromServer('sysCustomerUser/login', {
            username: this.state.userName,
            password: this.state.passWord,
        }, () => {
            this.setState({
                loading: true,
                fastStatus: false,
                tailStatus: false,
            })
        }, () => {
        }).then(res => {
            window.sessionStorage.setItem('msg', JSON.stringify({
                isLogined: true,
                token: res.value.token,
                id: res.value.id,
                fullname: res.value.fullname,
                qinniutoken: res.value.qinniutoken,
                joinId: res.value.joinId,
                isOpenGuide: res.value.isOpenGuide,
                username: res.value.username, //2020-04-20 夏总(在没有用户名的时候显示账号)
                phone: res.value.phone,
                mobileCode: res.value.mobileCode,
            }))
            this.loginByTail();
            this.setState({
                fastStatus: true,
            })
        }).catch(error => {
            if (error.indexOf("网络异常,请稍后重试") === -1) {
                this.loginByTail();
            }
        })
    }

    loginByTail() {
        getDataFromServer('customerUserForWeb/login', {
            mail: this.state.userName,
            pwd: this.state.passWord,
        }, () => {
        }, () => {
            this.setState({
                loading: false
            })
        }, 'tail').then(res => {
            window.localStorage.setItem(
                'msg',
                JSON.stringify({
                    isLogined: true,
                    id: res.value.id,
                    token: res.value.token,
                    name: res.value.name,
                    qinniutoken: res.value.qinniutoken,
                    mobile: res.value.mobile,
                    mobileCode: res.value.mobileCode,
                    wechat: res.value.wechat,
                    qq: res.value.qq,
                    supplyId: res.value.supplyId,
                    source: res.value.source, //(0:TAIL 1:COD)
                    authCreateSubAccount: res.value.authCreateSubAccount, //授权主账号创建子账号(0：启用 1：禁用)
                    highestAuthority: res.value.highestAuthority,
                })
            )
            this.setState({
                tailStatus: true,
                selectModelStatus: true
            })
        }).catch(error => {
            if (this.state.fastStatus){
                this.setState({
                    selectModelStatus: true
                })
            }
        })
    }

    render() {
        return (
            <div style={{position: 'relative'}}>
                {/*<iframe src={'https://fast.mrcargo.com'}/>*/}
                <div style={{bottom: 0, left: 0, position: 'fixed'}}>
                    <Image width={1084} height={740}
                           preview={false}
                           src={'images/bg_login_left_bottom.webp'}/>
                </div>
                <div style={{top: 0, position: 'fixed', left: '20%'}}>
                    <Image width={304} height={193}
                           preview={false}
                           src={'images/bg_login_left_top.webp'}/>
                </div>
                <div style={{top: 0, position: 'fixed', right: '0'}}>
                    <Image width={515} height={451}
                           preview={false}
                           src={'images/bg_login_right_top.webp'}/>
                </div>

                <div style={{bottom: 0, position: 'fixed', right: '10%'}}>
                    <Image width={279} height={62}
                           preview={false}
                           src={'images/bg_login_right_bottom.webp'}/>
                </div>
                <div style={{padding: '104px 158px'}}>
                    <Row style={Object.assign(centerBlockView, {height: this.state.height})} wrap={false}>
                        <Col className={'flex-row '} span={15} style={centerLeftView}>
                            <Image width={720} height={468} src={'/images/bg_login_left.webp'} preview={false}/>
                        </Col>
                        <Col className={'flex-center'} offset={1} span={7} style={centerRightView}>
                            <div style={centerRightBgView}/>
                            <div style={{zIndex: 2}}>
                                <div className={'font-size40 font-bold color263238'}>Login</div>
                                <div>Welcome back！</div>
                                <Input className={'font-size24'} size={'large'} style={inputView(7)}
                                       disabled={this.state.loading}
                                       placeholder="Enter your account" value={this.state.userName}
                                       onChange={(e) => this.inputChange(e, 'userName')}/>
                                <Input className={'font-size24'} size={'large'} type={'password'} style={inputView(3)}
                                       disabled={this.state.loading}
                                       placeholder="Enter your password" value={this.state.passWord}
                                       onChange={(e) => this.inputChange(e, 'passWord')}
                                       onPressEnter={this.navigationToMainPage}/>
                                <Button type="link" size={'large'}>
                                    Forget Password？Click me
                                </Button>
                                <br/>
                                <Button className={'font-size24'} style={loginBtn} type="primary"
                                        onClick={this.navigationToMainPage} loading={this.state.loading}>
                                    LOGIN <ArrowRightOutlined/>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <SelectModelDialog visible={this.state.selectModelStatus} inModelStatus={this.state.tailStatus}
                                   outModelStatus={this.state.fastStatus} onClose={(res) => {
                    this.setState({
                        selectModelStatus: false
                    })
                }}/>
            </div>
        );
    }
}


const centerBlockView = {
    background: '#FFFFFF',
    border: '8px solid #0097a7',
    borderRadius: '64px',
    width: 'auto',
    height: 'auto'
}

const centerLeftView = {
    height: '100%',
    background: '#fff',
    borderTopLeftRadius: '64px',
    borderBottomLeftRadius: '58px',
    justifyContent: 'flex-end',
    display: 'flex',
}

const centerRightBgView = {
    background: '#fff',
    filter: 'blur(100px)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0
}

const centerRightView = {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
}

function inputView(marginTopNum) {
    return Object.assign({
        width: '400px',
        height: '64px',
        borderRadius: '8px',
    }, marginTop(marginTopNum))
}


const loginBtn = {
    width: '400px',
    height: '64px',
    borderRadius: '16px',
    marginTop: '56px',
}

