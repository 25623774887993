import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";

export const customerHelper = {
    getDataList(that,pageIndex){
        let url;
        if (that.state.addressType === 'send'|| that.state.addressType === 'delivery') {
            url = 'memberSenderAddress/getMemberSenderAddressList';
        } else if (that.state.addressType === 'consignee') {
            url = 'memberConsigneeAddress/getMemberConsigneeAddressList';
        } else {
            return;
        }
        return getDataFromServer(url, {
            token: localCache.token,
            pageSize: 10,
            pageIndex: pageIndex,
            joinId:localCache.joinId,
            senderCountryId:that.props.countryId || '',
        }, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        })
    },

    delAddress(that,id){
        let url, params = {};
        if (that.state.addressType === 'send') {
            url = 'memberSenderAddress/deleteMemberSenderAddress';
            params = {
                token: localCache.token,
                memberSenderAddressId: id,
            }
        } else {
            url = 'memberConsigneeAddress/deleteMemberConsigneeAddress';
            params = {
                token: localCache.token,
                memberConsigneeAddressId: id,
            }
        }
        return getDataFromServer(url, params, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        })
    }
}
