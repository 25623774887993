import React from 'react';
import {Popup, CheckList, SearchBar, Empty} from 'antd-mobile';
import {marginLeft, marginRight, marginTop} from "../../common/script";
import {getDataFromServer} from "../../api/request";
import {hideLoading, showLoading} from "../../common/utils";
import i18n from "./../../config/i18n";

export default class SelectCountryCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataList: [],//国家列表
        }
    }

    componentDidMount() {
        this.getDataList();
    }

    getDataList() {
        getDataFromServer('system/getAllMobileCode', {}, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            this.setState({
                dataList: res.value
            })
        })
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
            })
        }
    }

    hideVisibleClick(selectInfo) {
        this.props.onClose(selectInfo);
    }

    searchCustomerByMobile(customer) {
        let dataList = this.state.dataList;
        let hidden;
        let searchInfo;
        for (let i = 0; i < dataList.length; i++) {
            searchInfo = `${dataList[i].mobileCode}-${dataList[i].countryName}`
            if (customer) {
                hidden = searchInfo.indexOf(customer) === -1
            } else {
                hidden = false;
            }
            dataList[i].hidden = hidden;
        }
        this.setState({
            dataList: dataList
        })
    }

    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={bodyView}
                   stopPropagation={['enter']}
            >
                <SearchBar style={Object.assign(marginTop(2), marginLeft(1), marginRight(1))} placeholder={i18n.t('searchContent')}
                        showCancelButton onSearch={(e) => this.searchCustomerByMobile(e)}/>
                <CheckList style={contentView} onChange={(selectInfo) => {
                    this.hideVisibleClick(selectInfo)
                }}>
                    {
                        this.state.dataList.map((item, index) => {
                            return item.hidden ? '' : <CheckList.Item
                                key={index}
                                value={`${item.mobileCode}`}>
                                {item.mobileCode}-{i18n.language === 'en'?item.countryEnName:item.countryName}
                            </CheckList.Item>
                        })
                    }
                    {
                        this.state.dataList.length === 0 ? <Empty description={i18n.t('empty')}/> : <div/>
                    }
                </CheckList>

            </Popup>
        )
    }
}


const contentView = {
    height: window.innerHeight * 0.4,
    overflowY: 'scroll',
    marginTop: '10px'
}

const bodyView = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    maxHeight:"60vh",
    minHeight: '40vh',
}

