import React from 'react';
import {Popup, Input, Button, Stepper, ImageUploader} from 'antd-mobile';
import {itemGoodsView, itemGoodsViewStyle, marginLeft, marginTop, scrollNowView} from "../../common/script";
import {dealCargoInfo, judgeParamsError, uploadImg} from "../../common/utils";
import {ContentOutline} from 'antd-mobile-icons'
import SelectGoods from "./SelectGoods";
import {cargoInfo} from "../../api/dataCommon";
import i18n from "./../../config/i18n";

export default class CargoEditDialog extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cargoInfo: JSON.parse(JSON.stringify(cargoInfo))
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            let updateInfo = {
                visible: this.props.visible,
            }
            if (this.props.visible) {
                if (this.props.cargoInfo.cargoNameEn) {
                    updateInfo.cargoInfo = this.props.cargoInfo;
                } else {
                    updateInfo.cargoInfo = JSON.parse(JSON.stringify(cargoInfo))
                }
            }
            this.setState(updateInfo)
        }
    }

    hideVisibleClick(confirm) {
        if (confirm) {
            if (judgeParamsError(this.state.cargoInfo.cargoNameEn, i18n.t('cargoNameEnNotNull'))) {
                return;
            }
            if (judgeParamsError(this.state.cargoInfo.cargoQuantity, i18n.t('cargoQuantityNotNull'))) {
                return;
            }
            if (judgeParamsError(this.state.cargoInfo.cargoWeight, i18n.t('singleWeightNotNull'))) {
                return;
            }
            if (judgeParamsError(this.state.cargoInfo.cargoPrice, i18n.t('UnitPriceNotNull'))) {
                return;
            }
            this.props.onClose(JSON.parse(JSON.stringify(this.state.cargoInfo)));
        } else {
            this.props.onClose();
        }
    }

    inputChange(res, type) {
        let cargoInfo = this.state.cargoInfo;
        if (res.target) {
            cargoInfo[type] = res.target.value
        } else {
            cargoInfo[type] = res
        }
        this.setState({
            cargoInfo: cargoInfo
        })
    }


    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={{
                       borderTopLeftRadius: '8px',
                       borderTopRightRadius: '8px',
                       height: "57vh",
                       paddingLeft: '8px',
                       paddingRight: '8px',
                       paddingTop: '8px',
                       display: 'flex',
                       flexDirection: 'column',
                   }}
                   stopPropagation={['enter']}
            >
                <div style={scrollNowView}>
                    {
                        itemGoodsView(i18n.t('goodsName'), <div className={'flex-row'}>
                            <Input className={'mobile-input-text-end'} placeholder={i18n.t('input')}
                                   clearable
                                   value={this.state.cargoInfo.cargoNameEn}
                                   onChange={(e) => this.inputChange(e, 'cargoNameEn')}/>
                            <ContentOutline style={marginLeft(1)} onClick={() => {
                                this.setState({
                                    showSelectGoods: true
                                })
                            }}/>
                        </div>)
                    }
                    {
                        itemGoodsView(i18n.t('quantity'), <div className={'flex-row justify-end'}>
                            <Stepper
                                value={this.state.cargoInfo.cargoQuantity}
                                onChange={value => {
                                    this.inputChange(value, 'cargoQuantity')
                                }}
                            />
                        </div>)
                    }
                    {
                        itemGoodsView(i18n.t('singleWeight'), <div className={'flex-row'}>
                            <Input className={'mobile-input-text-end'} type={'number'}
                                   placeholder={i18n.t('input')}
                                   value={this.state.cargoInfo.cargoWeight}
                                   clearable onChange={(e) => this.inputChange(e, 'cargoWeight')}/>
                            <div style={marginLeft(1)}>Kg</div>
                        </div>)
                    }
                    {
                        itemGoodsView(i18n.t('unitPrice'), <Input className={'mobile-input-text-end'} type={'number'}
                                                                  placeholder={i18n.t('input')}
                                                                  value={this.state.cargoInfo.cargoPrice}
                                                                  clearable
                                                                  onChange={(e) => this.inputChange(e, 'cargoPrice')}/>)
                    }
                    <div style={itemGoodsViewStyle}>
                        <div>{i18n.t('productPicture')}</div>
                        <ImageUploader
                            style={marginTop(1)}
                            value={this.state.cargoInfo.imgUrl.url ? [this.state.cargoInfo.imgUrl] : []}
                            onChange={(e) => {
                                let cargoInfo = this.state.cargoInfo;
                                if (e.length > 0) {
                                    cargoInfo.imgUrl = e[0];
                                } else {
                                    cargoInfo.imgUrl = '';
                                }
                                this.setState({
                                    cargoInfo: cargoInfo
                                })
                            }}
                            upload={async (res) => {
                                let response = await uploadImg(res)
                                return {
                                    url: `https://qiniu.mrcargo.com/${response.data.key}`,
                                }
                            }
                            }
                        />
                    </div>
                </div>
                <div className={'flex-row justify-center'}>
                    <Button color={'primary'} fill={'outline'} style={btnView(0)}
                            onClick={() => this.hideVisibleClick(false)}>{i18n.t('back')}</Button>
                    <Button color={'primary'} style={btnView(1)}
                            onClick={() => this.hideVisibleClick(true)}>{i18n.t('submit')}</Button>
                </div>
                <SelectGoods visible={this.state.showSelectGoods} onClose={(goodsInfo) => {
                    let updateInfo = {showSelectGoods: false}
                    if (goodsInfo) {
                        updateInfo.cargoInfo = dealCargoInfo(goodsInfo)
                        updateInfo.cargoInfo.cargoQuantity = 1;
                    }
                    this.setState(updateInfo);
                }}>
                </SelectGoods>
            </Popup>
        )
    }
}

function btnView(marginLeft) {
    return {
        width: '40%',
        marginBottom: '8px',
        marginTop: '10px',
        marginLeft: `${(marginLeft || 0) * 8}px`
    }
}
