import {message} from "antd";
import axios from "axios";
import {Toast} from "antd-mobile";
import {ExclamationCircleOutline} from 'antd-mobile-icons';
import {localCache} from "../api/localCache";
import {INJECT_INFO, SUBMIT_CODE} from "../api/dataCommon";


export function judgeParamsError(params, notifyMessage) {
    if (!params) {
        message.warning(notifyMessage);
        return true;
    } else {
        return false;
    }
}

export async function uploadImg(tempFilePath) {
    let formData = new FormData();
    formData.append('file', tempFilePath);
    formData.append('token', localCache.qiNiuToken);
    formData.append('fname', undefined);
    let response = await axios({
        url: 'https://upload.qiniup.com/',
        method: 'POST',
        data: formData,
        timeout: 0, //无超时
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    })
    return response;
}

export function showLoading() {
    Toast.show({
        icon: 'loading',
        content: '加载中…',
        duration: 0
    })
}

export function hideLoading() {
    Toast.clear();
}

export function showToast(message, type) {
    if (type === 'warning') {
        Toast.show({
            icon: <ExclamationCircleOutline/>,
            content: message
        })
    } else {
        Toast.show({
            icon: type,
            content: message,
        })
    }
}

export function getDeviceType() {
    if (/Android|webOS|iPhone|iPod|BlackBerry|XiaoMi/i.test(navigator.userAgent)) {
        return 'mobile';
    } else {
        return 'web'
    }
}

export function getDate() {
    let date = new Date();
    let month = date.getMonth() + 1;
    let day = date.getDay();
    return date.getFullYear() + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
}

/*判断客户端*/
export const judgeClient = () => {
    let u = navigator.userAgent;
    console.log('u', u)
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;   //判断是否是 android终端
    let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);     //判断是否是 iOS终端
    if (isAndroid) {
        return 'Android';
    } else if (isIOS) {
        return 'IOS';
    } else {
        return 'PC';
    }
}

/*
render(){
  return (
    <Input allowClear maxLength={20} placeholder="请输入手机号" onBlur={this.handleBlur}/>
  )
}
*/

export function handleBlur(windowHeight) {
    let scrollDiv = document.getElementById('scrollDiv');
    let styleArr = scrollDiv.style.cssText.split(";");
    for (let i = 0; i < styleArr.length; i++) {
        if (styleArr[i].split(":")[0].trim() === 'height') {
            let heightStyle = `height:${windowHeight}px`;
            if (styleArr[i].split(":")[1].trim() === heightStyle) {
                return;
            }
            styleArr[i] = heightStyle;
        }
    }
    scrollDiv.setAttribute('style', styleArr.join(";"));
    scrollDiv.style.cssText = styleArr.join(";")
}

export function judgeNumberInput(value, min = 1, defaultValue = '1') {
    if (value !== '' && (value === '-' || Number(value) < min)) {
        return defaultValue;
    } else {
        return value;
    }
}

export function encryptionInfo(info) {
    let result = "";
    let key = localCache.joinId;
    for (let i = 0; i < info.length; i++) {
        let tempNum = info.charCodeAt(i) + key.charCodeAt(i) || 'a';
        result += tempNum + ",";
    }
    if (result.substring(result.length - 1, result.length) === ",")
        result = result.substring(0, result.length - 1);
    return window.btoa(result);
}


export function decryptInfo(info) {
    if (info) {
        let result = "";
        let key = localCache.joinId;
        info = window.atob(info).split(',');
        for (let i = 0; i < info.length; i++) {
            let tempNum = info[i] - key.charCodeAt(i) || 'a';
            result += String.fromCharCode(tempNum);
        }
        return result;
    }
    return ''
}

/**
 * 商品信息来自模板筛选
 * 商品信息优化
 * 去除无用数据
 */
export function dealCargoInfo(cargoInfo) {
    if (cargoInfo) {
        let returnCargo = JSON.parse(JSON.stringify(cargoInfo));
        returnCargo.imgUrl = {url: cargoInfo.imgUrl};
        delete returnCargo.token
        delete returnCargo.id
        delete returnCargo.joinId
        delete returnCargo.qinniutoken
        delete returnCargo.updateTime
        delete returnCargo.createTime
        delete returnCargo.status
        return returnCargo;
    }
    return cargoInfo;
}


/**
 * 判断单号类型
 * @param code 需要判断的单号的类型
 * @return {code: *, type: number} 单号类型 包裹码0，订单id:2，卡果单号：3，快递单号、转运单号 4 确认码 5
 */
export function judgeCodeType(code) {
    let result = {code: code, type: 4};
    let httpTel = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\\.,@?^=%&amp;:/~\\+#]*[\w\-\\@?^=%&amp;/~\\+#])?/;
    if (httpTel.test(code)) {
        result.code = code.split("?qrcodeId=")[1];
        result.type = 2;
    } else if (code.indexOf("VEX") !== -1) {
        result.type = 3;
    } else if (code.length === 6) {
        result.type = 0;
    } else if (SUBMIT_CODE.indexOf(code) !== -1) {
        result.type = 5;
    }
    return result;
}

/**
 * 设置网页标题
 * @param title 标题内容
 */
export function setDocumentTitle(title) {
    document.title = title || '1'
}

/**
 * 获取注入对象
 *
 */
export function getInjectedObject() {
    let object = window.injectedObject;
    if (object && object.getIdentity && object.getIdentity() === INJECT_INFO) {
        return object;
    }else{
        return null;
    }
}
