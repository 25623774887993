import React from 'react';
import {InfiniteScroll, Card, Grid, Divider, Tabs, Button, Dialog, Image, Badge} from 'antd-mobile';
import {pageColorView, scrollNowView, marginLeft, marginRight, marginTop} from './../../common/script'
import {RightOutline} from 'antd-mobile-icons'
import {localCache} from "../../api/localCache";
import {getDataFromServer} from "../../api/request";
import ReceivingOrder from "../../dialog/mobile/ReceivingOrder";
import {Translation} from "react-i18next";
import {getInjectedObject, hideLoading, judgeCodeType, showLoading} from "../../common/utils";

let tabIndex = 'direct';
let hasNext = false;
let pageIndex = 1;
let loading = false;
let dataList = [];
let scrollLabel = 'scrollDiv';
let scrollLocation = 0;

window.setReceivingScanCode = function (codeValue) {
    console.log(codeValue)
    if (window.receivingCallBack !== undefined) {
        window.receivingCallBack.setScanCode(codeValue)
    }
}

window.setReceivingCallBack = function (callback) {
    window.receivingCallBack = callback
}

//揽收列表
export default class ReceivingOrderPage extends React.Component {

    injectObject = getInjectedObject();

    constructor(props) {
        super(props);
        this.state = {
            tabList: tabList,
            dataList: dataList,
            tabIndex: tabIndex,//
            receivingStatus: false,//接收订单弹窗显示状态
            receivingOrderId: '',//需要接收订单的id
            receivingServicePlatform: '',//需要接收订单的产品
            directCount: null,//直发订单待揽件数
            collectCount: 0,//集运订单待揽件数
        }
    }

    componentWillMount() {
        window.setReceivingCallBack(this)
    }

    componentDidMount() {
        if (scrollLocation !== 0) {
            let divObj = document.getElementById(scrollLabel);
            if (divObj) {
                divObj.scrollTop = scrollLocation
            }
        } else {
            this.loadMoreData(true)
        }
    }

    componentWillUnmount() {
        dataList = this.state.dataList;
        tabIndex = this.state.tabIndex;
        scrollLocation = document.getElementById(scrollLabel).scrollTop
        window.setReceivingCallBack(null)
    }

    setScanCode(codeValue) {
        let scanResult = judgeCodeType(codeValue);
        getDataFromServer('orderAudit/sweepCode', {
            token: localCache.token,
            orderCode: scanResult.code,
            type: scanResult.type,//type类型：快递单号:1，订单id:2 ，3卡果号，4转运单号
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            if (res.value) {
                this.navigationToDetail(res.value.id, res.value.orderType === 2 ? 'collect' : 'direct')
            }
        })
    }

    async loadMoreData(firstPage) {
        let response;
        for (let tabItem of this.state.tabList) {
            response = await this.getPageData(tabItem.key, firstPage);
            if (!response) {
                return;
            }
        }
    };

    async getPageData(requestType, firstPage) {
        let url;
        let setPageData = this.state.tabIndex === requestType;

        let updateInfo = {};
        if (setPageData) {
            if (firstPage || pageIndex === 1) {
                hasNext = true;
                loading = false;
                pageIndex = 1;
                updateInfo.dataList = [];
            } else {
                updateInfo.dataList = this.state.dataList;
            }

            if (loading || !hasNext) {
                return;
            }
        }

        if (requestType === 'direct') {
            url = 'orderAudit/getDirectOrderPage';
        } else {
            url = 'collectionOrderAudit/getSingleOrderList';
        }

        let response = await getDataFromServer(url, {
                token: localCache.token,
                pageSize: 10,
                pageIndex: setPageData ? pageIndex : 1,
                orderType: requestType === 'direct' ? 0 : 1,
                progress: '200'
            }, () => {
                if (setPageData) {
                    loading = true;
                }
            },
            () => {
                if (setPageData) {
                    loading = false;
                }
            }).catch(error => {
            if (setPageData) {
                hasNext = false;
                this.setState(updateInfo)
            }
        })

        if (response) {
            if (setPageData) {
                pageIndex++;
                hasNext = response.value.hasNext;
                updateInfo.dataList = updateInfo.dataList.concat(response.value.data);
            }
            let tabList = this.state.tabList;
            for (let i = 0; i < tabList.length; i++) {
                if (tabList[i].key === requestType) {
                    tabList[i].count = response.value.totalCount || null;
                    break;
                }
            }
            updateInfo.tabList = tabList;
            this.setState(updateInfo)
        }
        return response;
    }

    render() {
        return (
            <Translation>
                {
                    (t) => <div style={pageColorView("#FAFAFA")}>
                        {
                            updateTabList(t)
                        }
                        <Tabs activeKey={this.state.tabIndex} onChange={(e) => {
                            this.setState({
                                tabIndex: e
                            }, () => {
                                this.loadMoreData(true)
                            })
                        }}>
                            {
                                this.state.tabList.map((item, index) => (
                                    <Tabs.Tab title={
                                        <Badge content={item.count > 99 ? '99+' : item.count}
                                               style={badgeView}>
                                            {item.name}
                                        </Badge>
                                    } key={item.key}/>
                                ))
                            }
                        </Tabs>
                        <div id={scrollLabel} style={scrollNowView}>
                            {
                                this.state.dataList.map((item, index) => (
                                    <Card key={index}
                                          style={itemView}
                                          onClick={() => {
                                              this.navigationToDetail(item.id)
                                          }}>
                                        <Grid columns={24}>
                                            <Grid.Item span={5}>
                                                <div className={'color999 font-size15'}>
                                                    {t('systemNo')}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={13}>
                                                <div className={'color263238 font-size15'}>
                                                    {item.serialNo}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={6}>
                                                <div className={'font-size15'}>
                                                    {
                                                        item.maxProgress > 200 ?
                                                            <span>{t('received')}</span> : item.maxProgress === 200 || item.progress === 200 ?
                                                            <span className={'color52C41A'}>{t('waitCollect')}</span> :
                                                            <span>{t('notReceived')}</span>
                                                    }
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                                        <Grid columns={24} style={marginTop(1)}>
                                            <Grid.Item span={5}>
                                                <div className={'color999 font-size15'}>
                                                    {t('orderCreateTime')}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={19}>
                                                <div className={'color263238 font-size15'}>
                                                    {item.createTime}
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                                        {
                                            this.state.tabIndex === 'direct' ? <Grid columns={24} style={marginTop(1)}>
                                                <Grid.Item span={5}>
                                                    <div className={'color999 font-size15'}>
                                                        {t('service')}
                                                    </div>
                                                </Grid.Item>
                                                <Grid.Item span={19}>
                                                    <div className={'color263238 font-size15'}>
                                                        {item.serviceName || '-'}
                                                    </div>
                                                </Grid.Item>
                                            </Grid> : ''
                                        }
                                        <Divider/>
                                        <div className={'flex-row justify-between width100p '}>
                                            <div>
                                                {
                                                    item.maxProgress === 200 || item.progress === 200 ?
                                                        <Button color='primary' fill='none' style={operationBtnView(0)}
                                                                onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    this.setState({
                                                                        receivingOrderId: item.id,//需要接收订单的id
                                                                        receivingServicePlatform: item.servicePlatform,//需要接收订单的id
                                                                        receivingStatus: true,//接收订单弹窗显示状态
                                                                    })
                                                                }}>
                                                            {t('collection')}
                                                        </Button> : ''
                                                }
                                                <Button color='primary' fill='none' style={operationBtnView(1)}
                                                        onClick={(e) => {
                                                            this.getOrderQrCode(item.qrcode,t);
                                                            e.stopPropagation()
                                                        }}>
                                                    {t('qrCode')}
                                                </Button>
                                            </div>
                                            <div className={'flex-row'}>
                                                <div className={'font-bold'}>{t('viewOrderDetails')}</div>
                                                <RightOutline/>
                                            </div>
                                        </div>
                                    </Card>
                                ))
                            }
                            <InfiniteScroll loadMore={async () => {
                                await this.loadMoreData();
                            }} hasMore={hasNext} threshold={100}/>
                        </div>
                        {
                            this.injectObject && this.injectObject.scanCode ?
                                <Button className={'border-radius0'} color={'primary'} size={'large'} onClick={() => {
                                    this.injectObject.scanCode();
                                }}>{t('scanAndCollect')}</Button> : ''
                        }
                        <ReceivingOrder visible={this.state.receivingStatus} orderId={this.state.receivingOrderId}
                                        servicePlatform={this.state.receivingServicePlatform} onClose={(refresh) => {
                            if (refresh) {
                                this.loadMoreData(true).then(r => {
                                })
                            }
                            this.setState({receivingStatus: false})
                        }}/>
                    </div>
                }
            </Translation>
        )
    }

    /**
     * 跳转到详情页面
     * @param orderId 订单id
     * @param tabIndex 订单类型
     */
    navigationToDetail(orderId, tabIndex = this.state.tabIndex) {
        this.props.history.push(`/orderDetailPage?${orderId}&${tabIndex}`)
    }

    /**
     * 获取订单二维码
     */
    getOrderQrCode(url,t) {
        let contentView = (<div className={'flex-row justify-center'}>
            <Image style={{width: '90%', height: '90%'}} src={url}></Image>
        </div>);
        if (this.injectObject && this.injectObject.printCode) {
            Dialog.confirm({
                content:contentView,
                cancelText:t('cancel'),
                confirmText: t('print'),
                onConfirm: () => {
                    this.injectObject.printCode(url);
                }
            })
        }else{
            Dialog.alert({
                content:contentView,
                confirmText:t('submit')
            })
        }
    }
}

const tabList = [{name: '直发订单', key: 'direct', count: null}, {name: '集运订单', key: 'collect', count: null}];

function updateTabList(t) {
    tabList[0].name = t('directOrder');
    tabList[1].name = t('collectOrder');
}

function operationBtnView(marginLeft = 0) {
    return {
        padding: '0',
        margin: `0 0 0  ${marginLeft * 8}px`,
    }
}

const itemView = Object.assign(
    marginTop(2),
    marginLeft(1),
    marginRight(1)
)

const badgeView = {
    '--right': '-10px', '--top': '8px'
}

