import React from 'react';
import { Result, Button } from 'antd';

export default class UnKnowPage extends React.Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={(e)=>{
                    this.props.history.push("/")
                }}>Back Home</Button>}
            />
        )
    }
}
