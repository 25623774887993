import React from 'react';
import {Popup, Button} from 'antd-mobile';
import {marginBottom, marginTop, scrollNowView} from "../../common/script";
import i18n from "./../../config/i18n";

export default class ProtocolDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
            })
        }
    }

    hideVisibleClick(status) {
        this.props.onClose(status);
    }


    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={contentView}
                   stopPropagation={['enter']}
            >
                <div className={'text-center font-size18 font-bold'}
                     style={Object.assign(marginTop(1),marginBottom(1))}>{i18n.t('electronicContract')}</div>
                <div style={scrollNowView}>
                    <text style={{whiteSpace:'pre-line'}}>
                        {
                            'STANDARD CONDITIONS OF CARRAIGE FOR CHADEX (PTY) LTD REGISTRATION NUMBER: 2020/503013/07 t/a CHADEX EXPRESS All business undertaken by Chadex is subject to the following terms and conditions: 1. For the purpose of the paragraphs set out below:\n' +
                            '1.1. “Company” shall mean Chadex;\n' +
                            '1.2. “Conditions” shall mean the Standard Conditions of Carriage for Chadex (Pty) Ltd as set out in this document as at the date of publication hereof which shall be read in conjunction with any other terms and conditions which may be applicable;\n' +
                            '1.3. “Consignee” shall mean the person to whom the Goods are to be delivered under these conditions;\n' +
                            '1.4. “Carriage”shall mean all forms of transport and related activities undertaken by the Company on behalf of Clients, in respect of Goods.\n' +
                            '1.5. “Client”shall mean the party who contracts with the Company for the Carriage of the Goods and shall also mean the party on whose behalf the Company took possession of Goods in good faith, whether the sender, the party billed, the \n' +
                            'Consignee, the true owner, the lawful owner, or any other party who can be held responsible to be the party on whose behalf the Company has rendered the service.\n' +
                            '1.6. “Goods” shall mean all packages, parcels, or items taken into possession by the Company for Carriage on behalf of the Client.\n' +
                            '1.7. “Owner’s Risk” means that the Company shall not be liable for any loss or damage to any Goods unless that damage is caused intentionally;\n' +
                            '1.8. “POPIA” means the Protection of Personal Information Act of 2013;\n' +
                            '1.9. “Services” means the operations and services offered in relation to these Conditions. 2. The Company has an absolute, sole and unfettered discretion and right to refuse to accept Carriage of any Goods without needing to give reasons for such refusal. It is recorded and agreed that the Company is neither a public nor common \n' +
                            'carrier. 3. The Company will in its sole discretion select the methods of transport and handling to be used in the Carriage, regardless of the service requested by the Client. 4. The instruction from the Client to the Company in respect of any Carriage shall be made on the Company’s official dispatch document form duly completed and tendered with the Goods. If no dispatch document is created or received, the \n' +
                            'contents of this clause shall apply to whatever form of instruction is used in its place (including but not limited to sub-contractors), whether a parcel label, computer diskette or prior written contractual arrangement with the Client. The person signing the \n' +
                            'dispatch document, if someone other than the Client, hereby warrants that he/she is duly authorised to order the services of the Company and to enter into this agreement for and on behalf of the Client. In the event that the Client is not the owner of \n' +
                            'the Goods, the Client warrants that it is duly authorised to enter into this agreement on behalf of the true owner and / or lawful owner of the Goods. 5. The Company’s charges in respect of the Carriage shall be levied in accordance with its agreed tariffs, as amended from time to time, unless an alternative agreement has been arranged, which agreement must be in writing and signed by an \n' +
                            'authorized representative of the Company. \n' +
                            'The Client agrees to be bound by such tariff and amendments. Eternal disbursements made in respect of the Carriage, including but not limited to, duties and taxes, shall be recovered at cost from the Client in addition to the Company’s charges. 6. Dangerous Goods: 6.1. The Company does not carry dangerous Goods. Dangerous Goods, for the purpose of this clause and the Conditions as a whole, are defined as those Goods and substances which are classified as dangerous in accordance with SABS Codes of \n' +
                            'Practice 0228 and 0229, which codes may be amended from time to time, including without any limitations whatsoever, any Goods or substances which are in the opinion of the Company, of a hazardous and/or dangerous nature. The Client is cautioned \n' +
                            'to avoid tendering to the Company any packages containing dangerous or hazardous Goods and/or substances, as any liability of any nature whatsoever, and howsoever caused, arising out of the Carriage of the dangerous Goods will in its entirety be \n' +
                            'passed onto the Client. This clause may be amended/altered should the Client give prior written notice to the Company, and both parties agree in writing to the amendment thereof. The Company will then act as a 3rd party agent in sourcing the correct \n' +
                            'vehicle required to handle the dangerous Goods. Any liability whatsoever caused by and/or arising from any such amendment shall as aforesaid, remain with the Client. 6.2. The Client is cautioned to avoid tendering to the Company, Goods which are fragile or valuable, and should they do so, they are obliged to so pack and label the Goods to minimise the additional risk to which such Goods may be exposed. 6.3. Whether or not the Client has observed the terms of this clause, the Client indemnifies the Company against any loss or damage (including consequential damages), arising out of the Carriage of dangerous and / or fragile Goods. 7. Due consideration being given to clause 6.1 herein, the Client warrants and undertakes that it shall not submit for Carriage to the Company any illegal, dangerous or hazardous Goods, including but not limited to radioactive materials, explosives, \n' +
                            'narcotics and livestock. In the event of breach of this provision the Client indemnifies the Company against any loss or damage including but not limited to consequential damages. Any costs of disposal of such Goods which the Company may incur as a \n' +
                            'result of, or in connection with the Goods aforesaid, shall be for the Client’s account. Any actions taken / resulting and / or arising from the aforesaid, and any costs incurred as result thereof shall be taken / incurred in the Company’s sole and absolute \n' +
                            'discretion, and shall be undertaken / incurred at the Client’s risk. 8. In general, save for what is set out in the sub-clauses hereunder, the Company hereby either excludes or limits liability to the Client in respect of the Goods in its possession / in Carriage, in respect of any loss or damage relating thereto, \n' +
                            'howsoever such loss or damage is caused, even if caused by the negligence on the part of the Company or its servants, agents or sub-contractors in circumstances where vicarious liability would be applicable: 8.1. The Company’s liability to the Client in respect of the Carriage of Goods shall terminate when a clean receipt is received by the Company on delivery of the Goods to the Consignee. Clean receipt shall be deemed to have been obtained by the \n' +
                            'Company if the Consignee has not at the time of delivery endorsed any discrepancy on the dispatch document. 8.2. DECLARED VALUE OF GOODS: The Company assumes limited liability for Goods in Carriage up to the value of R1 000.00 (One Thousand Rand) ONLY. VALUE DECLARED AT ZERO OR NO VALUE DECLARED AT ALL: In the event of the value of \n' +
                            'the Goods being declared at zero on the dispatch note, or should no value for the Goods be disclosed at all by the Client, then the Company accepts limited liability to a maximum amount of R250.00 (Two Hundred And Fifty Rand) for Goods in Carriage. \n' +
                            'In this instance the Company shall not arrange insurance in respect of the Goods in Carriage. The Client acknowledges and agrees to the aforesaid values and accordingly recognises that the Company shall levy fees and insurance premiums based on \n' +
                            'these values ONLY. 8.3. Due consideration being given to clause 8.2 above, it is recorded that the Company’s liability is limited to the proven cost of repair or at the Company’s discretion, the original purchase cost of the Goods damaged or lost, which in neither \n' +
                            'circumstance shall exceed the maximum value of a R1 000.00 (One Thousand Rand), as the Company’s fees and insurance premiums charged to the Client are based on the maximum value declared. 8.4. The Company does not offer liability cover for Incidental Costs and the Client is required to arrange this cover with their own liability provider. Liability for fines, penalties and loss of profits is specifically excluded. 8.5. The Company accepts no liability whatsoever, nor shall any insurance cover be arranged for the following classes of Goods, regardless of the Client’s declaration in respect thereof, and the Client indemnifies the Company against any loss or \n' +
                            'damage (including consequential damages) arising out of the Carriage of such Goods: 8.5.1. Precious stones and metals, jewelry, negotiable instruments; 8.5.2. Works of art, heirlooms and other irreplaceable, sentimental or priceless items; 8.5.3. Any article exceeding a maximum value of a R 1000.00 (One Thousand Rand); 8.5.4. Unique articles such as samples whose cost of creation is materially different to the normal cost of such Goods in a production environment; 8.5.5. Perishable Goods or Goods likely to contaminate other Goods or attract pests. 8.6. Should insurers repudiate any claim under the terms and conditions of the insurance cover arranged on the Client’s behalf, the Company shall remain liable to the Client, subject to the limits and exceptions contained in clauses 8.1 to 8.8, \n' +
                            'provided that it is proved that the loss, damage or delay in respect of the Goods was caused by gross negligence on the part of the Company. 8.7. The Company’s maximum liability to the Client in respect of the aggregate of all claims, losses or damages governed by the preceding provisions of this clause shall never exceed R1 000.00 (One Thousand Rand) per shipment. 8.8. Notwithstanding any other provision(s) of this agreement the Company, its servants, agents and subcontractors accept no liability whatsoever and howsoever arising, for consequential losses of any kind, whether arising from gross negligence on \n' +
                            'the part of the Company, its servants, agents or subcontractors or otherwise. 8.9. Save in respect of the liability of the Company as set out above, the Client indemnifies the Company against any claims of whatsoever nature which may be made against the Company by any other person or entity as a result of the loss, damage \n' +
                            'or delay in respect of the Goods. 9. Payment by the Client to Company in respect of the services rendered will be due and payable (if the services were not attended to on a COD basis), within a maximum of 30 (thirty) calendar days calculated from the date of the invoice being \n' +
                            'delivered to the Client. The Client agrees to abide by and strictly adhere to the credit / payment terms of the Company, including making payment to the Company within the said 30 (thirty) calendar days-period, failing which interest may be charged on \n' +
                            'overdue amounts at the legal mora interest rate which is currently 7% (Seven Percentum) per annum. 10. The Client shall be entitled, during the said 30 (thirty) day period, to raise queries in respect of the Company’s invoices, which queries the Company undertakes to resolve within this period. It is expressly recorded that any queries raised shall not \n' +
                            'have the effect of extending the 30(thirty) day payment period. If no queries are raised within the 30 (thirty) day period aforesaid by the Client, the Client will be deemed to have accepted that the Company’s invoice is correct and undisputed.\n' +
                            '11. In the event that any entries on the Client’s invoice are disputed and queried by the Client within the time period specified before, this will not absolve the Client from liability to make payment of all undisputed amounts in respect of such \n' +
                            'invoice(s) to the Company within the 30 (thirty) day time period specified. 12. Under no circumstances whatsoever shall the Client be entitled to withhold payment of rendered invoices beyond the due date. Under no circumstances shall the Client be entitled to set off any alleged damages or loss against any amount due, \n' +
                            'owing and payable to the Company from time to time. Without departing from the generality of the aforesaid, this clause will apply in particular where Goods have been lost or damaged while in the Company’s possession and / or in Carriage. The \n' +
                            'Company shall have a lien over all Goods in its possession for Carriage as security for the payment of all amounts howsoever arising due by the Client to it, and the Client hereby pledges such Goods as security for the purpose aforesaid. 13. Any claims, whether submitted under the terms of insurance arranged on the Company’s behalf, or direct claims against the Company, must be submitted in writing and be delivered to the Company at its chosen domicilium address within \n' +
                            '30 (thirty) calendar days of the date of the claim arising. Claims submitted after this period will irrevocably lapse and be of no force and effect, regardless of the circumstances of loss or damage. The Client indemnifies the Company against any loss or \n' +
                            'damage, direct or indirect, (including consequential damages), arising from the Client’s failure to observe the terms of this clause.\n' +
                            '14. If the Company is unable for any reason to effect delivery of the Goods, reasonable steps will be taken by it to return the Goods forthwith to the Client. The Client shall be responsible for the costs of Carriage, attempted delivery and return of \n' +
                            'the Goods. If the Company is unable to effect return of the Goods as a result of any fault on the Client’s part, the Company shall be entitled to sell the Goods at a market related price to defray costs incurred by it, after giving notice of such sale by \n' +
                            'registered post to the Client. 15. Lien: 15.1. The Company shall have a lien over the Goods (and any document relating to the Goods) a nd any other items (and any documents relating thereto) of the Client in the custody or control of the Company and reserves the right to sell the Goods \n' +
                            '(or any document relating thereto) in accordance with 15.2 below. 15.2. The Company reserves the right, without prejudice to any other rights it may have in law, to sell any Goods of the Client at a market related price in order to apply the proceeds thereof against (i) any arrear indebtedness of the Client to the \n' +
                            'Company arising out of services rendered, as well as (ii) any costs incurred as a result of such sale. The Company shall give the Client 14 (fourteen) calendar days written notice by registered post, of such sale. Any surplus funds realised from such sale \n' +
                            'after deducting the Client’s indebtedness to the Company for services rendered and costs of sale of the Goods as aforesaid shall be paid to the nominated account of the Client. 16. The Client shall remain responsible to the Company for all charges until they are paid. The Company shall not collect any monies at the time of delivery and no employee of the Company is authorised to do so. All payments made by the Client or \n' +
                            'any third party on behalf of the Client, shall be made free of any deduction or set off to the Company at its chosen domicilium address or to its nominated South African bank account.\n' +
                            '17. The Client may instruct the Company to recover its charges from a third party provided that the third party is an account holder of good standing with the Company and provided the Client has quoted the account number of such third party \n' +
                            'on the dispatch document. In the absence of the above provisos, the Company will ignore the Client’s instruction and charge the Client directly. In the event that the third party referred to above declines to settle the Company’s charges so raised, the \n' +
                            'Company may charge the Client who undertakes to settle the account in full within the time frames and parameters set out hereinbefore. 18. The Client hereby consents to the jurisdiction of the Magistrate’s Court in terms of Section 45 of the Magistrate’s Court Act No. 32 of 1944 as amended, for all claims that the Company may have against the Client. This clause does not \n' +
                            'preclude the right of the Company to institute any action in the High Court of South Africa. 19. Should any legal action by the Company be taken against the Client as a consequence of a breach by the Client of any of the obligations of the Client in terms of this agreement, then in that event the Client shall be obliged to effect payment on \n' +
                            'demand of all Attorney-and-Client costs incurred by the Company in taking such legal action, including collection commission, irrespective of whether proceedings in a Court of Law have actually been instituted.\n' +
                            '20. POPIA\n' +
                            '20.1. It is anticipated that the Client shall share with the Company Personal Information relating to Consignees for the purposes of the Company providing the Services. Therefore, and for the purposes of POPIA, the Company shall be an Operator \n' +
                            'mandated by the Client (the Responsible Party) to Process Personal Information of Consignees (and other third parties) (Data Subjects) in terms of these Conditions for the purposes of rendering the Services and will comply with all requirements relating \n' +
                            'to Operators as prescribed by POPIA.\n' +
                            '20.2. The Client warrants that it has complied with all obligations of a Responsible Party (whether it is a Responsible Party or not as defined) in relation to any Personal Information of a Data Subject provided to the Company.\n' +
                            '20.3. The Client authorises the Company to share Personal Information with other parties within the Company’s network in so far as may be necessary to perform the Services. The Company shall Process Personal Information in a manner that is \n' +
                            'adequate, relevant and not excessive for purposes of providing the Services.\n' +
                            '20.4. Personal Information shall be retained by the Company only for so long and in so far as may be necessary for it to comply with laws and regulations applicable to the business of the Company.\n' +
                            '20.5. In the event that the Client is in default of the undertakings herein or of its statutory duties under POPIA, the Client hereby indemnifies and keeps the Company harmless against any claims for loss or damage, fine, statutory penalty and/or \n' +
                            'sanction, including direct, indirect, consequential loss, damage or injury, suffered by the Company (together with all other third parties involved in providing the Services) or any Data Subject as a result of the Client’s failure to comply with its statutory \n' +
                            'obligations contained under POPIA and/or any wilful and/or negligent acts or omissions of the Client, its employees, contractors, agents. Should a competent court, regulatory body, authority or person with jurisdiction award any damages, fine or \n' +
                            'penalties against the Company, the Client will be liable for payment of such damages, fine or penalties, which will be payable on demand. 21. The parties choose as their domicilium citandi et executandi for the delivery of all documents, communication, Court processes and any other notices hereunder, the address which appears on the dispatch document.\n' +
                            '22. All rights, powers, authorities, immunities and limitations of liability contained herein shall continue to operate with their full force and effect notwithstanding any breach on behalf of the Company or its duly authorised representatives.\n' +
                            '23. This agreement shall be governed and construed in accordance with the laws of the Republic of South Africa. 24. Should any part of this agreement be deemed to be unenforceable, whether whole or in part, the remainder of the agreement will be severed from the unenforceable section and the agreement will continue to operate in its absence.\n' +
                            '25. This agreement constitutes the sole record of the agreement between the parties. No party shall be bound by any express or implied term, representation, warranty, promise or the like not recorded herein.\n' +
                            '26. No addition to, variation of, or agreed cancellation of this agreement shall be of any force or effect, unless reduced to writing and signed on behalf of both parties. 27. No relaxation or indulgence which any party may grant to any other shall constitute a waiver of the rights of that party and shall not preclude that party from exercising any rights which many have arisen in past or which may arise in future.\n' +
                            '28. The onus of proving the state, condition and nature of the Goods handed to the Company for the purpose of Carriage shall at all times rest with the Client. No receipt given by the Company to the Client shall in any way, manner or form, be \n' +
                            'regarded prima facie proof / evidence of the state, condition and nature of the Goods.\n' +
                            'S.F. EXPRESS Waybill Terms and Conditions of Carriage\n' +
                            '顺丰速运运单条款和条件\n' +
                            'S.F. TERMS AND CONDITIONS (“T&C”)\n' +
                            '顺丰条款和条件（条款和条件）\n' +
                            '1. Data Privacy Policy\n' +
                            '数据隐私保护 You have agreed to abide by the Privacy Policy as amended from time to time which is published on the official website of SF http://www.sf-express.com/cn/sc/Privacy_Policy/ (for Shippers/Receivers outside Mainland China, please enter the relevant \n' +
                            'website of SF to check the Privacy Policy applicable in your country/region), and you have agreed that SF may process your waybill information in accordance with the provisions of laws, regulations, and the Privacy Policy.\n' +
                            '您同意遵守顺丰官方网站所公示的隐私政策http://www.sf-express.com/cn/sc/Privacy_Policy/（如寄件人/收件人位于中国大陆以外的国家/地区，请访问相关的顺丰网站查看您所在国家/地区所适用的隐私政策）及其不时修订的内\n' +
                            '容，同意顺丰按照\n' +
                            '法律法规和隐私政策的规定处理您提供的运单信息。 2. SF’s Liability\n' +
                            '顺丰的责任 2.1．SF’s liability, including but not limited to the liability of loss, damage or partially missing of Shipment and custom brokerage service, is strictly limited to direct loss only and to the limit of liability in this Clause 2. All other types of loss or damage \n' +
                            'are excluded\n' +
                            '(including but not limited to lost profits, income, interest and future business), whether such loss or damage is special or indirect, and even if the risk of such loss or damage was brought to SF’s attention.\n' +
                            '顺丰所承担的责任，包括但不限于快件丢失、破损或短缺及清关服务的责任，仅严格限于实际直接损失且不超过本条款第2条所规定的限额。顺丰不承担任何其它损失或损害（包括但不限于利润、收入、利息及未来业务的损\n' +
                            '失），无论这些其它损失和损害是特殊的或是间接的且无论顺丰是否知晓存在这些损失或损害的风险。 2.2．If the carriage of a Shipment is solely or partly by air and involves an ultimate destination or a stop in a country other than the country of departure, without prejudice to the rights in Clause 3 and 11, the Warsaw Convention or the Montreal \n' +
                            'Convention, whichever is compulsorily applicable, will apply. In the absence of such Conventions, SF’s liability, regardless of declared value of the Shipment, shall in no event exceed the lesser of USD100 or USD20/kg or USD9.07/lb. If the carriage of a Shipment\n' +
                            'combines carriage by air, road or other mode of transport, it shall be presumed that any loss or damage occurred during the air period unless proven otherwise.\n' +
                            '如快件完全或部分由空运运输，且快件的最终目的地或者停靠地不在发件国家或地区，在不影响本条款第3条和第11条约定的前提下，若《华沙公约》和《蒙特利尔公约》强制适用，则适用公约。若公约不适用，则顺丰的责\n' +
                            '任，无论快件的申报价值，均不得超过以下各项中的低者：100美元或20美元/公斤或9.07美元/磅。如快件采取包含空运、陆运或其他方式的多式联运，除非另有证据，否则任何损失或损害将被推定发生在空运阶段。 2.3．For cross border Shipment transported by road, SF’s liability shall be subject to the Convention for the International Carriage of Goods by Road (CMR), or in the absence of CMR, regardless of declared value of Shipment, to the lesser of USD100 or USD10/kg or USD4.54/lb (not applicable to the United States). Such limits will also apply to national road transportation in the absence of any mandatory or lower liability limits in the applicable national transport law.\n' +
                            '对于国际陆运运输，顺丰的责任应适用《国际公路货物运输合同公约》（“CMR”），若该公约不适用，则顺丰的责任，无论快件的申报价值，均不得超过以下各项中的低者：100美元或10美元/公斤或4.54美元/磅（但不适用\n' +
                            '于美国）。该责任限制同\n' +
                            '样适用于在国内公路运输情形下不存在根据国内运输法律具有强制适用或更低责任标准的情况。 2.4．Claims are limited to one claim per Shipment, settlement of which shall be full and final for all loss or damage in connection therewith. IF SHIPPER REGARDS THESE LIMITS AS INSUFFICIENT, IT MUST PURCHASE SPP OR MAKE ITS OWN INSURANCE ARRANGEMENTS.\n' +
                            '每票快件只能提出一次索赔，且对于该快件所相关的所有丢失或损坏的结算是全额且终局的。如寄件人认为这些限制不够，寄件人应购买保价服务或自行投保。 2.5．Unless contrary to applicable laws, all claims must be submitted in writing to SF together with all relevant supporting documents within thirty (30) days from the date SF accepted the Shipment, failing which SF shall have no liability whatsoever. \n' +
                            'SF is not\n' +
                            'obligated to act on any claim until all Shipment charges have been paid. The Shipment charges shall not be offset against the claim amount. Receipt of the Shipment by the Receiver without written notice of damage on the delivery record is prima \n' +
                            'facie evidence\n' +
                            'that the Shipment was delivered in good condition. As a condition for SF considering any claim for damage the Receiver must make the contents, original shipping and packing materials available for inspection by SF.\n' +
                            '除非与相关适用法律冲突，任何索赔必须在顺丰接收快件后的三十（30）天内以书面方式向顺丰提出并附上相应证明材料，否则顺丰将不再承担任何责任。若所有运输费用尚未被支付，顺丰无义务受理任何索赔。索赔金额不\n' +
                            '可用该运输费用抵消。若收件人在签收快件时没有在快递记录上注明有任何损坏，则该快件被视为完好送达。作为理赔的条件，顺丰有权对原快件和包装材料进行检查。\n' +
                            '3． Shipment Protection Plus Service (“SPP”)\n' +
                            '保价条款（“SPP”） 3.1．SPP is a value-added service which is OPTIONAL. The Shipper is entitled to a higher than normal compensation provided in Clause 2 should your Shipment is lost or damaged during transportation, provided that the Shipper informs SF of its \n' +
                            'intention to purchase SPP and has paid the surcharge.\n' +
                            '保价服务是可选的增值服务，一旦在运输途中发生快件丢失或损坏且寄件人告知顺丰其购买本项服务的意图并已支付相应的保价费用，寄件人有权获得高于本条款第2条所规定的赔偿。 3.2．SF’s liability in respect of any Shipment covered under SPP is limited to lesser of the SPP Value purchased by Shipper or the actual cash value of Shipment (“Decided Value”). For partial loss, damage or partially missing of the Shipment, the \n' +
                            'Shipper will be compensated on a pro rata basis (Decided Value of the Shipment * proportion of actual loss, damage or missing to the Shipment) and should the remaining or damaged Shipment be returned to the Shipper on Shipper’s instruction, SF is entitled to offset the Shipment charges against the compensation.\n' +
                            '对于已购买SPP服务的任何快件，顺丰应承担的责任受限于寄件人已购买的SPP价值以及快件实际现金价值中的较低者（“决定价值”）。对于快件的部分灭失、破损或短缺，顺丰将按比例（即快件决定价值 * 快件实际灭失、\n' +
                            '破损或短缺的比例）赔偿寄件人，且如剩余快件或损毁快件按寄件人的要求返还寄件人，顺丰有权在赔偿中抵销返还快件所产生的额外运费。 4． Declared Value Limit\n' +
                            '申报价值限制\n' +
                            '4.1．For any Shipment with a declared value in excess of the limit of declared value in accordance with the policy published on the official website of SF, SF is entitled for additional charges in accordance with SF rates and charges policy.\n' +
                            '若快件的申报价值高于顺丰官网政策中规定的申报价值上限，则顺丰有权依据顺丰的费用和收费标准加收额外费用。\n' +
                            '4.2．For avoidance of doubt, the acceptance for carriage of any Shipment with the declared value specified on the waybill and/or the receipt of additional charges do not constitute a waiver of SF’s limit of liability and limit of SPP Value set out herein. \n' +
                            'Regardless of the declared value of a Shipment, SF’s entire liability shall be subject to Clause 2 and 3 hereof.\n' +
                            '为避免歧义，对于任何填写了申报价值的快件的接收和/或加收额外费用均不构成顺丰对本条款下赔偿责任限额和SPP价值限额的放弃,即无论快件的申报价值，顺丰均依据本条款第2条和第3条约定承担赔偿责任。\n' +
                            '4.3．Shipper agrees that the declared value for customs and carriage on one waybill should be equivalent to the actual cash value of the Shipment. The declared value of a Shipment must be proved by an authentic and valid commercial invoice or \n' +
                            'receipt, which SF shall have the sole and absolute discretion to accept, inspect and verify thereof to its satisfaction or to reject thereof without giving any reason.\n' +
                            '寄件人同意，单票运单上海关和承运的申报价值应与快件的实际现金价值相等。您须提供证明快件申报价值的真实有效的商业发票或收据，且顺丰有权自行决定是否对其进行接受、检查、查验或拒绝而无需出具理由。\n' +
                            '5． Definitions 定义5.1．“SF”, “Us” and “Our” refers to S.F. Express Co., Ltd., its subsidiary, branch, affiliate, agent, or independent contractor, which originally accepts your Shipment; “Shipper” and “you” refers to the Shipper.\n' +
                            '“顺丰”、“我们”和“我们的”指最初收寄您快件的顺丰速运有限公司及其子公司、分公司、关联公司、代理或独立承包商；“寄件人”和“您”指的是寄件人。 5.2．“Package” means any parcel or envelope that is accepted by us for delivery.\n' +
                            '“包裹”指我们收寄的任何包裹或文件封。 5.3．“Shipment” means all packages which are tendered to and accepted by us under one waybill.\n' +
                            '“快件”指基于单个运单的所有需要并被我们收寄的包裹。 6. Unacceptable Shipments\n' +
                            '不予收寄的快件 Shipper agrees that its Shipment is deemed unacceptable if:\n' +
                            '寄件人同意以下快件将不被收寄： i. it is classified as hazardous material, dangerous Goods, prohibited or restricted articles by International Air Transport Association, International Civil Aviation Organization, any applicable government department or other relevant organization;\n' +
                            '国际航空货运协会、国际民航组织及其它相关政府部门或其它相关组织所规定的有害物质、危险物品，禁运物品或限运物品；\n' +
                            'ii. no customs declaration is made when required by applicable customs regulations; or\n' +
                            '未按照有关海关规定办理报关手续；或\n' +
                            'iii. it contains such item which SF believes cannot be carried safely or legally (including but not limited to: animals, currency, negotiable instruments in bearer form, precious metals and stones, firearms, parts thereof and ammunition, human remains, \n' +
                            'pornography material and illegal narcotics/drugs).\n' +
                            '包含顺丰认为不能安全、合法运输的物品（包括但不限于动物、现钞、不记名可议付票据、贵重金属和矿石、枪械及其零附件、弹药、人体、色情物品或非法的麻醉药品/毒品）。\n' +
                            '7. Right to Inspect\n' +
                            '查验权 Shipper agrees that SF or any governmental authorities may open and inspect the Shipment without notice at any time.\n' +
                            '寄件人同意顺丰或任何政府部门有权未经事先通知于任何时候对快件进行开箱查验。 8. Shipper’s Warranties and Indemnity\n' +
                            '寄件人的保证和赔偿责任 Shipper shall indemnify and hold SF harmless from any loss or damage resulting from Shipper’s failure to comply with the following warranties and representations:\n' +
                            '如寄件人无法遵守以下保证和陈述，寄件人应赔偿并确保顺丰免受损失或损害： i. all information provided by Shipper or its authorized person is complete, legible and accurate;\n' +
                            '寄件人或寄件人授权人员提供的所有信息都是完整、清晰和准确的；\n' +
                            'ii. the Shipment was prepared securely and packed to ensure safe transportation with ordinary care in handling by Shipper or its authorized person and was protected against unauthorized interference during preparation, storage and transportation \n' +
                            'to SF;\n' +
                            '快件由寄件人或寄件人授权人员为确保快件安全运输而予以安全准备并谨慎包装，并且在快件的准备、仓储和运输至顺丰的过程中确保顺丰免受不当干扰；\n' +
                            'iii. the Shipment is properly described and correctly labelled; and\n' +
                            '快件描述恰当且标识准确；和 iv. Shipper shall comply with all applicable customs, import, export data protection laws, sanctions, embargos and other applicable laws and regulations.\n' +
                            '寄件人应遵守所有适用海关、进出口数据保护法律、制裁、禁运令及其它适用法律法规的规定。 9. Routing 路线Shipper acknowledges and agrees to all routing and diversion, including the possibility that the shipment may be carried via intermediate stopping places.\n' +
                            '寄件人确认并同意顺丰对快件运输路线和转移上的所有处理，包括通过中转地予以中转运输。 10. Customs Clearance 清关10.1．Shipper appoints SF as the agent solely for the purpose of clearing and entering the Shipment through customs. SF may itself complete or consign a third party or redirect the Shipment to Receiver’s import broker or other address upon request \n' +
                            'by any\n' +
                            'person whom SF believes in its reasonable opinion to be authorized to perform customs clearances and/or entries.\n' +
                            '寄件人委托顺丰为其代理，全权负责快件清关和进关业务。为完成清关手续，顺丰可自行或委托第三方或应其有合理理由判断有权开展清关和/或进关业务的任何人的要求将承运的快件转交给收件人的进口代理或运送到其它\n' +
                            '地点。 10.2．To the extent that SF may voluntarily assist Shipper in completing the required customs and other formalities; such assistance will be rendered at Shipper’s sole risk and expense. If any customs authority requires additional documentation for \n' +
                            'the purpose of confirming the import/export declaration, it is Shipper’s responsibility to provide the required documentation at shipper’s expense.\n' +
                            '顺丰仅出于自愿协助寄件人完成所必须的海关和其它手续，由寄件人自行承担相应风险和费用。若任何海关当局出于确认进出口报关的目的要求额外文件，寄件人有责任提供相关文件并自行承担费用。\n' +
                            '11. Delay of Shipments\n' +
                            '快件延误 SF will make reasonable effort to deliver the Shipment according to SF’s regular delivery schedules, but these are not guaranteed and shall not form part of the T&C. SF is not liable for any damage or loss caused by delays.\n' +
                            '顺丰将按照其正常配送标准尽合理努力派送快件，但这些标准并不具有约束力，也不构成本条款的组成部分。顺丰不对运输延误而导致的任何损失或损害承担责任。 12. Deliveries and Undeliverable\n' +
                            '派送和不能派送 12.1．Shipments cannot be delivered to PO boxes or postal codes, but only to the Receiver’s address given by Shipper but not necessarily to the named Receiver personally. Shipments to addresses with a central receiving area will be delivered \n' +
                            'accordingly.\n' +
                            '快件不能派送到某邮政信箱或仅按邮政编码派送，只可按照寄件人提供的收件人地址派送，但不一定直接送达收件人本人。如果收件人地址设集中接收点，快件将被派送到该接收点。 12.2．If Receiver refuses delivery or to pay for delivery, or the Shipment is deemed to be unacceptable, or it has been undervalued for customs purposes, or Receiver cannot be reasonably identified or located, SF shall use reasonable efforts to return \n' +
                            'the Shipment to Shipper at Shipper’s cost, failing which the Shipment may be released, disposed of or sold without incurring any liability whatsoever to Shipper or anyone else, with the proceeds applied against service charges and related administrative costs and \n' +
                            'the balance of the proceeds to be returned to Shipper.\n' +
                            '若有如下情形之一，顺丰将以合理的努力将快件退还寄件人，因此额外发生的费用由寄件人支付：收件人拒绝接收快件或支付运费；该快件被认为是不可接受的；海关认为货物的价值被低报了；无法合理确定或找到收件\n' +
                            '人。如不能退还，顺丰可以对快件进行放弃、处置或变卖，且无需就上述行为向寄件人或其他任何人承担任何责任。所得收入将在扣除服务费和相关管理费用以及处理费用后返还您。 13． Shipment Charges, Tariff and other Charges\n' +
                            '运费、关税及其它费用 13.1．SF’s Shipment charges are calculated according to the higher of actual or volumetric weight and Shipment may be re-weighted and re-measured by SF to confirm this calculation.\n' +
                            '顺丰的运费将按照货物实际重量和体积重量中较高者计算，顺丰可以对任何快件重新称量和测量以确认其计算数据。 13.2．Shipper will always be responsible for all charges regardless of different payment instruction given by Shipper. Shipper shall pay on demand for all Shipment or other charges or tariff (customs duties) due not paid by Receiver for Receiver billing, \n' +
                            'or by\n' +
                            'third party for third party billing.\n' +
                            '即使寄件人给出不同的付款指示，寄件人始终对所有费用承担责任。在收件人或第三方付费的情况下，寄件人应按需求支付收件人或第三方应支付但未支付的所有运费或其它费用或税费（关税）。\n' +
                            '14. Circumstances beyond SF’s Control\n' +
                            '不可抗拒因素 SF is not liable for any loss or damage caused by any events beyond SF’s control including but not limited to “Act of God”- e.g. earthquake, cyclone, storm, flood, fog; “Force Majeure” –e.g. war, plane crash or embargo; any defect or characteristic related to the nature of Shipment, even if known to SF; riot or civil commotion; any act or omission by a person not employed or contracted by SF e.g. Shipper, Receiver, third party, customs or other government official; industrial action; and electrical or magnetic damage to, or erasure of, electronic or photographic images, data or recordings.\n' +
                            '顺丰将不承担对于超出顺丰控制范围的原因而导致的损失或损害。这些原因包括但不限于：自然灾害，如地震、龙卷风、风暴、洪水、大雾；不可抗力，如战争、空难或禁运等；快件固有的缺陷或特性（无论顺丰是否知\n' +
                            '晓）；暴乱或民间骚乱；非顺丰雇员或与顺丰没有合同关系的人员的作为或不作为，如您、收件人、第三方、海关或其他政府部门；工业行动；对于电子音像图片、数据或记录的电磁性损坏或删除。 15. Governing Law\n' +
                            '准据法\n' +
                            'Any dispute arising in connection with the T&C shall be subject to the non-exclusive jurisdiction of the courts of, and governed by the law of the country of origin of the Shipment, unless contrary to applicable law.\n' +
                            '除非与适用法律冲突，与本条款有关的任何争议将受到快件原寄件地国家或地区法院的非排他管辖，并适用于原寄件地国家或地区法律。 16. Severability\n' +
                            '可分割性\n' +
                            'The invalidity or unenforceability of any provision shall not affect any other part of these T&C.\n' +
                            '本条款任何部分的无效性或不可执行性，不影响其他条款的效力和执行。\n' +
                            'This T&C and the published rates and charges are subject to amendments without notice except to the extent otherwise provided by applicable laws or regulations. For the avoidance of doubt, any such amendments shall become effective and binding \n' +
                            'on the Shipper upon its publication on the official website of SF.\n' +
                            '除非经适用的法律法规要求，本条款以及公布的费用收取标准将会未经通知而不时修改。为避免歧义，任何该等变更应自顺丰官网公布之日起生效并对寄件人具有约束力。'
                        }
                    </text>
                </div>
                <div className={'flex-row justify-center'}>
                    <Button color={'primary'} fill={'outline'} style={btnView(0)}
                            onClick={() => this.hideVisibleClick(false)}>{i18n.t('back')}</Button>
                    {
                        !this.state.onlyShowInfo ? <Button color={'primary'} style={btnView(1)}
                                                           onClick={() => this.hideVisibleClick(true)}>{i18n.t('read')}</Button> : ''
                    }
                </div>
            </Popup>
        )
    }
}

function btnView(marginLeft) {
    return {
        width: '40%',
        marginBottom: '8px',
        marginTop: '10px',
        marginLeft: `${(marginLeft || 0) * 8}px`
    }
}

const contentView = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: '90vh',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    display: 'flex',
    flexDirection: 'column'
}
