import React from 'react';
import {Popup, Button, ImageUploader, Input, Picker, Checkbox, Image, ImageViewer} from 'antd-mobile';
import {RightOutline} from 'antd-mobile-icons'
import {getDataFromServer} from "../../api/request";
import {
    encryptionInfo,
    getDate,
    hideLoading,
    judgeNumberInput,
    showLoading,
    showToast,
    uploadImg
} from "../../common/utils";
import {localCache} from "../../api/localCache";
import {
    itemView,
    marginLeft,
    marginRight,
    marginTop, protocolView,
    scrollNowView
} from "../../common/script";
import SignDialog from "./SignDialog";
import {Translation} from "react-i18next";


/**
 * 接收订单
 */
export default class ReceivingOrder extends React.Component {

    defaultState = {
        visible: false,
        dataList: [],//国家列表
        selectValue: {
            label: '',
            value: '',
        },
        selectStoreInfo: [],//选择仓库信息
        signUrl: '',
        collectImageList: [],//揽收照片列表
        cargoQuantity: '1',//预计件数
        idencryption: '',//身份证信息
        canvasInfo: {
            width: 0,
            height: 0,
        },
        signDialogStatus: false,//签字弹窗
        protocolDialogStatus: false,//协议弹窗
        agreementStatus: false,//用户是否同意协议
    };

    constructor(props) {
        super(props);
        this.state = this.defaultState;
    }


    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            if (this.props.visible) {
                if (this.props.servicePlatform) {
                    this.setState({
                        visible: true,
                    })
                } else {
                    this.getDataList()
                }
            } else {
                this.setState({
                    visible: false,
                })
            }
        }
    }


    getDataList() {
        let url;
        if (this.props.servicePlatform) {
            url = 'storageHouse/getStorageHouseListByType'
        } else {
            url = 'storageHouse/getStoreListForCustomer';
        }
        getDataFromServer(url, {
            token: localCache.token,
            servicePlatform: this.props.servicePlatform
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            let reposeValue = res.value;
            let dataList = [];
            for (let item of reposeValue) {
                dataList = dataList.concat({
                    label: item.storageName,
                    value: {
                        label: item.storageName,
                        value: item.id,
                        type: item.type,
                    },
                })
            }
            this.setState({
                dataList: [dataList],
                visible: true
            })
        })
    }

    render() {
        return (
            <Translation>
                {
                    (t) => <Popup visible={this.state.visible}
                                  onMaskClick={() => {
                                      this.hideVisibleClick()
                                  }}
                                  bodyStyle={{
                                      borderTopLeftRadius: '8px',
                                      borderTopRightRadius: '8px',
                                      height: "75vh",
                                      display: 'flex',
                                      flexDirection: 'column'
                                  }}
                                  stopPropagation={['enter']}
                    >
                        <div className={'text-center font-size18 font-bold'}
                             style={marginTop(1)}>{t('clientConfirm')}</div>
                        <div style={scrollNowView}>
                            {
                                !this.props.servicePlatform ? itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('receivingWarehouse')}</div>
                                        <div className={'flex-row'} onClick={async () => {
                                            let value = await Picker.prompt({columns: this.state.dataList})
                                            if (value != null && value.length > 0 && value[0]) {
                                                value = value[0];
                                                this.setState({
                                                    selectValue: value,//选择群组名称
                                                })
                                            }
                                        }}>
                                            <div
                                                style={marginRight(1)}>{this.state.selectValue.label || t('choose')}</div>
                                            <RightOutline/></div>
                                    </div>
                                ), 0) : ''
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('packagesNum')}</div>
                                        <Input className={'mobile-input-text-end width50p'} type={'number'}
                                               placeholder={t('input')}
                                               value={this.state.cargoQuantity}
                                               clearable onChange={(e) => this.inputChange(e, 'cargoQuantity')}/>
                                    </div>
                                ), 0)
                            }

                            {
                                itemView((<div>
                                    <div className={'font-size15'}>{t('uploadPicLimit')}</div>
                                    <div className={'color999'} style={marginTop(1)}>{t('takeCollectPic')}</div>
                                    <ImageUploader
                                        style={marginTop(1)}
                                        value={this.state.collectImageList}
                                        maxCount={3}
                                        multiple
                                        onChange={(e) => {
                                            this.setState({
                                                collectImageList: e
                                            })
                                        }}
                                        upload={async (res) => {
                                            let response = await uploadImg(res)
                                            return {
                                                url: `https://qiniu.mrcargo.com/${response.data.key}`,
                                            }
                                        }
                                        }
                                    />
                                </div>), 0)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('identityInfo')}</div>
                                        <Input className={'mobile-input-text-end width50p'}
                                               placeholder={t('input') + '(' + t('notRequired') + ')'}
                                               value={this.state.idencryption}
                                               clearable onChange={(e) => this.inputChange(e, 'idencryption')}/>
                                    </div>
                                ), 0)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('customerSign')}</div>
                                        <div className={'flex-row'} onClick={() => {
                                            this.setState({signDialogStatus: true});
                                        }}>
                                            <div className={this.state.signUrl ? 'colorDefault' : 'colorD32F2F'}
                                                 style={marginRight(1)}>{this.state.signUrl ? t('signed') : t('notSigned')}</div>
                                            <RightOutline/>
                                        </div>
                                    </div>
                                ), -2)
                            }
                            {
                                this.state.signUrl ?
                                    <Image src={this.state.signUrl} style={marginLeft(1)} width={100} height={100}
                                           fit='fill' onClick={() => {
                                        ImageViewer.show({
                                            image: this.state.signUrl,
                                        })
                                    }
                                    }/> : ''
                            }
                        </div>
                        <Checkbox style={agreementView} size={'small'}
                                  checked={this.state.agreementStatus}
                                  onChange={(e) => this.setState({agreementStatus: e})}>
                            {
                                protocolView(this, (updateInfo) => {
                                    updateInfo.agreementStatus = true;
                                })
                            }
                        </Checkbox>
                        <div className={'flex-row justify-center '} style={bottomView}>
                            <Button style={{flex: 1}} color='primary' fill='outline' onClick={() => {
                                this.hideVisibleClick()
                            }}>{t('back')}</Button>
                            <Button color='primary' disabled={this.getSubmitBtnDisabledStatus()}
                                    style={Object.assign(marginLeft(2), {flex: 1})}
                                    onClick={() => {
                                        this.hideVisibleClick(true,t)
                                    }}>{t('submitCollect')}</Button>
                        </div>
                        <SignDialog visible={this.state.signDialogStatus} signUrl={this.state.signUrl}
                                    onClose={(signUrl) => {
                                        let updateInfo = {signDialogStatus: false};
                                        if (signUrl) {
                                            updateInfo.signUrl = signUrl
                                        }
                                        this.setState(updateInfo)
                                    }}/>

                    </Popup>
                }
            </Translation>
        )
    }

    /**
     * @return {boolean} 揽收按钮可点击状态
     */
    getSubmitBtnDisabledStatus() {
        return !this.state.agreementStatus || this.state.cargoQuantity === '' || !this.state.signUrl
    }

    /**
     * 输入框监听事件
     * @param res 输入的内存
     * @param type 修改的参数
     */
    inputChange(res, type) {
        let value = res.target ? res.target.value : res;
        if (type === 'cargoQuantity') {
            value = judgeNumberInput(value);
        }

        this.setState({
            [type]: value
        })
    }

    /**
     * 关闭弹窗
     * @param submit 是否提交数据
     */
    hideVisibleClick(submit,t) {
        if (submit) {
            if (!this.props.servicePlatform && !this.state.selectValue.value) {
                showToast(t('selectWarehouse'), 'warning')
                return;
            }

            let collectImageList = [];
            for (let item of this.state.collectImageList) {
                collectImageList = collectImageList.concat({imageUrl: item.url})
            }

            getDataFromServer('ups/toUpsOperation', {
                token: localCache.token,
                isRed: 1,
                orderId: this.props.orderId,
                storageOpertime: JSON.stringify([{
                    id: this.state.selectValue.value,
                    type: this.state.selectValue.type,
                    operTimeStr: getDate()
                }]),
                orderConfirmSign: this.state.signUrl,//签字图片
                orderCollectImageJSON: JSON.stringify(collectImageList),
                cargoQuantity: this.state.cargoQuantity,
                idencryption: this.state.idencryption ? encryptionInfo(this.state.idencryption) : '',
            }, () => {
                showLoading()
            }, () => {
                hideLoading()
            }).then(res => {
                showToast(t('successCollection'), 'success')
                this.setState({...this.defaultState})
                this.props.onClose(true);
            })
        } else {
            this.setState({...this.defaultState})
            this.props.onClose();
        }
    }
}

const agreementView = {
    '--icon-size': '16px',
    '--font-size': '13px',
    '--gap': '6px',
    marginLeft: '16px',
    marginBottom: '8px'
}

const bottomView = {
    margin: '0 16px 16px 16px'
}


