import React from 'react';
import { Popup, Button, List, Stepper } from 'antd-mobile';
import {scrollNowView} from "../../common/script";
import i18n from "./../../config/i18n"

export default class AddCargoDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cargoList: [],
            onlyShowInfo: false,
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            let updateInfo = {
                visible: this.props.visible,
                onlyShowInfo: this.props.onlyShowInfo || false
            };
            if (this.props.visible) {
                let cargoList = [];
                if (this.props.cargoList) {
                    for (let item of this.props.cargoList) {
                        item = JSON.parse(JSON.stringify(item));
                        if (!updateInfo.onlyShowInfo) {
                            item.quantity = 0;
                        }
                        cargoList = cargoList.concat(item)
                    }
                }
                updateInfo.cargoList = cargoList;
            }
            this.setState(updateInfo)
        }
    }

    hideVisibleClick(confirm) {
        if (confirm) {
            let cargoList = [];
            let index = 0;
            let weight = 0;
            for (let item of this.state.cargoList) {
                if (item.quantity > 0) {
                    item.sort = index;
                    cargoList = cargoList.concat(item)
                    weight += Number(item.quantity) * Number(item.cargoWeight || '0');
                }
                index++;
            }
            this.props.onClose({
                weight: weight,
                cargoList: cargoList
            });
        } else {
            this.props.onClose();
        }
    }

    inputChange(res, type) {
        let cargoInfo = this.state.cargoInfo;
        if (res.target) {
            cargoInfo[type] = res.target.value
        } else {
            cargoInfo[type] = res
        }
        this.setState({
            cargoInfo: cargoInfo
        })
    }


    render() {
        return (
            <Popup visible={this.state.visible}
                onMaskClick={() => {
                    this.hideVisibleClick()
                }}
                bodyStyle={contentView}
                stopPropagation={['enter']}
            >
                <div style={scrollNowView}>
                    <List>
                        {
                            this.state.cargoList.map((item, index) => {
                                return <List.Item prefix={item.cargoNameEn} key={index} extra={<div className={'flex-row'}>
                                    <Stepper
                                        min={0}
                                        value={item.quantity}
                                        disabled={this.state.onlyShowInfo}
                                        onChange={value => {
                                            let cargoList = this.state.cargoList;
                                            cargoList[index].quantity = value;
                                            this.setState({
                                                cargoList: cargoList
                                            })
                                        }}
                                    />
                                    <div>{i18n.t('piece')}</div>
                                </div>} />
                            })
                        }
                    </List>
                </div>
                <div className={'flex-row justify-center'}>
                    <Button color={'primary'} fill={'outline'} style={btnView(0)}
                        onClick={() => this.hideVisibleClick(false)}>{i18n.t('back')}</Button>
                    {
                        !this.state.onlyShowInfo ? <Button color={'primary'} style={btnView(1)}
                            onClick={() => this.hideVisibleClick(true)}>{i18n.t('submit')}</Button> : ''
                    }
                </div>
            </Popup>
        )
    }
}

function btnView(marginLeft) {
    return {
        width: '40%',
        marginBottom: '8px',
        marginTop: '10px',
        marginLeft: `${(marginLeft || 0) * 8}px`
    }
}

const contentView = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height:'60vh',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    display:'flex',
    flexDirection:'column'
}
