import LoginPage from "../page/web/LoginPage";
import SelectIdentityPage from "../page/mobile/SelectIdentityPage";
import fastLoginPage from "../page/mobile/FastLoginPage";
import MainPage from "../page/mobile/MainPage";
import PlaceOrderPage from "../page/mobile/PlaceOrderPage";
import PlaceUnpackOrderPage from "../page/mobile/PlaceUnpackOrderPage";
import ReceivingOrderPage from "../page/mobile/ReceivingOrderPage";
import CollectOrderPage from "../page/mobile/CollectOrderPage";
import AddressListPage from "../page/mobile/AddressListPage";
import AddressDetailPage from "../page/mobile/AddressDetailPage";
import OrderDetailPage from "../page/mobile/OrderDetailPage";
import ScanCodePage from "../page/mobile/ScanCodePage";
import EstimateFreightPage from "../page/mobile/EstimateFreightPage";
import HelpPage from "../page/mobile/HelpPage";
import HelpSearchResultPage from "../page/mobile/HelpSearchResultPage";
import UnKnowPage from "../page/mobile/UnKnowPage";

const routes = [

    {
        path: "/login",
        exact: false,
        name: "登录",
        component: LoginPage
    },
    {
        path: "/selectIdentityPage",
        exact: false,
        name: "identity",
        component: SelectIdentityPage
    },
    {
        path: "/fastLogin",
        exact: false,
        name: "login",
        component: fastLoginPage
    },
    {
        path: "/fastMainPage",
        exact: false,
        name: "home",
        component: MainPage
    },
    {
        path: "/placeOrderPage",
        exact: false,
        name: "bookDirectOrder",
        component: PlaceOrderPage
    },
    {
        path: "/estimateFreightPage",
        exact: false,
        name: "shipEstimate",
        component: EstimateFreightPage
    },
    {
        path: "/receivingOrderPage",
        exact: false,
        name: "collectList",
        component: ReceivingOrderPage
    },
    {
        path: "/placeUnpackOrderPage",
        exact: false,
        name: "bookCollectOrder",
        component: PlaceUnpackOrderPage
    },
    {
        path: "/collectOrderPage",
        exact: false,
        name: "myOrder",
        component: CollectOrderPage
    },
    {
        path: "/addressListPage",
        exact: false,
        name: "",
        component: AddressListPage
    }, {
        path: "/addressDetailPage",
        exact: false,
        name: "addressDetail",
        component: AddressDetailPage
    }, {
        path: "/orderDetailPage",
        exact: false,
        name: "orderDetail",
        component: OrderDetailPage
    }, {
        path: "/scanCode",
        exact: false,
        name: "scanCode",
        component: ScanCodePage
    }, {
        path: "/helpPage",
        exact: false,
        name: "help",
        component: HelpPage
    }, {
        path: "/helpSearchResultPage",
        exact: false,
        name: "searchHelpResult",
        component: HelpSearchResultPage
    }, {
        path: "*",
        exact: false,
        name: "404",
        component: UnKnowPage
    }
]

export default routes
