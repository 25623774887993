export const localCache = {
    _TOKEN: "token",
    _QI_NIU_TOKEN: "qiniu_token",
    _CUSTOMER_ID: "customer_id",
    _JOIN_ID: "join_id",
    _IDENTITY: "identity",
    _JOIN_CURRENCY:'join_currency',
    _SEARCH_HELP_HISTORY:'search_help_history',

    //保存的数据
    _token: '',
    _customerId: '',
    _qiNiuToken: '',
    _joinId: '',
    _identity: '',

    //用户token存储处理
    set token(val) {
        if (val === '' || val === null || val === undefined) {
            throw new Error("token is not null or undefined")
        }
        this.setItem(this._TOKEN, val);
    },

    get token() {
        return this.getItem(this._TOKEN);
    },

    //用户token存储处理
    set qiNiuToken(val) {
        if (val === '' || val === null || val === undefined) {
            throw new Error("token is not null or undefined")
        }
        this.setItem(this._QI_NIU_TOKEN, val);
    },

    get qiNiuToken() {
        return this.getItem(this._QI_NIU_TOKEN);
    },

    set joinId(val) {
        this.setItem(this._JOIN_ID, val);
    },

    get joinId() {
        return this.getItem(this._JOIN_ID);
    },

    set customerId(val) {
        this.setItem(this._CUSTOMER_ID, val);
    },


    get customerId() {
        return this.getItem(this._CUSTOMER_ID);
    },

    set identity(val) {
        this.setItem(this._IDENTITY, val);
    },

    get identity() {
        return this.getItem(this._IDENTITY) || '0';
    },

    set joinCurrency(val) {
        this.setItem(this._JOIN_CURRENCY, val);
    },

    get joinCurrency() {
        return this.getItem(this._JOIN_CURRENCY) || 'CNY';
    },

    set searchHelpHistory(val) {
        this.setItem(this._SEARCH_HELP_HISTORY, val);
    },

    get searchHelpHistory() {
        return this.getItem(this._SEARCH_HELP_HISTORY) || [];
    },

    //设置保存缓存
    setItem: (key, value) => {
        if (key) {
            if (value) {
                const newValue = JSON.stringify(value);
                return window.sessionStorage.setItem(key, newValue);
            } else {
                window.sessionStorage.removeItem(key);
            }
        } else {
            throw new Error('关键字和value不能为空！');
        }
    },

    //获取缓存信息
    getItem: (key) => {
        if (key) {
            const newGet = window.sessionStorage.getItem(key) || "";
            try {
                return JSON.parse(newGet);
            } catch (e) {
                return newGet;
            }
        } else {
            throw new Error('关键字不能为空！');
        }
    },




    CUSTOMER : '0',
    SERVER : '1',
}
