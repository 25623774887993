import {getDataFromServer} from "../../../../api/request";
import {hideLoading, showLoading} from "../../../../common/utils";
import {localCache} from "../../../../api/localCache";

export const serverHelper = {

    getOrderDetail(that) {
        if (that.state.navigationParams.orderType === 'direct') {
            return getDataFromServer('orderAudit/getOrderAuditDetail',
                {
                    token: localCache.token,
                    orderId: that.state.navigationParams.orderId,
                    type: '1'
                },
                () => {
                    showLoading()
                },
                () => {
                    hideLoading()
                });
        } else if (that.state.navigationParams.orderType === 'collect') {
            return getDataFromServer('collectionOrderAudit/getOrderPackSubDetail',
                {
                    token: localCache.token,
                    subOrderId: that.state.navigationParams.orderId,
                },
                () => {
                    showLoading()
                },
                () => {
                    hideLoading()
                });
        }
    }
}
