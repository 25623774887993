import {getDataFromServer} from "../../../../api/request";
import {hideLoading, showLoading} from "../../../../common/utils";
import {localCache} from "../../../../api/localCache";

export const customerHelper = {
    getStoreList(that) {
        return getDataFromServer('sysProduct/getStoreListByCountryId', {
            countryId: that.state.senderCountryId,
            memberCode: that.state.classId,
            joinId: localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    },

    getDiscountList(that) {
        return getDataFromServer('discount/member/getDiscountGroupInfo', {
            token: localCache.token,
            joinId: localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    },

    getProductList(that, weight, countryId) {
        return getDataFromServer('order/getProductByCountryId', {
            token: localCache.token,
            memberCode: that.state.classId,
            mailType: 1,
            weight: '',
            cargoType: 2,
            storeId: '',
            amazonAddressId: '',
            zipCode: '',
            cityId: '',
            countryId: countryId || '',
            senderCountryId: that.state.senderCountryId || '',
            joinId: localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        });
    },

    /**
     * 获取下单参数
     */
    getBookOrderParams(that) {
        let params = {};
        params.token = localCache.token;
        params.trackingNumber = '';
        params.memberCode = that.state.classId;
        params.cargoQuantity = 1;
        params.senderCountryId = that.state.senderCountryId;
        params.servicePlatform = '';
        params.isAutoCharge = 1;
        params.orderType = 2;
        params.resourceType = 0;//来源类型：0fast，1veryMall
        params.storeId = '';
        params.memberId = that.state.customerId;
        params.cargoType = 2;//货物类型：0FBA，1商业快递，2小包件
        params.needDelivery = that.state.needDelivery;//需要存储提货地址
        if (that.state.needDelivery === 0){
            params.storeId = '';
        }else{
            params.storeId = that.state.storeId;
        }
        params.deliveryName = that.state.deliveryAddress.name;
        params.deliveryCompanyName = that.state.deliveryAddress.companyName;
        params.deliveryMobileZoneCode = that.state.deliveryAddress.mobileZoneCode;
        params.deliveryPhone = that.state.deliveryAddress.phone;
        params.deliveryZipCode = that.state.deliveryAddress.zipCode;
        params.deliveryAddress = `${that.state.deliveryAddress.address || ''}${that.state.deliveryAddress.cityName || ''}${that.state.deliveryAddress.provinceName || ''}`;
        params.joinId = localCache.joinId;
        let orderCargoList = [];
        let index = 0
        for (let item of that.state.orderCargoList) {
            item.sort = index
            item.cargoQuantity = 0
            orderCargoList = orderCargoList.concat(item)
            index++;
        }

        let consigneeInfos = [];

        for (let item of that.state.consigneeInfos) {
            for (let cargoItem of item.cargoList) {
                orderCargoList[cargoItem.sort].cargoQuantity += cargoItem.quantity
            }
            item = JSON.parse(JSON.stringify(item));
            item.consigneeName = item.name;
            item.consigneeCompanyName = item.companyName;
            item.consigneeMobileZoneCode = item.mobileZoneCode;
            item.consigneeAddress = item.address;
            item.consigneePhone = item.phone;
            item.consigneeZipCode = item.zipCode;
            item.consigneeCountryId = item.countryId;
            item.consigneeCountryName = item.countryName;
            item.consigneeProvinceName = item.provinceName;
            item.consigneeCityName = item.cityName;
            item.consigneeProvinceId = '';
            item.consigneeCityId = '';
            item.consigneeCustomsNumber = '';
            item.isPack = item.checked ? 1 : 0
            delete item.name;
            delete item.companyName;
            delete item.mobileZoneCode;
            delete item.address;
            delete item.phone;
            delete item.zipCode;
            delete item.countryId;
            delete item.countryName;
            delete item.provinceName;
            delete item.cityName;
            delete item.checked;
            consigneeInfos = consigneeInfos.concat(item)
        }
        params.orderCargoListStr = JSON.stringify(orderCargoList);
        params.consigneeInfos = JSON.stringify(consigneeInfos);
        params.imageUrls = '';
        return params;
    },

    bookToOrder(that) {
        return getDataFromServer('collectionOrder/addOrder', this.getBookOrderParams(that), () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    }
}
