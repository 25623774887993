import React from 'react';
import { Popup, Input, Button, Grid } from 'antd-mobile';
import { getDataFromServer } from "../../api/request";
import { localCache } from "../../api/localCache";
import { hideLoading, showLoading, showToast } from "../../common/utils";
import { DownOutline } from 'antd-mobile-icons';
import { marginTop } from "../../common/script";
import { serverHelper } from "./js/editAddress/Server";
import { customerHelper } from "./js/editAddress/Customer";
import SelectCountry from "./SelectCountry";
import i18n from "./../../config/i18n";


export default class EditAddressDialog extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            visible: false,
            countryList: [],//国家列表
            addressInfo: {},
            selectCountryStatus: false,//选择国家弹窗
        }
    }

    componentDidMount() {
        this.getCountryList()
    }

    componentDidUpdate() {

        if (this.state.visible !== this.props.visible) {
            let updateInfo = {
                visible: this.props.visible,
            }
            if (this.props.visible) {
                let propsInfo = this.props.addressInfo || {};
                let addressInfo = {
                    memberId: propsInfo.memberId || localCache.customerId || '',
                    id: propsInfo.id || '',
                    name: propsInfo.name || '',
                    companyName: propsInfo.companyName || '',
                    mobileZoneCode: propsInfo.mobileZoneCode || '27',
                    address: propsInfo.address || '',
                    phone: propsInfo.phone || '',
                    zipCode: propsInfo.zipCode || '',
                    countryId: propsInfo.countryId || 'd59fb7e4398243c79ee7dac6e063d8df',
                    countryName: propsInfo.countryName || '南非',
                    countryEnName: propsInfo.countryEnName ||'South Africa',//发件国英文
                    provinceName: propsInfo.provinceName || '',
                    cityName: propsInfo.cityName || '',
                    areaName: propsInfo.areaName || '',
                    defaultSelection: propsInfo.defaultSelection || 0, //非默认地址
                }
                updateInfo.addressInfo = addressInfo;
            }
            this.setState(updateInfo)
        }
    }

    // 获取国码
    getCountryList() {
        getDataFromServer('system/getAllMobileCode', {}, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            this.setState({
                countryList: res.value
            })
        })
    }

    // 改变input
    handleChange = (key) => {
        // react中对对象进行双向绑定
        return (e) => {
            let address = this.state.addressInfo;
            address[key] = e.target ? e.target.value : e;
            this.setState({
                addressInfo: address
            })
        }
    }


    // 确定地址
    hideVisibleClick(submit) {
        if (submit) {
            if (this.props.addressInfo && this.props.addressInfo.id) {
                this.saveAddressClick();
            } else {
                this.props.onClose(this.state.addressInfo);
            }
        } else {
            this.props.onClose();
        }
    }

    // 保存地址
    saveAddressClick = () => {
        let request;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.saveAddress(this)
        } else {
            request = serverHelper.saveAddress(this)
        }
        request.then(res => {
            this.props.addressInfo && this.props.loadMoreData && this.props.loadMoreData(true);
            showToast(i18n.t('saved'), 'success')
        })
    }

    //获取保存地址参数
    getCurAddressItem() {
        let params = {}
        if (this.props.addressType === 'consignee') {
            params = {
                memberId: localCache.customerId,
                id: this.state.addressInfo.id,
                consigneeName: this.state.addressInfo.name,
                consigneeCompanyName: this.state.addressInfo.companyName,
                consigneeMobileZoneCode: this.state.addressInfo.mobileZoneCode,
                consigneeAddress: this.state.addressInfo.address,
                consigneePhone: this.state.addressInfo.phone,
                consigneeZipCode: this.state.addressInfo.zipCode,
                consigneeCountryId: this.state.addressInfo.countryId,
                consigneeCountryName: this.state.addressInfo.countryName,
                consigneeCountryEnName: this.state.addressInfo.countryEnName,
                consigneeProvinceId: '',
                consigneeCityId: '',
                consigneeProvinceName: this.state.addressInfo.provinceName,
                consigneeCityName: this.state.addressInfo.cityName,
                consigneeCustomsNumber: '',
                taxNumber: '',
                defaultSelection: 0,//非默认地址
            }
        } else {
            params = {
                memberId: localCache.customerId,
                id: this.state.addressInfo.id,
                senderName: this.state.addressInfo.name,
                senderCompanyName: this.state.addressInfo.companyName,
                senderMobileZoneCode: this.state.addressInfo.mobileZoneCode,
                senderAddress: this.state.addressInfo.address,
                senderPhone: this.state.addressInfo.phone,
                senderZipCode: this.state.addressInfo.zipCode,
                senderCountryId: this.state.addressInfo.countryId,
                senderCountryName: this.state.addressInfo.countryName,
                senderCountryEnName: this.state.addressInfo.countryEnName,
                senderProvinceId: '',
                senderCityId: '',
                senderProvinceName: this.state.addressInfo.provinceName,
                senderCityName: this.state.addressInfo.cityName,
                senderCustomsNumber: '',
                taxNumber: '',
                defaultSelection: 0 //非默认地址
            }
        }
        params.token = localCache.token;
        return params
    }


    render() {
        let title;
        switch (this.props.addressType) {
            case 'send':
                title = i18n.t('sender');
                break;
            case 'consignee':
                title = i18n.t('consignee');
                break;
            case 'delivery':
                title = i18n.t('pickup');
                break;
            default:
                title = i18n.t('address');
                break;
        }
        return (
            <Popup visible={this.state.visible}
                onMaskClick={() => {
                    this.hideVisibleClick()
                }}
                bodyStyle={{
                    borderTopLeftRadius: '8px',
                    borderTopRightRadius: '8px',
                    maxHeight:"60vh",
                    minHeight: '40vh',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    paddingTop: '8px',
                }}
                stopPropagation={['enter']}
            >
                <div className={'flex-between font-size15'}>
                    <div>{title}</div>
                </div>
                <Grid style={marginTop(1)} columns={24}>
                    <Grid.Item span={8}>
                        <Input style={inputView} value={this.state.addressInfo.name}
                            onChange={this.handleChange('name')} placeholder={i18n.t('name')} clearable />
                    </Grid.Item>
                    <Grid.Item span={1} />
                    <Grid.Item span={15}>
                        <Input style={inputView} value={this.state.addressInfo.phone}
                            onChange={this.handleChange('phone')} placeholder={i18n.t('phone')} clearable />
                    </Grid.Item>
                </Grid>
                <Button className={'flex-row'} color={'primary'} fill='none'
                    style={pickView(!!this.state.addressInfo.countryName)}
                    onClick={() => {
                        if (this.props.addressType !== 'delivery') {
                            this.setState({ selectCountryStatus: true })
                        }
                    }}>
                    <div className={'flex-row justify-between width100p'}>
                        {
                            i18n.language === 'en' ? this.state.addressInfo.countryEnName || i18n.t('chooseCountry'):''
                        }
                        {
                            i18n.language === 'zh' ? this.state.addressInfo.countryName || i18n.t('chooseCountry'):''
                        }
                        {
                            this.props.addressType !== 'delivery' ? <DownOutline /> : ''
                        }
                    </div>
                </Button>
                {
                    this.props.addressType !== 'delivery' ?
                        <Input style={inputView} placeholder={i18n.t('provinceState')} value={this.state.addressInfo.provinceName}
                            onChange={this.handleChange('provinceName')} clearable /> : ''
                }
                {
                    this.props.addressType !== 'delivery' ?
                        <Input style={inputView} placeholder={i18n.t('city')} value={this.state.addressInfo.cityName}
                            onChange={this.handleChange('cityName')} clearable /> : ''
                }
                <Input style={inputView} placeholder={i18n.t('addressAll')} value={this.state.addressInfo.address}
                    onChange={this.handleChange('address')} clearable />
                <Input style={inputView} placeholder={i18n.t('postCode')} value={this.state.addressInfo.zipCode}
                    onChange={this.handleChange('zipCode')} clearable />
                <Input style={inputView} placeholder={i18n.t('companyName')} value={this.state.addressInfo.companyName}
                    onChange={this.handleChange('companyName')} clearable />
                {
                    !this.props.addressInfo || !this.state.addressInfo.id ?
                        <div className={'flex-row justify-between'}>
                            {
                                this.props.addressType !== 'delivery' ?
                                    <Button color={'primary'} fill='none' style={{ padding: 0 }}
                                        onClick={this.saveAddressClick}>{i18n.t('saveAddress')}</Button> : <div />
                            }

                            <Button color={'primary'} fill='none' style={{ padding: 0, color: '#999999' }}
                                onClick={() => {
                                    this.setState({
                                        name: '',
                                        companyName: '',
                                        mobileZoneCode: '',
                                        address: '',
                                        phone: '',
                                        zipCode: '',
                                        countryId: '',
                                        countryName: '',
                                        countryEnName: '',
                                        provinceName: '',
                                        cityName: '',
                                        areaName: '',
                                    })
                                }}>{i18n.t('clearInfo')}</Button>
                        </div>
                        : <div />
                }
                <div className={'flex-row justify-center'} style={marginTop(3)}>
                    <Button color={'primary'} fill={'outline'} style={btnView(0)}
                        onClick={(e) => {
                            this.hideVisibleClick()
                        }}>{i18n.t('back')}</Button>
                    <Button color={'primary'} style={btnView(1)}
                        onClick={(e) => {
                            this.hideVisibleClick(true)
                        }}>{this.props.addressInfo && this.props.addressInfo.id ? i18n.t('update') : i18n.t('submit')}</Button>
                </div>
                <SelectCountry visible={this.state.selectCountryStatus} onClose={(countryInfo) => {
                    let updateInfo = { selectCountryStatus: false };
                    if (countryInfo && countryInfo.length > 0) {
                        countryInfo = countryInfo[0].split("&&");
                        let addressInfo = this.state.addressInfo;
                        addressInfo.countryName = countryInfo[0];
                        addressInfo.countryId = countryInfo[1];
                        addressInfo.mobileZoneCode = countryInfo[2];
                        addressInfo.countryEnName = countryInfo[3];
                        updateInfo.addressInfo = addressInfo;
                    }
                    this.setState(updateInfo);
                }} />
            </Popup>
        )
    }
}

const inputView = {
    borderBottom: 'solid 1px #DFDFDF',
    fontSize: '15px',
    marginBottom: '8px'
}

function pickView(selected) {
    return {
        width: '100%',
        padding: '0 0 8px 0',
        borderBottom: 'solid 1px #DFDFDF',
        fontSize: '15px',
        marginBottom: '8px',
        textAlign: 'left',
        color: selected ? '#333333' : '#cccccc',
        borderRadius: 0,
    }
}

function btnView(marginLeft) {
    return {
        width: '40%',
        marginBottom: '8px',
        marginTop: '10px',
        marginLeft: `${(marginLeft || 0) * 8}px`
    }
}
