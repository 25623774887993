import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {Modal} from 'antd-mobile';
import i18n from "../../../../config/i18n";

export const serverHelper = {
    login: function (that) {
        getDataFromServer('sysCustomerUser/login', {
            username: that.state.userName,
            password: that.state.passWord,
        }, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        }).then(res => {
            if(res.value.isPostman !== 1){
                //非快递员身份不能登陆 在客服端账号管理中设置
                Modal.alert({
                    content: i18n.t('notPostmanCannotLogin'),
                    confirmText:i18n.t('submit'),
                    onConfirm: () => {
                    },
                });
                return;
            }
            localCache.token = res.value.token;
            localCache.qiNiuToken = res.value.qinniutoken;
            localCache.joinId = res.value.joinId;
            this._getJoinInfo(that);
        })
    },

    _getJoinInfo: function (that) {
        getDataFromServer('sysJoinBusiness/getCustomerJoinInfo', {
            token: localCache.token
        }, () => {
        }, () => {
        }).then(res => {
            localCache.joinCurrency = res.value.defaultCurrency
            that.props.history.push("/fastMainPage")
        })
    }
}
