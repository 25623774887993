import React from 'react'
import {Button} from "antd";
import {TabBar} from 'antd-mobile'
import {marginTop, pageColorView, scrollNowView} from "../../common/script";
import {
    AppstoreOutline,
    SearchOutline
} from 'antd-mobile-icons'
import {localCache} from "../../api/localCache";
import {Translation} from "react-i18next";


export default class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabKey: 'home'
        }
    }

    render() {
        return (
           <Translation>
               {
                   (t) => <div style={pageColorView('#FAFAFA')}>
                       <div style={scrollNowView}>
                           {
                               this.state.tabKey === 'home' ?
                                   <div>
                                       <div className={'flex-row justify-center'} style={firstMarginTop}>
                                           <Button type={'primary'} style={btnView()} onClick={(e) => {
                                               this.props.history.push("/placeOrderPage")
                                           }}>{t('bookDirectOrder')}</Button>
                                           <br/>
                                       </div>
                                       {
                                           <div className={'flex-row justify-center'} style={marginTop(5)}>
                                               <Button type={'primary'} style={btnView()} onClick={(e) => {
                                                   this.props.history.push("/placeUnpackOrderPage")
                                               }}>{t('bookCollectOrder')}</Button>
                                           </div>
                                       }
                                       {
                                           localCache.identity !== localCache.CUSTOMER ?
                                               <div className={'flex-row justify-center'} style={marginTop(5)}>
                                                   <Button type={'primary'} style={btnView()} onClick={(e) => {
                                                       this.props.history.push("/receivingOrderPage")
                                                   }}>{t('collectList')}</Button>
                                               </div> : <div className={'flex-row justify-center'} style={marginTop(5)}>
                                                   <Button type={'primary'} style={btnView()} onClick={(e) => {
                                                       this.props.history.push("/estimateFreightPage")
                                                   }}>{t('shipEstimate')}</Button>
                                               </div>
                                       }
                                   </div> : <div>
                                       <div className={'flex-row justify-center'} style={firstMarginTop}>
                                           <Button type={'primary'} style={btnView()} onClick={(e) => {
                                               this.props.history.push("/collectOrderPage")
                                           }}>{localCache.identity === localCache.CUSTOMER ? t('myOrder') : t('orderReceived')}</Button>
                                       </div>
                                   </div>
                           }

                       </div>

                       <TabBar activeKey={this.state.tabKey} onChange={(e) => {
                           this.setState({
                               tabKey: e
                           })
                       }}>
                           {getTabs(t).map(item => (
                               <TabBar.Item
                                   key={item.key}
                                   icon={item.icon}
                                   title={item.title}
                               />
                           ))}
                       </TabBar>
                   </div>
               }
           </Translation>
        )
    }
}

const firstMarginTop = {
    marginTop:'15%'
}

function getTabs(t) {
    return [
        {
            key: 'home',
            title: t('sendCargo'),
            icon: <AppstoreOutline/>,
        },
        {
            key: 'todo',
            title: t('searchCargo'),
            icon: <SearchOutline/>,
        },
    ]
}

function btnView() {
    return {
        width: '80%',
        height: '50px',
        fontSize: '18px',
        borderRadius: '40px'
    }
}
