export const videoList = [
        {
            class: "basicSetup",
            data: [
                {title: 'tmsLoginDemo', url: 'https://qiniu.mrcargo.com/login.mp4'},
                {title: 'tmsCustomerSettingDemo', url: 'https://qiniu.mrcargo.com/customerSetting.mp4'},
                {title: 'tmsRateSettingDemo', url: 'https://qiniu.mrcargo.com/rateSetting.mp4'},
                {title: 'tmsNumberSetupDemo', url: 'https://qiniu.mrcargo.com/noSetting.mp4'},
                {title: 'tmsWarehouseSettingDemo', url: 'https://qiniu.mrcargo.com/wareHouseSetting.mp4'},
                {title: 'tmsGroupSettingDemo', url: 'https://qiniu.mrcargo.com/groupSetting.mp4'},
                {title: 'tmsServiceSettingDemo', url: 'https://qiniu.mrcargo.com/serviceSetting.mp4'},
            ]
        }
    ]
;
