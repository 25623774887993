import {getDataFromServer} from "../../../../api/request";
import {hideLoading, showLoading} from "../../../../common/utils";
import {customerJoinId} from "../../../../config/env";

export const customerHelper = {
    saveAddress(that){
        let url;
        let param = that.getCurAddressItem();
        param.joinId = customerJoinId
        if (that.props.addressType === 'send'|| that.props.addressType === 'delivery') {
            if (param.id){
                url = 'memberSenderAddress/updateMemberSenderAddress';
            }else{
                url = 'memberSenderAddress/addMemberSenderAddress';
            }
        } else if (that.props.addressType === 'consignee') {
            if (param.id){
                url = 'memberConsigneeAddress/updateMemberConsigneeAddress';
            }else{
                url = 'memberConsigneeAddress/addMemberConsigneeAddress';
            }
        } else {
            return;
        }
        return getDataFromServer(url,param , () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    }
}
