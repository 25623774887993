import React from 'react';
import {Popup, Collapse, Image} from 'antd-mobile';
import {dialogCloseIconStyle, marginTop} from "../../common/script";
import Big from "big.js";
import {CloseCircleOutline} from "antd-mobile-icons";
import {localCache} from "../../api/localCache";
import i18n from "./../../config/i18n"

export default class ProductDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cargoNum: 1,//包裹数量
            productList: [],//客户列表
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            let updateInfo = {visible: this.props.visible}
            if (this.props.visible) {
                updateInfo.productList = this.props.productList;
                updateInfo.cargoNum = this.props.cargoNum;
            }
            this.setState(updateInfo)
        }
    }

    hideVisibleClick(selectInfo) {
        this.props.onClose(selectInfo);
    }

    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={{
                       borderTopLeftRadius: '8px',
                       borderTopRightRadius: '8px',
                       height:"70vh",
                   }}
                   stopPropagation={['enter']}
            >
                <div className={'text-center font-bold'} style={marginTop(1)}>
                    <span>{i18n.t('singlePackage')}</span>
                    <span className={'colorD32F2F'}>5</span>
                    <span>Kg，{i18n.t('estDist')}</span>
                    <span className={'colorD32F2F'}>{Math.ceil(this.state.cargoNum)}</span>
                    <span>{i18n.t('hasPack')}</span>
                </div>
                <CloseCircleOutline className={'font-size24'} style={dialogCloseIconStyle()} onClick={()=>{
                    this.hideVisibleClick()
                }}/>
                <Collapse style={contentView}>
                    {
                        this.state.productList.map((item, index) => (
                            <Collapse.Panel key={index} title={(<div>
                                <div className={'flex-row'}>
                                    <Image style={logoImage} src={item.logo}/>
                                    <div>{item.enterpriseName}</div>
                                </div>
                                <div className={'flex-row justify-between font-size12'} style={marginTop(1)}>
                                    <div className={'color999'}>{i18n.t('timeLimit')}：{item.sendTime}</div>
                                    <div className={'colorD32F2F'}>
                                        <span>{localCache.joinCurrency} ({item.price}*{this.state.cargoNum}) = </span>
                                        <span className={'font-bold'}>{Big(item.price+'').times(this.state.cargoNum+'').toString()}</span>
                                    </div>
                                </div>
                            </div>)}>
                                <div className={'font-size12'}>
                                    <div>{i18n.t('serviceInst')}</div>
                                    <div>{item.intro || ""}</div>
                                    <div style={marginTop(1)}>{i18n.t('mailingInst')}</div>
                                    <div>{item.notice || i18n.t('null')}</div>
                                    <div style={marginTop(1)}>{i18n.t('mailingRest')}</div>
                                    <div>{item.prohibitedGoodsName || i18n.t('null')}</div>
                                    <div style={marginTop(1)}>{i18n.t('miscellaneousFees')}</div>
                                    {
                                        item.sysSuperTemplateIncidentalInfoVOList.map((feeItem, feeIndex) => {
                                            return <div key={feeIndex}>
                                                <div>{feeItem.name}({feeItem.curreny})</div>
                                                <div>
                                                    <span>{feeItem.sysSuperTemplateIncidentalSubList[0].price}</span>
                                                    <span>
                                                        {
                                                            feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? '/' : ''
                                                        }
                                                        {
                                                            feeItem.unit === 0 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? 'kg' : ''
                                                        }
                                                        {
                                                            feeItem.unit === 1 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? i18n.t('bill') : ''
                                                        }
                                                        {
                                                            feeItem.unit === 2 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? i18n.t('piece') : ''
                                                        }
                                                        {
                                                            feeItem.unit === 3 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? 'g' : ''
                                                        }
                                                        {
                                                            feeItem.unit === 4 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? 'LB' : ''
                                                        }
                                                        {
                                                            feeItem.unit === 5 && feeItem.sysSuperTemplateIncidentalSubList[0].type !== 1 ? 'OZ' : ''
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        })
                                    }
                                    {
                                        !item.sysSuperTemplateIncidentalInfoVOList || item.sysSuperTemplateIncidentalInfoVOList.length === 0 ? i18n.t('null') : ''
                                    }
                                </div>
                            </Collapse.Panel>
                        ))
                    }
                </Collapse>
            </Popup>
        )
    }
}


const contentView = {
    height: window.innerHeight * 0.7,
    overflowY: 'scroll',
    marginTop: '10px'
}

const logoImage = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    marginRight: '10px'
}
