import React from 'react';
import {pageColorView} from "../../common/script";

//地址详情
export default class AddressDetailPage extends React.Component{
    render() {
        return(
            <div style={pageColorView("#F7F7F7")}></div>
        )
    }
}
