import React from 'react';
import {Tabs, InfiniteScroll, Card, Grid, Divider} from 'antd-mobile';
import {pageColorView, scrollNowView, marginLeft, marginRight, marginTop} from './../../common/script'
import {RightOutline} from 'antd-mobile-icons'
import {localCache} from "../../api/localCache";
import {customerHelper} from "./js/collectOrder/Customer";
import {serverHelper} from "./js/collectOrder/Server";
import {Translation} from "react-i18next";

let hasNext = false;
let pageIndex = 1;
let loading = false;
let dataList = [];
let tabIndex = 'direct';
let scrollLabel = 'scrollDiv';
let scrollLocation = 0;
let refresh = true;

// 我收的订单列表
export default class CollectOrderPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: dataList,
            tabIndex: tabIndex,//
        }
    }

    componentDidMount() {
        if (scrollLocation !== 0) {
            let divObj = document.getElementById(scrollLabel);
            if (divObj) {
                divObj.scrollTop = scrollLocation
            }
        }else{
            this.loadMoreData(true)
        }
    }

    componentWillUnmount() {
        if (!refresh){
            dataList = this.state.dataList;
            tabIndex = this.state.tabIndex;
            scrollLocation = document.getElementById(scrollLabel).scrollTop
            refresh = true;
        }else{
            dataList = [];
            tabIndex = 'direct';
            scrollLocation = 0
        }
    }

    loadMoreData(firstPage) {
        let updateInfo = {};
        if (firstPage || pageIndex === 1) {
            hasNext = true;
            loading = false;
            pageIndex = 1;
            updateInfo.dataList = [];
        } else {
            updateInfo.dataList = this.state.dataList;
        }

        if (loading || !hasNext) {
            return;
        }

        let request;
        loading = true;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.getDataList(this, pageIndex);
        } else {
            request = serverHelper.getDataList(this, pageIndex);
        }

        request.then(res => {
            loading = false;
            pageIndex++;
            hasNext = res.value.hasNext
            updateInfo.dataList = updateInfo.dataList.concat(res.value.data);
            console.log(updateInfo.dataList)
            this.setState(updateInfo)
        }).catch(error => {
            loading = false;
            hasNext = false;
            this.setState(updateInfo)
        })
    };

    render() {
        return (
            <Translation>
                {(t) =>
                    <div style={pageColorView("#FAFAFA")}>
                        <Tabs activeKey={this.state.tabIndex} onChange={(e) => {
                            this.setState({
                                tabIndex: e
                            }, () => {
                                this.loadMoreData(true)
                            })
                        }}>
                            <Tabs.Tab title={t('directOrder')} key="direct"/>
                            <Tabs.Tab title={t('collectOrder')} key="collect"/>
                        </Tabs>
                        <div id={scrollLabel} style={scrollNowView}>
                            {
                                this.state.dataList.map((item, index) => (
                                    <Card key={index}
                                          style={itemView}
                                          onClick={() => {
                                              refresh = false;
                                              this.props.history.push(`/orderDetailPage?${item.id}&${this.state.tabIndex}`)
                                          }}>
                                        <Grid columns={24}>
                                            <Grid.Item span={5}>
                                                <div className={'color999 font-size15'}>
                                                    {t('systemNo')}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={14}>
                                                <div className={'color263238 font-size15'}>
                                                    {item.serialNo}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={5}>
                                                <div className={'font-size15'}>
                                                    {
                                                        item.progress === 800 || item.progress === 900 ?
                                                            <span>{t('completed')}</span> : <span className={'color52C41A'}>{t('inProgress')}</span>
                                                    }
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                                        <Grid columns={24} style={marginTop(1)}>
                                            <Grid.Item span={5}>
                                                <div className={'color999 font-size15'}>
                                                    {t('orderCreateTime')}
                                                </div>
                                            </Grid.Item>
                                            <Grid.Item span={19}>
                                                <div className={'color263238 font-size15'}>
                                                    {item.createTime}
                                                </div>
                                            </Grid.Item>
                                        </Grid>
                                        <Divider/>
                                        <div className={'flex-row justify-between width100p '}>
                                            <div className={'font-bold'}>{t('viewOrderDetails')}</div>
                                            <RightOutline/>
                                        </div>
                                    </Card>
                                ))
                            }
                            <InfiniteScroll loadMore={() => {
                                this.loadMoreData();
                            }} hasMore={hasNext} threshold={100}/>
                        </div>
                    </div>}
            </Translation>
        )
    }
}

const itemView = Object.assign(
    marginTop(2),
    marginLeft(1),
    marginRight(1)
)
