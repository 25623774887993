import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {hideLoading, showLoading} from "../../../../common/utils";

export const serverHelper = {
    getDataList(that) {
        getDataFromServer('sysOrderCargo/getList', {
            token:localCache.token
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            that.setState({
                dataList: res.value
            })
        })
    },


    delAddress(that, id) {
        let url, params = {};
        if (that.state.addressType === 'send') {
            url = 'memberSenderAddress/customerDelMemberSenderAddress';
            params = {
                token: localCache.token,
                memberSenderAddressId: id,
            }
        } else {
            url = 'memberConsigneeAddress/customerDelMemberConsigneeAddress';
            params = {
                token: localCache.token,
                memberConsigneeAddressId: id,
            }
        }
        return getDataFromServer(url, params, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        })
    }
}
