import React from "react";
import {Button, Grid, Image, ImageViewer} from "antd-mobile";
import ProtocolDialog from "../dialog/mobile/ProtocolDialog";
import i18n from "./../config/i18n";

export function marginLeft(space) {
    return {marginLeft: `${8 * (space || 0)}px`}
}

export function marginRight(space) {
    return {marginRight: `${8 * (space || 0)}px`}
}

export function marginTop(space) {
    return {marginTop: `${8 * (space || 0)}px`}
}

export function marginBottom(space) {
    return {marginBottom: `${8 * (space || 0)}px`}
}

export function scrollOutView() {
    return {
        display: 'flex',
        flexDirection: 'column',
        height: `${window.innerHeight}px`,
    }
}

export function pageColorView(color) {
    return Object.assign(scrollOutView(), {background: color || '#fff'})
}

export const scrollNowView = {
    flex: 1,
    overflowY: 'scroll',
}

export const lowGreenBtnView = {
    background: 'rgba(0,151,167,0.1)',
    color: '#0097A7',
    marginLeft: '16px',
    borderColor: 'rgba(0,151,167,0.1)',
}

export function itemView(contentView, marginTopNum = 3) {
    let style = Object.assign(marginTop(marginTopNum), {
        padding: '16px 29px 16px 16px',
        background: '#fff',
        color: '#666'
    })
    return <div className={'color666666'} style={style}>{contentView}</div>
}

export function itemDetailView(title, endView, marginTopNum = 1) {
    return <Grid key={title} columns={24} style={marginTop(marginTopNum)}>
        <Grid.Item span={8}>
            <div className={'color999 font-size15'}>
                {title}
            </div>
        </Grid.Item>
        <Grid.Item span={12}>
            {
                endView
            }
        </Grid.Item>
    </Grid>
}

export function itemGoodsView(title, endView,showLine = true) {
    return <div style={itemGoodsViewStyle}>
        <Grid key={title} columns={24} >
            <Grid.Item span={9}>
                <div className={'color999 font-size15'}>
                    {title}
                </div>
            </Grid.Item>
            <Grid.Item span={15}>
                {
                    endView
                }
            </Grid.Item>
        </Grid>
        {
            showLine?<div style={{borderBottom:'solid 1px #cccccc',paddingBottom:'8px'}}/>:<div style={{paddingBottom:'8px'}}/>
        }
    </div>
}

export const itemGoodsViewStyle = {padding: '8px 16px 0 16px',}

export function blackTextView(content, color, onClick) {
    color = color || 'color263238';
    return <div className={`${color} font-size15`} onClick={onClick}>
        {content || '-'}
    </div>
}

export function imageListView(imageList) {
    imageList = imageList || [];
    return <div className={'flex-row'}>
        {
            imageList.map((item, index) => {
                return <Image width={40} height={40} alt={item} src={item} fit={'contain'} onClick={() => {
                    ImageViewer.Multi.show({images: imageList})
                }}/>
            })
        }
    </div>
}

/**
 * 协议样式
 * @param that
 * @param block
 * @return {*}
 */
export function protocolView(that, block: ()=>{}) {
    return <div className={'flex-row'}>
        <div>{i18n.t('readAndAgree')}</div>
        <Button color='primary' fill='none' size={'mini'} onClick={(e) => {
            e.stopPropagation()
            that.setState({
                protocolDialogStatus: true
            })
        }}>{i18n.t('electronicContract')}
        </Button>
        <ProtocolDialog visible={that.state.protocolDialogStatus} onClose={(status) => {
            let updateInfo = {protocolDialogStatus: false};
            if (status) {
                block(updateInfo);
            }
            that.setState(updateInfo)
        }}/>
    </div>
}

export function dialogCloseIconStyle() {
    return {
        marginRight: '8px',
        position: 'absolute',
        top: 0,
        right: 0,
        marginTop: '8px'
    }
}
