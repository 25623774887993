import React from 'react';
import { Popup, Button, InfiniteScroll } from 'antd-mobile';
import {marginBottom, marginLeft, marginRight} from "../../common/script";
import { localCache } from "../../api/localCache";
import { DeleteOutline, EditSFill,CloseCircleOutline } from "antd-mobile-icons";
import EditAddressDialog from './EditAddressDialog'
import { showToast } from "../../common/utils";
import { serverHelper } from "./js/selectAddress/Server";
import { customerHelper } from "./js/selectAddress/Customer";
import i18n from "./../../config/i18n";

let pageIndex = 1;
let hasNext = true;

export default class SelectCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            dataList: [],
            addressType: 'send',
            visible: false,
            addressEditStatus: false,//修改地址
            currentAddressItem: {}
        }
    }

    componentDidUpdate() {

        if (this.state.visible !== this.props.visible) {
            console.log(1)
            if (this.props.visible) {
                console.log(2)
                pageIndex = 1;
                hasNext = true;
                this.setState({
                    visible: true,
                    addressType: this.props.addressType,
                }, () => {
                    // debugger
                    this.loadMoreData(true)
                })
            } else {
                this.setState({
                    visible: false,
                })
            }
        }
    }

    hideVisibleClick(selectInfo, isGetProduct) {

        this.props.onClose(selectInfo, isGetProduct);
    }

    loadMoreData = (isModify) => {

        if (isModify) {
            pageIndex = 1
            let request;
            if (localCache.identity === localCache.CUSTOMER) {
                request = customerHelper.getDataList(this, pageIndex);
            } else if (localCache.identity === localCache.SERVER) {
                request = serverHelper.getDataList(this, pageIndex);
            }
            if (!request) {
                hasNext = false;
                return
            }
            if (pageIndex === 1) {
                this.setState({
                    dataList: []
                })
            }
            request.then(res => {
                pageIndex++;
                let dataList = this.state.dataList;

                for (let item of res.value.data) {
                    let addItem = {};
                    if (this.state.addressType === 'send' || this.state.addressType === 'delivery') {
                        addItem.name = item.senderName;
                        addItem.mobileZoneCode = item.senderMobileZoneCode;
                        addItem.phone = item.senderPhone;
                        addItem.countryName = item.senderCountryName;
                        addItem.countryEnName = item.senderCountryEnName;
                        addItem.provinceName = item.senderProvinceName;
                        addItem.cityName = item.senderCityName;
                        addItem.address = item.senderAddress;
                        addItem.zipCode = item.senderZipCode;
                        addItem.companyName = item.senderCompanyName;
                        addItem.countryId = item.senderCountryId;
                    } else if (this.state.addressType === 'consignee') {
                        addItem.name = item.consigneeName;
                        addItem.mobileZoneCode = item.consigneeMobileZoneCode;
                        addItem.phone = item.consigneePhone;
                        addItem.countryName = item.consigneeCountryName;
                        addItem.countryEnName = item.consigneeCountryEnName;
                        addItem.provinceName = item.consigneeProvinceName;
                        addItem.cityName = item.consigneeCityName;
                        addItem.address = item.consigneeAddress;
                        addItem.zipCode = item.consigneeZipCode;
                        addItem.companyName = item.consigneeCompanyName;
                        addItem.countryId = item.consigneeCountryId;
                    } else {
                        return;
                    }
                    addItem.defaultSelection = item.defaultSelection
                    addItem.id = item.id
                    addItem.memberId = item.memberId
                    dataList = dataList.concat(addItem)

                }

                hasNext = res.value.hasNext;



                this.setState({
                    dataList: dataList,
                    addressEditStatus: false
                }, () => {
                    console.log(this.state.dataList)
                })
            }).catch(error => {
                hasNext = false;
            })
        } else {
            if (!hasNext || !this.state.visible) {
                return;
            }
        }

    };

    // 编辑地址
    modifyAddressClick(item) {
        this.setState({
            currentAddressItem: item,
            addressEditStatus: true,
        })
    }

    // 删除地址
    deleteAddressClick(id) {
        let request;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.delAddress(this, id)
        } else {
            request = serverHelper.delAddress(this, id)
        }
        request.then(res => {
            this.loadMoreData(true)
            showToast(i18n.t('deleted'), 'success')
        })
    }

    render() {
        return (
            <>
                <Popup visible={this.state.visible}
                    onMaskClick={() => {
                        this.hideVisibleClick()
                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        maxHeight:"60vh",
                        minHeight: '40vh',
                    }}
                    stopPropagation={['enter']}
                >
                    <div style={contentView}>
                        <div className={'flex-row justify-end'} style={marginBottom(1)}>
                            <CloseCircleOutline className={'font-size24'} style={marginRight(1)} onClick={()=>{
                                this.hideVisibleClick()
                            }}/>
                        </div>
                        {
                            this.state.dataList.map((item, index) => (
                                <div key={index}
                                    style={Object.assign(marginLeft(1), marginRight(1))}
                                    onClick={(e) => {
                                        // debugger
                                        this.hideVisibleClick(item, true)
                                    }}>
                                    <div className={'color263238 font-size15'}>
                                        <span>{item.name}</span>
                                        <span style={marginLeft(1)}>{item.mobileZoneCode}-{item.phone}</span>
                                    </div>
                                    <div className={'color666666 font-size13'}>
                                        {
                                            i18n.language === 'en' ? item.countryEnName || '':''
                                        }
                                        {
                                            i18n.language === 'zh' ? item.countryName || '':''
                                        }
                                        {item.provinceName || ''}
                                        {item.cityName || ''}
                                        {item.address || ''}
                                        {item.zipCode || ''}
                                    </div>
                                    <div style={cardFooter} onClick={e => e.stopPropagation()}>
                                        <Button color={'primary'} fill={'none'} onClick={(e) => {
                                            this.modifyAddressClick(item)
                                        }} size={'mini'} style={{ color: '#666666' }}>
                                            <EditSFill /> {i18n.t('edit')}
                                        </Button>
                                        <Button color={'primary'} fill={'none'} onClick={(e) => {
                                            this.deleteAddressClick(item.id)
                                        }} size={'mini'} style={{ color: '#666666' }}>
                                            <DeleteOutline /> {i18n.t('delete')}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        <InfiniteScroll loadMore={() => {
                            this.loadMoreData();
                        }} hasMore={hasNext} threshold={50} />
                    </div>
                </Popup>
                {/* 编辑地址 */}
                {this.state.addressEditStatus ?
                    <EditAddressDialog visible={this.state.addressEditStatus}
                        addressType={this.state.addressType === 'delivery' ? 'send' : this.state.addressType}
                        addressInfo={this.state.currentAddressItem}
                        loadMoreData={this.loadMoreData}
                        onClose={(address) => {
                            this.setState({ addressEditStatus: false })
                        }} /> : <div />}

            </>

        )
    }
}


const contentView = {
    height: window.innerHeight * 0.70,
    overflowY: 'scroll',
    marginTop: '10px'
}


const cardFooter = {
    borderBottom: '1px solid #e5e5e5',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '8px'
}
