import React from 'react'
import {Popup, Button} from "antd-mobile";
import {lowGreenBtnView, marginBottom, marginLeft, marginTop} from "../../common/script";
import {uploadImg} from "../../common/utils";
import i18n from "./../../config/i18n";

let canvasContent;
let canvasTop;

export default class SignDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            canvas: '',
            canvasInfo: {
                width: 0,
                height: 0,
            }
        }
        this.setCanvasSize.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.setCanvasSize)
    }

    setCanvasSize = () => {
        this.setState({
            canvasInfo: {
                width: window.innerWidth,
                height: window.innerHeight * 0.6 - 120,
            }
        }, () => {
            this.getCanvasInfo();
        })
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setCanvasSize)
    }

    getCanvasInfo() {
        let box = null;
        if (!this.state.canvas) {
            let canvas = document.getElementById('signCanvas');
            if (canvas) {
                canvas.antialias = true
                canvasContent = canvas.getContext("2d");
                canvasContent.lineJoin = 'round';
                canvasContent.lineCap = 'round'
                // this.clearCanvasContent()
                box = canvas.getBoundingClientRect();
                canvasContent.fillStyle = '#fff'
                canvasContent.fillRect(0, 0, box.width, box.height)
                this.setState({
                    canvas: canvas
                })
            } else {
                return
            }
        }
        if (box == null) {
            box = this.state.canvas.getBoundingClientRect();
        }
        canvasTop = box.y;
        if (!this.props.signUrl) {
            this.clearCanvasContent();
        }
    }

    render() {
        return <Popup visible={this.state.visible}
                      onMaskClick={() => {
                          this.hideVisibleClick()
                      }}
                      bodyStyle={bodyStyle}
                      stopPropagation={['enter']}
                      afterShow={() => this.setCanvasSize()}>
            <div className={'font-size18 color263238 font-bold text-center'}
                 style={Object.assign(marginTop(2), marginBottom(2))}>{i18n.t('signSpace')}
            </div>
            <div onTouchMove={(e) => {
                this.onTouchMoveListener(e)
            }} onTouchStart={(e) => {
                this.onTouchStartListener(e)
            }} onLoad={() => {
                this.getCanvasInfo();
            }}>
                <canvas id={'signCanvas'}
                        width={this.state.canvasInfo.width}
                        height={this.state.canvasInfo.height}
                        style={{width: '100%'}}/>
            </div>
            <div style={Object.assign(marginTop(1), marginBottom(1))}>
                {
                    this.state.canvas ? (
                        <div className={'flex-row justify-center'}>
                            <Button color='primary' fill='outline' onClick={() => {
                                this.hideVisibleClick()
                            }}>{i18n.t('back')}</Button>
                            <Button color='primary' fill='solid' style={lowGreenBtnView} onClick={() => {
                                this.clearCanvasContent();
                            }}>{i18n.t('clear')}</Button>
                            <Button color='primary' style={marginLeft(2)} onClick={() => {
                                this.submitSignClick()
                            }}>{i18n.t('submit')}</Button>
                        </div>
                    ) : <div className={'flex-row justify-center'}>
                        <Button color='primary' fill='solid' style={lowGreenBtnView} onClick={() => {
                            this.setCanvasSize();
                        }}>{i18n.t('reset')}</Button>
                    </div>
                }
            </div>

        </Popup>;
    }

    onTouchStartListener(e) {
        let touche = e.touches[0];
        let x = touche.clientX, y = touche.clientY - (canvasTop || 0)
        if (this.state.canvas) {
            canvasContent.moveTo(x, y);
        }
    }


    onTouchMoveListener(e) {
        let touche = e.touches[0];
        let x = touche.clientX, y = touche.clientY - (canvasTop || 0)
        if (this.state.canvas) {
            canvasContent.lineTo(x, y);
            canvasContent.stroke()
        }
    }

    /**
     *关闭弹窗
     */
    hideVisibleClick(signInfo) {
        if (!this.props.signUrl && !signInfo) {
            this.clearCanvasContent();
        }
        this.props.onClose(signInfo)
    }

    /**
     * 清除canvas内容
     */
    clearCanvasContent() {
        if (this.state.canvas) {
            let canvas = this.state.canvas;
            let height = canvas.height;
            canvas.height = height;
            let box = this.state.canvas.getBoundingClientRect();
            canvasContent.fillStyle = '#fff'
            canvasContent.fillRect(0, 0, box.width, box.height)
        }
    }

    /**
     * 点击
     * 确认保存签字
     */
    submitSignClick() {
        this.state.canvas.toBlob((blob) => {
            uploadImg(blob).then(res => {
                this.hideVisibleClick(`https://qiniu.mrcargo.com/${res.data.key}`)
            })
        }, 'image/png', 1)
    }
}


const bodyStyle = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: "60vh",
    background: '#FAFAFA'
}

