import React from 'react';
import {Steps, Button, Grid, Divider, Checkbox, Picker, Input, List, ImageUploader} from 'antd-mobile';
import {
    itemView,
    marginBottom,
    marginLeft,
    marginRight,
    marginTop,
    pageColorView, protocolView,
    scrollNowView
} from "../../common/script";
import {RightOutlined} from '@ant-design/icons';
import SelectCustomer from './../../dialog/mobile/SelectCustomer'
import SelectAddress from './../../dialog/mobile/SelectAddress'
import EditAddressDialog from './../../dialog/mobile/EditAddressDialog'
import CargoListDialog from '../../dialog/mobile/CargoListDialog'
import {getDataFromServer} from "../../api/request";
import {localCache} from "../../api/localCache";
import {hideLoading, judgeNumberInput, showLoading, showToast, uploadImg} from "../../common/utils";
import {customerHelper} from "./js/placeOrder/Customer";
import {serverHelper} from "./js/placeOrder/Server";
import SignDialog from "../../dialog/mobile/SignDialog";
import {getCargoTypeList, getMailTypeList} from "../../api/dataCommon";
import {Translation} from "react-i18next";
import i18n from "./../../config/i18n";

const {Step} = Steps;
const steps = [
    {
        title: 'orderInfo',
        content: 'First-content',
    },
    {
        title: 'contact',
        content: 'Second-content',
    },
    {
        title: 'goodsInfo',
        content: 'Last-content',
    },
    {
        title: 'pay',
        content: 'Last-content',
    },
];

const payTypeList = [{key: 'senderPays', value: 0}, {key: 'consigneeReceives', value: 1}];//付款方式列表


export default class PlaceOrderPage extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            current: 0,
            customerStatus: false,//客户选择显示状态
            addressStatus: false,//地址选择显示状态
            addressEditStatus: false,//地址编辑显示状态
            cargoStatus: false,//商品信息弹窗
            addressType: '',//地址选择的类型

            productList: [],//产品列表
            outStoreList: [],//出库仓库列表
            packageList: [],//商品信息列表
            classList: [],//群组列表

            orderCargoList: [],//商品信息列表
            cargoQuantity: '1',//预计包裹件数
            estimateWeight: '',//预计重量
            mailType: 1,//包装类型
            cargoType: 1,//货物类型
            taxMethod: 0,//税金方式
            customerName: "",//选择客户名称
            mobileCode: "",//选择客户手机国码
            mobile: "",//选择客户手机号
            customerId: "",//选择客户id
            customerNum: "",//选择客户Num
            className: "",//选择群组名称
            classId: "",//选择群组id
            productName: "",//选择群组名称
            productId: "",//选择群组id
            storeName: "",//选择群组名称
            storeId: "",//选择群组id
            estimateFreight: 0,//预估运费
            imageUrlList: [],//图片列表

            send: {
                name: '',
                mobileZoneCode: '',
                phone: '',
                countryName: '',
                countryEnName: '',
                provinceName: '',
                cityName: '',
                address: '',
                zipCode: '',
            },
            consignee: {
                name: '',
                mobileZoneCode: '',
                phone: '',
                countryName: '',
                countryEnName: '',
                provinceName: '',
                cityName: '',
                address: '',
                zipCode: '',
            },
            agreementStatus: false,//协议同意状态
            signDialogStatus: false,//签字弹窗状态
            signUrl: '',//签字图片路径
        }
    }

    componentDidMount() {
        if (localCache.identity === localCache.CUSTOMER) {
            this.getDiscountListByMobile();
        }
    }

    inputChange(res, type) {
        let value = res.target ? res.target.value : res;
        if (type === 'cargoQuantity') {
            value = judgeNumberInput(value);
        }

        this.setState({
            [type]: value
        })
    }

    /**
     * 下一步 跳转机制
     * @param e 事件
     * @param step 步数 可正可负
     */
    nextStepClick(e, step) {
        step = this.state.current + step;
        if (step === 2) {
            this.getProductList();
        }
        this.setState({
            current: step
        })
    }


    render() {
        return (
            <Translation>
                {
                    (t) => <div style={pageColorView("#F7F7F7")}>
                        <Steps current={this.state.current} size={'small'} style={topStepView}>
                            {steps.map(item => (
                                <Step key={item.title} title={t(item.title)}/>
                            ))}
                        </Steps>
                        <div style={scrollNowView}>
                            {this.state.current === 0 ? this.firstStepView(t) : ''}
                            {this.state.current === 1 ? this.secondStepView(t) : ''}
                            {this.state.current === 2 ? this.thirdStepView(t) : ''}
                            {this.state.current === 3 ? this.fourthStepView(t) : ''}
                        </div>
                        <div className={'flex-row justify-center'} style={marginBottom(2)}>
                            {
                                this.state.current !== 0 ? <Button
                                    style={proOrNextBtn(this.state.current === 3 ? '80%' : '40%')}
                                    color={'primary'}
                                    fill={'outline'}
                                    onClick={e => this.nextStepClick(e, -1)}>{t('previous')}</Button> : <div/>
                            }
                            {
                                this.state.current !== 3 ? <Button
                                    style={Object.assign(proOrNextBtn(this.state.current === 0 ? '80%' : '40%'), marginLeft(this.state.current !== 0 ? 1 : 0))}
                                    color={'primary'}
                                    disabled={this.getNextBtnStatus()}
                                    onClick={e => this.nextStepClick(e, 1)}>{t('nextStep')}</Button> : <div/>
                            }
                        </div>
                        {
                            this.state.current === 3 ? <div>
                                    <Checkbox style={agreementView} size={'small'}
                                              checked={this.state.agreementStatus}
                                              onChange={(e) => this.setState({agreementStatus: e})}>
                                        {
                                            protocolView(this, (updateInfo) => {
                                                updateInfo.agreementStatus = true;
                                            })
                                        }
                                    </Checkbox>
                                    <div style={bottomView}>
                                        <div>
                                            <div className={'font-size18'}>
                                                <span className={'color263238'}>{t('estimate')}</span>
                                                <span className={'color263238 font-size13'}>({localCache.joinCurrency}):</span>
                                                <span className={'colorD32F2F'}>{this.state.estimateFreight || 0}</span>
                                            </div>

                                        </div>
                                        <div className={'flex-row'}>
                                            {
                                                localCache.identity !== localCache.CUSTOMER ?
                                                    <Button color={'primary'} fill='outline'
                                                            onClick={(e) => this.setState({signDialogStatus: true})}>{this.state.signUrl ? t('signed') : t('notSigned')}</Button>
                                                    : ''
                                            }
                                            <Button color={'primary'} style={marginLeft(1)} onClick={(e) => this.bookToOrder(t)}>{t('placeOrder')}</Button>
                                        </div>
                                        <SignDialog visible={this.state.signDialogStatus} onClose={(signUrl) => {
                                            let updateInfo = {signDialogStatus: false};
                                            if (signUrl) {
                                                updateInfo.signUrl = signUrl;
                                            }
                                            this.setState(updateInfo)
                                        }}/>
                                    </div>
                                </div> : ''
                        }
                    </div>
                }
            </Translation>
        )
    }

    /**
     * 第一步页面 选择客户
     */
    firstStepView(t) {
        let mailTypeList = getMailTypeList();
        let cargoTypeList = getCargoTypeList();
        return <div>
            {
                localCache.identity !== localCache.CUSTOMER ? itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('ownCustomer')}</div>
                        <div className={'flex-row'} onClick={() => this.setState({customerStatus: true})}>
                            <div style={marginRight(1)}>{this.state.customerName || t('choose')}</div>
                            <RightOutlined/></div>
                    </div>
                )) : ''
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('affGroup')}</div>
                        <div className={'flex-row'} onClick={async () => {
                            if (!this.state.customerId && localCache.identity !== localCache.CUSTOMER) {
                                showToast(t('selectCustomer'), 'warning');
                                return;
                            } else if (this.state.classList.length === 0) {
                                showToast(t('noSearchGroup'), 'warning');
                                return;
                            }
                            let value = await Picker.prompt({columns: this.state.classList})
                            if (value != null && value.length > 0 && value[0]) {
                                value = value[0].split("&&");
                                this.setState({
                                    className: value[0],//选择群组名称
                                    classId: value[1],//选择群组id
                                })
                            }
                        }}>
                            <div style={marginRight(1)}>{this.state.className || t('choose')}</div>
                            <RightOutlined/></div>
                    </div>
                ))
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('packType')}</div>
                        <div className={'flex-row'} onClick={async () => {
                            let value = await Picker.prompt({columns: [mailTypeList]})
                            if (value != null && value.length > 0) {
                                this.setState({
                                    mailType: value[0],//包装类型
                                })
                            }
                        }}>
                            <div style={marginRight(1)}>{mailTypeList[this.state.mailType].label || t('choose')}</div>
                            <RightOutlined/></div>
                    </div>
                ))
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('cargoType')}</div>
                        <div className={'flex-row'} onClick={async () => {
                            let value = await Picker.prompt({columns: [cargoTypeList]})
                            if (value != null && value.length > 0) {
                                this.setState({
                                    cargoType: value[0],//包装类型
                                })
                            }
                        }}>
                            <div style={marginRight(1)}>{cargoTypeList[this.state.cargoType].label || t('choose')}</div>
                            <RightOutlined/></div>
                    </div>
                ))
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('packageNumEst')}</div>
                        <Input className={'mobile-input-text-end width50p'} type={'number'} placeholder={t('input')}
                               value={this.state.cargoQuantity}
                               clearable onChange={(e) => this.inputChange(e, 'cargoQuantity')}/>
                    </div>
                ))
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('estWeight')}(Kg)</div>
                        <Input className={'mobile-input-text-end width50p'} type={'number'} placeholder={t('input')}
                               value={this.state.estimateWeight}
                               clearable onChange={(e) => this.inputChange(e, 'estimateWeight')}/>
                    </div>
                ))
            }
            <SelectCustomer visible={this.state.customerStatus} onClose={(userInfo) => {
                let updateState = {customerStatus: false}
                if (userInfo && userInfo.length > 0) {
                    userInfo = userInfo[0].split("&&");
                    updateState.customerName = userInfo[0];//选择客户名称
                    updateState.customerId = userInfo[1];//选择客户id
                    updateState.customerNum = userInfo[2];//选择客户id
                    updateState.mobileCode = userInfo[3];//选择客户id
                    updateState.mobile = userInfo[4];//选择客户id
                    localCache.customerId = userInfo[1];
                }
                this.setState(updateState, (e) => {
                    if (userInfo && userInfo.length > 0) {
                        this.getDiscountListByMobile();
                    }
                })
            }}/>
        </div>
    }

    /**
     * 获取折扣群
     */
    getDiscountListByMobile() {
        let request;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.getDiscountList(this);
        } else if (localCache.identity === localCache.SERVER) {
            request = serverHelper.getDiscountList(this);
        } else {
            return
        }
        request.then(res => {
            let classList = [];
            let reposeList = [];
            if (localCache.identity === localCache.CUSTOMER) {
                reposeList = res.value.discountGroupList;
            } else if (localCache.identity === localCache.SERVER) {
                reposeList = res.value;
            }
            for (let item of reposeList) {
                classList = [...classList, {
                    label: item.groupName,
                    value: `${item.groupName}&&${item.memberCode}`
                }]
            }
            this.setState({
                classList: [classList]
            })
        })
    }

    /**
     * 第二步页面 收发件人
     */
    secondStepView(t) {
        return itemView(
            <div className={'flex-row'}>
                <div className={'flex-col'}>
                    <img width={20} height={20} alt={t('sender')} src={'images/ic_send.webp'}/>
                    <img width={6} height={50} alt={'To'} style={marginTop(1)} src={'images/ic_address_to.webp'}/>
                    <img width={20} height={20} alt={t('consignee')} style={marginTop(1)} src={'images/ic_consigee.webp'}/>
                </div>
                <div className={'flex-col'} style={Object.assign(marginLeft(1), {flex: 1})}>
                    {this.addressView('send',t)}
                    <Divider className={'width100p'}/>
                    {this.addressView('consignee',t)}
                </div>
                <SelectAddress visible={this.state.addressStatus} addressType={this.state.addressType}
                               onClose={(address) => {
                                   let updateState = {addressStatus: false}
                                   if (address) {
                                       if (this.state.addressType === 'send') {
                                           updateState.send = address;
                                       } else if (this.state.addressType === 'consignee') {
                                           updateState.consignee = address;
                                       }
                                   }
                                   this.setState(updateState)
                               }}/>
                <EditAddressDialog visible={this.state.addressEditStatus} addressType={this.state.addressType}
                                   addressInfo={this.state.addressType === 'send' ? this.state.send : this.state.consignee}
                                   onClose={(address) => {
                                       let updateState = {addressEditStatus: false}
                                       if (address) {
                                           if (this.state.addressType === 'send') {
                                               updateState.send = address;
                                           } else if (this.state.addressType === 'consignee') {
                                               updateState.consignee = address;
                                           }
                                       }
                                       this.setState(updateState)
                                   }}/>
            </div>
        )
    }

    /**
     * 地址itemView
     * @param addressType 地址类型 send 寄件人 consignee 收件人
     */
    addressView(addressType,t) {
        let address = this.state[addressType];
        let countryName = "";
        if (i18n.language === 'en'){
            countryName = address.countryEnName
        }

        if (!countryName){
            countryName = address.countryName
        }

        let addressStr = `${countryName || ''}${address.provinceName || ''}${address.cityName || ''}${address.address || ''}${address.zipCode || ''}`;
        return <Grid className={'width100p'} columns={24}>
            <Grid.Item span={18}>
                <div onClick={(e) => {
                    this.setState({
                        'send.id': '',
                        'consignee.id': '',
                        addressEditStatus: true,
                        addressType: addressType
                    })
                }}>
                    <div className={'font-size15 color263238'}>
                        {
                            address.name || address.phone ?
                                <div>
                                    <span>{address.name}</span>
                                    <span style={marginLeft(1)}>{address.phone}</span>
                                </div> : <div>{addressType === 'send' ? t('senderInfo') : t('consigneeInfo')}</div>
                        }
                    </div>
                    <div className={'font-size13'}>
                        {
                            addressStr || t('clickAddInfo')
                        }
                    </div>
                </div>
            </Grid.Item>
            <Grid.Item className={'flex-row flex-center'} span={1}>
                <div style={{background: '#DFDFDF', width: '1px', height: '100%'}}/>
            </Grid.Item>
            <Grid.Item span={5}>
                <div className={'flex-row height100p'} onClick={(e) => {
                    this.setState({
                        addressStatus: true,
                        addressType: addressType
                    })
                }}>
                    <div className={'text-center font-size13'}>{t('chooseAddress')}</div>
                </div>
            </Grid.Item>
        </Grid>
    }

    /**
     * 第三步页面 物品信息
     */
    thirdStepView(t) {
        return <div>
            <List>
                <List.Item title={t('uploadPic')} description={<ImageUploader
                    style={marginTop(1)}
                    value={this.state.imageUrlList}
                    onChange={(e) => {
                        this.setState({
                            imageUrlList: e
                        })
                    }}
                    upload={async (res) => {
                        let response = await uploadImg(res)
                        return {
                            url: `https://qiniu.mrcargo.com/${response.data.key}`,
                        }
                    }
                    }
                />}>
                </List.Item>
            </List>
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('goodsInfo')}</div>
                        <div className={'flex-row'} onClick={(e) => {
                            this.setState({
                                cargoStatus: true,
                            })
                        }}>
                            <div
                                style={marginRight(1)}>{this.state.orderCargoList.length > 0 ? `${t('filledIn')}${this.state.orderCargoList.length}${t('records')}` : t('fillOut')}</div>
                            <RightOutlined/></div>
                    </div>
                ))
            }
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('service')}</div>
                        <div className={'flex-row'} onClick={async () => {
                            if (this.state.productList.length === 0) {
                                showToast(t('noServiceChoose'));
                                return;
                            }
                            let value = await Picker.prompt({columns: [this.state.productList]})
                            if (value != null && value.length > 0) {
                                value = value[0].split("&&");
                                this.setState({
                                    productName: value[0],//选择群组名称
                                    productId: value[1],//选择群组id
                                    estimateFreight: value[2],//预估费用
                                }, () => {
                                    if (localCache.identity !== localCache.CUSTOMER) {
                                        this.getStoreList();
                                    }
                                })
                            }
                        }}>
                            <div style={marginRight(1)}>{this.state.productName || t('choose')}</div>
                            <RightOutlined/>
                        </div>
                    </div>
                ))
            }
            {/*{*/}
            {/*    localCache.identity !== localCache.CUSTOMER ? itemView((*/}
            {/*        <div className={'flex-row justify-between'}>*/}
            {/*            <div>最终出货仓</div>*/}
            {/*            <div className={'flex-row'} onClick={async () => {*/}
            {/*                if (!this.state.productId) {*/}
            {/*                    showToast('请先选择产品', 'warning');*/}
            {/*                    return;*/}
            {/*                }*/}
            {/*                let value = await Picker.prompt({columns: [this.state.outStoreList]})*/}
            {/*                if (value != null && value.length > 0) {*/}
            {/*                    value = value[0].split("&&");*/}
            {/*                    this.setState({*/}
            {/*                        storeName: value[0],//选择群组名称*/}
            {/*                        storeId: value[1],//选择群组id*/}
            {/*                    })*/}
            {/*                }*/}
            {/*            }}>*/}
            {/*                <div style={marginRight(1)}>{this.state.storeName || t('choose')}</div>*/}
            {/*                <RightOutlined/></div>*/}
            {/*        </div>*/}
            {/*    )) : ''*/}
            {/*}*/}
            <CargoListDialog visible={this.state.cargoStatus}
                             onClose={(cargoInfoList) => {
                                 this.setState({cargoStatus: false, orderCargoList: cargoInfoList})
                             }}/>
        </div>
    }

    /**
     * 获取产品列表
     */
    getProductList() {
        let request;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.getProductList(this);
        } else if (localCache.identity === localCache.SERVER) {
            request = serverHelper.getProductList(this);
        }
        request.then(res => {
            let product = [];
            for (let item of res.value) {
                product = product.concat({
                    label: item.enterpriseName,//选择群组名称
                    value: `${item.enterpriseName}&&${item.servicePlatform}&&${item.price}`,//选择群组id
                })
            }
            this.setState({
                productList: product
            })
        })
    }


    /**
     * 获取出货仓列表
     */
    getStoreList() {
        getDataFromServer('storageHouse/getStorageHouseListByType', {
            token: localCache.token,
            type: 2,
            servicePlatform: this.state.productId,
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            let outStoreList = [];
            for (let item of res.value) {
                outStoreList = outStoreList.concat({
                    label: item.storageName,//选择群组名称
                    value: `${item.storageName}&&${item.id}`,//选择群组id
                })
            }
            this.setState({
                outStoreList: outStoreList
            })
        })
    }

    /**
     * 第四步页面 付款
     */
    fourthStepView(t) {
        for (let item of payTypeList){
            item.label = t(item.key);
        }
        return <div>
            {
                itemView((
                    <div className={'flex-row justify-between'}>
                        <div>{t('paymentMethod')}</div>
                        <div className={'flex-row'} onClick={async () => {
                            let value = await Picker.prompt({columns: [payTypeList]});
                            if (value != null) {
                                this.setState({taxMethod: value[0]})
                            }
                        }}>
                            <div
                                style={marginRight(1)}>{payTypeList[this.state.taxMethod].label || t('choose')}</div>
                            <RightOutlined/></div>
                    </div>
                ))
            }
        </div>
    }

    /**
     * 下一步按钮的选择状态
     */
    getNextBtnStatus() {
        if (this.state.current === 0) {//第一步 订单信息
            if (localCache.identity === localCache.CUSTOMER) {
                return !this.state.classId || !this.state.cargoQuantity;
            } else if (localCache.identity === localCache.SERVER) {
                return !this.state.customerId || !this.state.classId || !this.state.cargoQuantity;
            }
        }
        if (this.state.current === 1) {//第二步 收发件人
            return !this.state.send.countryId || !this.state.send.name || !this.state.consignee.countryId || !this.state.consignee.countryId
        } else if (this.state.current === 2) {//第三步 物品信息
            if (localCache.identity === localCache.CUSTOMER) {
                return !this.state.productId
            } else if (localCache.identity === localCache.SERVER) {
                return !this.state.productId
            }
        }
        return false;
    }


    /**
     * 点击
     * 下订单
     */
    bookToOrder(t) {
        if (!this.state.agreementStatus) {
            showToast(t('agreeFirst'), 'warning')
            return
        }

        let request;
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.bookToOrder(this);
        } else if (localCache.identity === localCache.SERVER) {
            request = serverHelper.bookToOrder(this);
        }
        request.then(res => {
            showToast(t('successOrder'), 'success')
            this.props.history.goBack();
        })
    }
}

const topStepView = {
    paddingTop: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
}

function proOrNextBtn(width, leftMargin) {
    return Object.assign({
        width: width,
        height: '44px',
        borderRadius: '8px',
    }, marginTop(3))
}


const bottomView = {
    background: '#fff',
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
}

const agreementView = {
    '--icon-size': '16px',
    '--font-size': '13px',
    '--gap': '6px',
    marginLeft:'8px'
}
