import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {hideLoading, showLoading} from "../../../../common/utils";
import {customerJoinId} from "../../../../config/env";

export const customerHelper = {
    getDataList(that,pageIndex){
        getDataFromServer('sysOrderCargo/getClientList', {
            token:localCache.token,
            joinId:customerJoinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            that.setState({
                dataList: res.value
            })
        })
    },
}
