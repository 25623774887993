import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {hideLoading, showLoading} from "../../../../common/utils";

export const customerHelper = {
    getDataList(that, pageIndex) {
        let url;
        if (that.state.tabIndex === 'direct') {
            url = 'order/myPackagedOrderList';
        } else if (that.state.tabIndex === 'collect') {
            url = 'collectionOrder/collectionOrderList';
        } else {
            return;
        }
        return getDataFromServer(
            url,
            {
                token: localCache.token,
                pageIndex: pageIndex,
                pageSize: 10,
                status:'',
                joinId:localCache.joinId,
                orderType:0,
                isAll:1
            },
            () => {
                showLoading()
            },
            () => {
                hideLoading()
            }
        )
    }
}
