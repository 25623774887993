import React from 'react';
import {marginLeft, marginTop, pageColorView} from "../../common/script";
import {localCache} from "../../api/localCache";
import {ActionSheet, Image, Button} from "antd-mobile";
import {Translation} from "react-i18next";
import i18n from "./../../config/i18n";
import {DownFill} from 'antd-mobile-icons'
import {getInjectedObject} from "../../common/utils";
import {languageList} from "../../api/dataCommon";


/**
 * 选择用户登录身份页面
 */
export default class SelectIdentityPage extends React.Component {

    injectObject = getInjectedObject();

    constructor() {
        super()
        this.state = {
            languageSetting: false
        };
    }

    //跳转到登录页面
    navigationToLogin(type) {
        localCache.identity = type;
        this.props.history.push('/fastLogin')
    }

    render() {
        return (
            <Translation>
                {(t) =>
                    <div style={pageColorView('#FFFFFF')}>
                        {
                            !this.injectObject ? <div className={'flex-row justify-end'}>
                                <Button color='primary' fill='none' size={'small'} style={marginTop(1)}
                                        onClick={(e) => {
                                            this.props.history.push("/helpPage")
                                        }}>{t('help')}</Button>
                                <Button className={'flex-row'} style={selectLanguageBtn} color='primary' size={'small'}
                                        onClick={() => {
                                            this.setState({
                                                languageSetting: true
                                            })
                                        }}>
                                    <span>{t('language')}</span>
                                    <DownFill className={'font-size10'} style={marginLeft(1)}/>
                                </Button>
                            </div> : ''
                        }

                        <div className={'color263238 font-bold'}
                             style={topTipsView(!this.injectObject)}>{this.injectObject ? '' : t('selectIdentity')}</div>

                        {
                            getIdentityList(t).map((item, index) => {
                                return <div style={blockView} key={index} onClick={(e) => {
                                    this.navigationToLogin(item.value)
                                }}>
                                    <div className={'flex-row justify-between'} style={{alignItems: 'flex-start'}}>
                                        <Image width={48} height={48} src={item.img}/>
                                        <div className={'font-size12'} style={{color: '#ccc'}}>
                                            <div>{item.descEn}</div>
                                            <div style={descEnBottomLineView}/>
                                        </div>
                                    </div>
                                    <div className={'font-size18 color263238 font-bold'}
                                         style={marginTop(2)}>{item.title}</div>
                                    <div className={'font-size12 color999'}>{item.desc}</div>
                                </div>
                            })
                        }

                        <ActionSheet
                            extra={t('selectLanguage')}
                            visible={this.state.languageSetting}
                            actions={languageList}
                            cancelText={t('cancel')}
                            closeOnAction={true}
                            onClose={() => {
                                this.setState({languageSetting: false})
                            }}
                            onAction={action => {
                                i18n.changeLanguage(action.key).then(r => {
                                })
                            }}
                        />
                    </div>}
            </Translation>
        );
    }
}

function getIdentityList(t) {
    return [
        {
            img: '/images/ic_courier.webp',
            desc: t('courierWork'),
            descEn: 'Deliver',
            title: t('isCourier'),
            value: localCache.SERVER
        },
        {
            img: '/images/ic_customer.webp',
            desc: t('customerWork'),
            descEn: 'User',
            title: t('isCustomer'),
            value: localCache.CUSTOMER
        },
    ]
}


const descEnBottomLineView = {borderTop: 'solid 1px #ccc', width: '16px', float: 'right'}

const selectLanguageBtn = {
    background: 'rgba(0,151,167,0.10)',
    marginTop: '8px',
    marginRight: '16px',
    border: 'none',
    color: '#0097A7',
}

function topTipsView(bottomStatus) {
    return {
        marginTop: '15%',
        textAlign: 'center',
        marginBottom: bottomStatus ? '56px' : '0',
        fontSize: '24px'
    }
}

const blockView = {
    background: '#fff',
    width: '80%',
    marginLeft: '10%',
    padding: '20px 26px ',
    borderRadius: '8px',
    boxShadow: ' 0px 2px 8px 0px rgba(38,50,56,0.11)',
    marginBottom: '40px'
}
