import React from 'react';
import {Steps, Button, Grid, Checkbox, Picker, Input, List, Popover, Image, Radio} from 'antd-mobile';
import {
  itemGoodsView,
  itemView,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  pageColorView, protocolView,
  scrollNowView
} from "../../common/script";
import {RightOutlined} from '@ant-design/icons';
import SelectCustomer from './../../dialog/mobile/SelectCustomer'
import SelectAddress from './../../dialog/mobile/SelectAddress'
import EditAddressDialog from './../../dialog/mobile/EditAddressDialog'
import AddCargoDialog from '../../dialog/mobile/AddCargoDialog'
import {getDataFromServer} from "../../api/request";
import {localCache} from "../../api/localCache";
import {dealCargoInfo, handleBlur, hideLoading, judgeNumberInput, showLoading, showToast} from "../../common/utils";
import {RightOutline, DeleteOutline, ContentOutline, QuestionCircleFill} from 'antd-mobile-icons'
import SignDialog from "../../dialog/mobile/SignDialog";
import SelectCountry from "../../dialog/mobile/SelectCountry";
import {customerHelper} from "./js/placeUnpackOrder/Customer";
import {serverHelper} from "./js/placeUnpackOrder/Server";
import SelectGoods from "../../dialog/mobile/SelectGoods";
import {Translation} from "react-i18next";
import {cargoInfo} from "../../api/dataCommon";
import i18n from "./../../config/i18n"

const {Step} = Steps;
const steps = [
  {
    title: 'orderInfo',
    content: 'First-content',
  },
  {
    title: 'addItem',
    content: 'Second-content',
  },
  {
    title: 'recipientConfirm',
    content: 'Last-content',
  },
];

const cargoTypeList = [{key: 'generalGrocery', value: 0}, {key: 'specialGoods', value: 1},
  {key: 'liquidPowder', value: 2}, {key: 'cosmeticsBrands', value: 3},
  {key: 'food', value: 4}];//货物类型


export default class PlaceUnpackOrderPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      customerStatus: false,//客户选择显示状态
      addressStatus: false,//地址选择显示状态
      addressEditStatus: false,//地址编辑显示状态
      addressIndex: -1,//修改地址的下标
      cargoStatus: false,//商品信息弹窗
      addressType: '',//地址选择的类型

      countryList: [],//国家列表
      productList: [],//产品列表
      outStoreList: [],//出库仓库列表
      classList: [],//群组列表
      consigneeInfos: [],//收件人列表

      orderCargoList: [],//商品信息列表
      cargoType: 1,//货物类型
      customerName: "",//选择客户名称
      mobileCode: "",//选择客户手机国码
      mobile: "",//选择客户手机号
      customerId: "",//选择客户id
      customerNum: "",//选择客户Num
      className: "",//选择群组名称
      classId: "",//选择群组id
      productName: "",//产品名称
      productId: "",//产品id
      storeName: "",//仓库名称
      storeId: "",//仓库id
      estimateFreight: 0,//预估运费
      imageUrlList: [],//图片列表

      senderCountryId: 'd59fb7e4398243c79ee7dac6e063d8df',//发件国id
      senderCountryName: '南非',//发件国中文
      senderCountryEnName: 'South Africa',//发件国英文
      consignee: {
        name: '',
        companyName: '',
        mobileZoneCode: '',
        address: '',
        phone: '',
        zipCode: '',
        countryId: '',
        countryName: '',
        countryEnName: '',
        provinceName: '',
        cityName: '',
        taxNumber: '',
        productId: '',//产品id
        productName: '',//产品Name
        estimateFreight: 0,//费用
        productList: [],//产品列表
        cargoList: [],
        checked: true,//是否是打包订单
      },
      deliveryAddress: {},//取件地址
      agreementStatus: false,//协议同意状态
      signDialogStatus: false,//签字弹窗状态
      signUrl: '',//签字图片路径
      selectCountryStatus: false,//
      windowHeight: window.innerHeight,
      showSelectGoodsInfo: {visible: false, index: -1},//显示选择商品弹窗
      needDelivery: 0,//是否需要上门取件 上门取件不需要 选择仓库
    }
  }

  componentDidMount() {
    this.getCountryList();
    if (localCache.identity === localCache.CUSTOMER) {
      //客户身份下单不需要获取仓库信息
      this.getDiscountListByMobile();
    } else if (localCache.identity === localCache.SERVER) {
      this.getStoreList();
    }
    //监听页面尺寸变化
  }

  // 获取国码
  getCountryList() {
    getDataFromServer('system/getAllMobileCode', {}, () => {
      showLoading()
    }, () => {
      hideLoading()
    }).then(res => {
      this.setState({
        countryList: res.value
      })
    })
  }

  /**
   * 获取出货仓列表
   */
  getStoreList() {
    serverHelper.getStoreList(this).then(res => {
      let outStoreList = [];
      for (let item of res.value) {
        outStoreList = outStoreList.concat({
          label: item.storageName,//选择群组名称
          value: `${item.storageName}&&${item.id}`,//选择群组id
        })
      }
      this.setState({
        outStoreList: outStoreList
      })
    })
  }

  inputChange(res, type, index) {
    let key;
    let value = res.target ? res.target.value : res;
    if (type === 'cargoWeight') {
      value = judgeNumberInput(value, 0, '');
    }
    if (index !== undefined) {
      key = 'orderCargoList';
      let orderCargoList = this.state.orderCargoList;
      orderCargoList[index][type] = value;
      value = orderCargoList;
    } else {
      key = type;
    }

    this.setState({
      [key]: value
    });
    handleBlur(this.state.windowHeight)
  }

  /**
   * 下一步 跳转机制
   * @param e 事件
   * @param step 步数 可正可负
   */
  nextStepClick(e, step) {
    step = this.state.current + step;
    this.setState({
      current: step
    })
  }


  render() {
    return (
      <Translation>
        {(t) => <div id='scrollDiv' style={pageColorView("#F7F7F7")}>
          <Steps current={this.state.current} size={'small'} style={topStepView}>
            {steps.map(item => (
              <Step key={item.title} title={t(item.title)}/>
            ))}
          </Steps>
          <div className={'scroll-touch'} style={scrollNowView}>
            {this.state.current === 0 ? this.firstStepView(t) : ''}
            {this.state.current === 1 ? this.secondStepView(t) : ''}
            {this.state.current === 2 ? this.thirdStepView(t) : ''}
          </div>
          <div className={'flex-row justify-center'} style={marginBottom(2)}>
            {
              this.state.current !== 0 ? <Button
                style={proOrNextBtn(this.state.current === 3 ? '80%' : '40%')}
                color={'primary'}
                fill={'outline'}
                onClick={e => this.nextStepClick(e, -1)}>{t('previous')}</Button> : <div/>
            }
            {
              this.state.current !== 2 ? <Button
                style={Object.assign(proOrNextBtn(this.state.current === 0 ? '80%' : '40%'), marginLeft(this.state.current !== 0 ? 1 : 0))}
                color={'primary'}
                disabled={this.getNextBtnStatus()}
                onClick={e => this.nextStepClick(e, 1)}>{t('nextStep')}</Button> : <div/>
            }
          </div>
          {
            this.state.current === 2 ? <div>
              <Checkbox style={agreementView} size={'small'}
                        checked={this.state.agreementStatus}
                        onChange={(e) => this.setState({agreementStatus: e})}>
                {
                  protocolView(this, (updateInfo) => {
                    updateInfo.agreementStatus = true;
                  })
                }
              </Checkbox>
              <div style={bottomView}>
                <div className={'font-size18'}>
                  <span className={'color263238'}>{t('estimate')}</span>
                  <span className={'color263238 font-size13'}>({localCache.joinCurrency}):</span>
                  <span className={'colorD32F2F'}>{this.state.estimateFreight || 0}</span>
                </div>
                <div className={'flex-row'}>
                  {
                    localCache.identity === localCache.SERVER ?
                      <Button style={marginRight(1)} color={'primary'} fill='outline'
                              onClick={(e) => this.setState({signDialogStatus: true})}>{this.state.signUrl ? t('signed') : t('notSigned')}</Button> : ''
                  }
                  <Button color={'primary'} disabled={this.getNextBtnStatus()}
                          onClick={(e) => this.bookToOrder(t)}>{t('placeOrder')}</Button>
                </div>
                <SignDialog visible={this.state.signDialogStatus} onClose={(signUrl) => {
                  let updateInfo = {signDialogStatus: false};
                  if (signUrl) {
                    updateInfo.signUrl = signUrl;
                  }
                  this.setState(updateInfo)
                }}/>
              </div>
            </div> : ''
          }
          <SelectAddress visible={this.state.addressStatus}
                         countryId={this.state.addressType === 'delivery' ? this.state.deliveryAddress.countryId : ''}
                         addressType={this.state.addressType}
                         onClose={(address, isGetProduct) => {
                           this.setSelectAddress(address)
                         }}/>
          <EditAddressDialog visible={this.state.addressEditStatus} addressType={this.state.addressType}
                             addressInfo={this.state.addressIndex === -1 ? this.state.deliveryAddress : this.state.consigneeInfos[this.state.addressIndex]}
                             onClose={(address) => {
                               this.setSelectAddress(address)
                             }}/>
        </div>}
      </Translation>
    )
  }

  /**
   * 第一步页面 选择客户
   */
  firstStepView(t) {
    return <div>
      {
        itemView((
          <div className={'flex-row justify-between'}>
            <div>{t('ownCountry')}</div>
            <div className={'flex-row'} onClick={() => {
              this.setState({selectCountryStatus: true})
            }}>
              <div style={marginRight(1)}>
                {
                  i18n.language === 'en' ? this.state.senderCountryEnName || t('choose') : ''
                }
                {
                  i18n.language === 'zh' ? this.state.senderCountryName || t('choose') : ''
                }
              </div>
              <RightOutlined/></div>
          </div>
        ))
      }
      {
        localCache.identity !== localCache.CUSTOMER ? itemView((
          <div className={'flex-row justify-between'}>
            <div>{t('ownCustomer')}</div>
            <div className={'flex-row'} onClick={() => this.setState({customerStatus: true})}>
              <div style={marginRight(1)}>{this.state.customerName || t('choose')}</div>
              <RightOutlined/>
            </div>
          </div>
        )) : ''
      }
      {
        itemView((
          <div className={'flex-row justify-between'}>
            <div>{t('affGroup')}</div>
            <div className={'flex-row'} onClick={async () => {
              if (!this.state.customerId && localCache.identity === localCache.SERVER) {
                showToast(t('selectCustomer'), 'warning')
                return;
              }
              let value = await Picker.prompt({columns: this.state.classList})
              if (value != null && value.length > 0) {
                value = value[0].split("&&");
                this.setState({
                  className: value[0],//选择群组名称
                  classId: value[1],//选择群组id
                })
              }
            }}>
              <div style={marginRight(1)}>{this.state.className || t('choose')}</div>
              <RightOutlined/>
            </div>
          </div>
        ))
      }
      {
        itemView((
          <div className={'flex-row justify-between'}>
            <div>{t('shippingMethod')}</div>
            <Radio.Group className={'flex-row'} value={this.state.needDelivery} onChange={val => {
              this.setState({
                needDelivery: val
              })
            }}>
              <Radio style={smallRadio} value={0}>
                {t('toWarehouse')}
              </Radio>
              <Radio style={smallRadio} value={1}>
                {t('pickUp')}
              </Radio>
            </Radio.Group>
          </div>
        ))
      }
      {
        this.state.needDelivery === 0 ? itemView((
          <div className={'flex-row justify-between'}>
            <div>{localCache.identity !== localCache.CUSTOMER ? t('receivingWarehouse') : t('estReceivingWarehouse')}</div>
            <div className={'flex-row'} onClick={async () => {
              if (this.state.outStoreList.length === 0) {
                showToast(t('noWarehouseChoose'), 'warning');
                return
              }
              let value = await Picker.prompt({columns: [this.state.outStoreList]});
              if (value != null && value.length > 0) {
                value = value[0].split("&&");
                this.setState({
                  storeName: value[0],//选择群组名称
                  storeId: value[1],//选择群组id
                })
              }
            }}>
              <div style={marginRight(1)}>{this.state.storeName || t('choose')}</div>
              <RightOutlined/>
            </div>
          </div>
        )) : itemView(<div className={'flex-row'}>
          <div className={'flex-col'}>
            <Image width={20} height={20} alt={t('pickup')}
                   src={'images/ic_delivery.webp'}/>
          </div>
          <div className={'flex-col'} style={Object.assign(marginLeft(1), {flex: 1})}>
            {this.addressView('delivery', this.state.deliveryAddress, -1, t)}
          </div>
        </div>)
      }
      <SelectCustomer visible={this.state.customerStatus} onClose={(userInfo) => {
        let updateState = {customerStatus: false}
        if (userInfo && userInfo.length > 0) {
          userInfo = userInfo[0].split("&&");
          updateState.customerName = userInfo[0];//选择客户名称
          updateState.customerId = userInfo[1];//选择客户id
          updateState.customerNum = userInfo[2];//选择客户id
          updateState.mobileCode = userInfo[3];//选择客户id
          updateState.mobile = userInfo[4];//选择客户id
          localCache.customerId = userInfo[1];
        }
        this.setState(updateState, (e) => {
          if (userInfo && userInfo.length > 0) {
            this.getDiscountListByMobile();
          }
        })
      }}/>
      <SelectCountry visible={this.state.selectCountryStatus} onClose={(countryInfo) => {
        let updateInfo = {selectCountryStatus: false}
        if (countryInfo && countryInfo.length > 0) {
          countryInfo = countryInfo[0].split("&&");
          updateInfo.senderCountryName = countryInfo[0];
          updateInfo.senderCountryEnName = countryInfo[3];

          updateInfo.senderCountryId = countryInfo[1];

          updateInfo.deliveryAddress = this.state.deliveryAddress;
          updateInfo.deliveryAddress.countryName = countryInfo[0];
          updateInfo.deliveryAddress.countryId = countryInfo[1];
          updateInfo.deliveryAddress.countryEnName = countryInfo[3];
        }
        this.setState(updateInfo);
      }}/>
    </div>
  }

  getDiscountListByMobile() {
    let request;
    if (localCache.identity === localCache.CUSTOMER) {
      request = customerHelper.getDiscountList(this);
    } else if (localCache.identity === localCache.SERVER) {
      request = serverHelper.getDiscountList(this);
    } else {
      return;
    }
    request.then(res => {
      let classList = [];
      let reposeList = [];
      if (localCache.identity === localCache.CUSTOMER) {
        reposeList = res.value.discountGroupList;
      } else if (localCache.identity === localCache.SERVER) {
        reposeList = res.value;
      }
      for (let item of reposeList) {
        classList = [...classList, {
          label: item.groupName,
          value: `${item.groupName}&&${item.memberCode}`
        }]
      }
      this.setState({
        classList: [classList]
      })
    })
  }

  /**
   * 第二步页面 物品信息
   */
  secondStepView(t) {
    for (let item of cargoTypeList) {
      item.label = t(item.key);
    }
    return <div>
      {
        this.state.orderCargoList.map((item, index) => {
          return <div className={'bg-white'}>
            {
              itemGoodsView(`${t('goodsName')}${index + 1}`, <div className={'flex-row'}>
                <Input className={'mobile-input-text-end'} placeholder={t('input')} clearable
                       value={item.cargoNameEn}
                       onChange={(e) => this.inputChange(e, 'cargoNameEn', index)}
                />
                <ContentOutline style={marginLeft(1)} onClick={() => {
                  this.setState({
                    showSelectGoodsInfo: {
                      visible: true,
                      index: index
                    }
                  })
                }}/>
              </div>)
            }
            {
              itemGoodsView(t('singleWeight'), <div className={'flex-row'}>
                <Input className={'mobile-input-text-end'} type={'number'}
                       placeholder={t('input')}
                       value={item.cargoWeight}
                       clearable
                       onChange={(e) => this.inputChange(e, 'cargoWeight', index)}
                />
                <div style={marginLeft(1)}>(Kg)</div>
              </div>)
            }
            {
              itemGoodsView(t('cargoType'), <div className={'flex-row justify-end'}>
                <div onClick={async (e) => {
                  let value = await Picker.prompt({columns: [cargoTypeList]})
                  if (value != null) {
                    let orderCargoList = this.state.orderCargoList;
                    orderCargoList[index].cargoType = value[0];
                    this.setState({
                      orderCargoList: orderCargoList,
                    })
                  }
                }}>{cargoTypeList[item.cargoType].label}</div>
                <RightOutline/>
              </div>)
            }
            <div className={'flex-row justify-center'} style={delView}>
              <DeleteOutline style={marginRight(1)} onClick={() => {
                let orderCargoList = this.state.orderCargoList;
                orderCargoList.splice(index, 1);
                this.setState({
                  orderCargoList: orderCargoList
                })
              }}/>
            </div>
          </div>
        })
      }
      <Button color={'primary'} style={addBtnView} onClick={() => {
        let orderCargoList = this.state.orderCargoList;
        let cargoObject = JSON.parse(JSON.stringify(cargoInfo));
        cargoObject.sort = orderCargoList.length;
        orderCargoList = orderCargoList.concat(cargoObject);
        this.setState({
          orderCargoList: orderCargoList
        })
      }}>{t('addItem')}</Button>

      <SelectGoods visible={this.state.showSelectGoodsInfo.visible} onClose={(goodsInfo) => {
        let updateInfo = {showSelectGoodsInfo: {visible: false, index: -1}};
        if (goodsInfo) {
          let orderCargoList = this.state.orderCargoList;
          let cargoInfo = dealCargoInfo(goodsInfo);
          cargoInfo.cargoQuantity = 0;
          orderCargoList[this.state.showSelectGoodsInfo.index] = cargoInfo;
          updateInfo.orderCargoList = orderCargoList;
        }
        this.setState(updateInfo);
      }}>
      </SelectGoods>
    </div>
  }

  /**
   * 第三步页面 收发件人
   */
  thirdStepView(t) {
    return <div>
      {
        this.state.consigneeInfos.map((item, index) => {
          return <List style={marginTop(1)}>
            <div className={'flex-row justify-between'} style={marginTop(1)}>
              <div className={'font-size13'} style={marginLeft(1)}>{t('selectShipmentType')}
                <Popover
                  content={
                    <div className={'font-size12'}>
                      <div>{t('arriveWareWaitSendNotify')}</div>
                      <div>{t('arriveWareSendNotify')}</div>
                    </div>
                  }
                  trigger='click'
                  placement='bottom'
                >
                  <QuestionCircleFill style={marginLeft(1)}/>
                </Popover></div>
              <div className={'flex-row'}>
                <div
                  className={`check-btn ${!item.checked ? 'check-btn-normal' : 'check-btn-selected'}`}
                  style={marginRight(1)} onClick={() => {
                  this.updateNeedPackCargoStatus(index, false)
                }}>{t('arriveWareWaitSend')}
                </div>
                {
                  this.state.needDelivery === 0 ? <div
                    className={`check-btn ${item.checked ? 'check-btn-normal' : 'check-btn-selected'}`}
                    style={marginRight(1)} onClick={() => {
                    this.updateNeedPackCargoStatus(index, true)
                  }}>{t('arriveWareSend')}
                  </div> : ''
                }
              </div>
            </div>

            <List.Item style={marginTop(1)} children={<div className={'flex-row'}>
              <div className={'flex-col'}>
                <Image width={20} height={20} alt={t('consignee')}
                       src={'images/ic_consigee.webp'}/>
              </div>
              <div className={'flex-col'} style={Object.assign(marginLeft(1), {flex: 1})}>
                {this.addressView('consignee', item, index, t)}
              </div>
            </div>}/>
            {
              item.cargoList.length !== 0 ? item.cargoList.map((item, cargoIndex) => {
                  return <div className={'flex-row'}
                              style={Object.assign(marginLeft(1), marginRight(1), marginTop(1), marginBottom(1))}>
                    <div className={'flex-row justify-between'} style={addCargoStyle}>
                      <div>{item.cargoNameEn} - {item.quantity}</div>
                      <RightOutline/>
                    </div>
                    <DeleteOutline style={marginLeft(2)} onClick={() => {
                      let consigneeInfos = this.state.consigneeInfos;
                      consigneeInfos[index].cargoList.splice(cargoIndex, 1);
                      this.setState({
                        consigneeInfos: consigneeInfos
                      })
                    }}/>
                  </div>
                }) :
                <div className={'flex-row'}
                     style={Object.assign(marginLeft(1), marginRight(1), marginTop(1), marginBottom(1))}>
                  <div className={'flex-row justify-between'} style={addCargoStyle} onClick={() => {
                    if (!item.countryId && item.checked) {
                      showToast(t('setAddress'), 'warning');
                      return;
                    }
                    this.setState({cargoStatus: true, addressIndex: index})
                  }}>
                    <div>{t('clickAddItem')}</div>
                    <RightOutline/>
                  </div>
                </div>
            }
            {
              item.checked ?
                <List.Item prefix={<div className={'font-size13'} style={marginTop(2)}>物流产品</div>} arrow
                           extra={<div onClick={async () => {
                             if (item.productList.length === 0) {
                               showToast(t('noServiceChoose'));
                               return;
                             }
                             let value = await Picker.prompt({columns: [item.productList]})
                             if (value != null && value.length > 0) {
                               let estimateFreight = 0;
                               let consigneeInfos = this.state.consigneeInfos;
                               value = value[0].split("&&");
                               for (let i = 0; i < consigneeInfos.length; i++) {
                                 if (i === index) {
                                   consigneeInfos[index].productName = value[0];
                                   consigneeInfos[index].productId = value[1];
                                   consigneeInfos[index].estimateFreight = value[2];
                                 }
                                 estimateFreight += Number(consigneeInfos[i].estimateFreight);
                               }
                               this.setState({
                                 consigneeInfos: consigneeInfos,
                                 estimateFreight: estimateFreight
                               })
                             }
                           }}>{item.productName || t('choose')}</div>}/> : ''
            }
            <div className={'flex-row justify-center'} style={delView} onClick={() => {
              let consigneeInfos = this.state.consigneeInfos;
              consigneeInfos.splice(index, 1);
              this.setState({
                consigneeInfos: consigneeInfos
              })
            }}>
              <DeleteOutline/>
            </div>
          </List>
        })
      }
      <Button color={'primary'} style={addBtnView} onClick={(e) => {
        let consigneeInfos = this.state.consigneeInfos;
        let consignee = JSON.parse(JSON.stringify(this.state.consignee));
        if (this.state.needDelivery === 1) {
          consignee.checked = false
        }
        consigneeInfos = consigneeInfos.concat(consignee)
        this.setState({
          consigneeInfos: consigneeInfos
        })
      }}>{t('addConsignee')}</Button>
      <AddCargoDialog visible={this.state.cargoStatus} cargoList={this.state.orderCargoList} onClose={(cargo) => {
        let updateInfo = {cargoStatus: false}
        if (cargo) {
          let consigneeInfos = this.state.consigneeInfos;
          consigneeInfos[this.state.addressIndex].cargoList = cargo.cargoList;
          updateInfo.consigneeInfos = consigneeInfos;
          if (consigneeInfos[this.state.addressIndex].checked) {
            this.getProductList(consigneeInfos[this.state.addressIndex].countryId, this.state.addressIndex, cargo.weight)
          }
        }
        this.setState(updateInfo)
      }}/>
    </div>
  }

  /**
   * 修改收件人的包裹是否需要打包的状态
   * @param index
   * @param status
   */
  updateNeedPackCargoStatus(index, status) {
    let consigneeInfos = this.state.consigneeInfos;
    consigneeInfos[index].checked = status;
    this.setState({
      consigneeInfos: consigneeInfos
    })
  }

  /**
   * 设置选择的地址
   * @param address
   */
  setSelectAddress(address) {
    let updateInfo = {addressStatus: false, addressEditStatus: false,}
    if (address) {
      let keys = ['name', 'companyName', 'mobileZoneCode', 'address', 'phone', 'zipCode', 'countryId', 'countryName', 'countryEnName', 'cityName', 'provinceName']
      if (this.state.addressType === 'delivery') {
        updateInfo.deliveryAddress = {};
        for (let key of keys) {
          updateInfo.deliveryAddress[key] = address[key];
        }
      } else {
        let consigneeInfos = this.state.consigneeInfos;
        let consigneeInfo = consigneeInfos[this.state.addressIndex];
        for (let key of keys) {
          consigneeInfo[key] = address[key];
        }
        consigneeInfos[this.state.addressIndex] = consigneeInfo;
        updateInfo.consigneeInfos = consigneeInfos;
      }
    }
    this.setState(updateInfo)
  }

  /**
   * 地址itemView
   * @param addressType 地址类型 send 寄件人 consignee 收件人
   */
  addressView(addressType, item, index, t) {
    let address = item;
    let title;
    switch (addressType) {
      case 'send':
        title = t('senderInfo');
        break;
      case 'consignee':
        title = t('consigneeInfo');
        break;
      case 'delivery':
        title = t('pickupInfo');
        break;
      default:
        break;
    }
    return <Grid className={'width100p'} columns={24}>
      <Grid.Item span={18}>
        <div onClick={(e) => {
          this.showSelectAddressDialog('addressEditStatus', addressType, index, t)
        }}>
          <div className={'font-size15 color263238'}>
            {
              address.name || address.phone ?
                <div>
                  <span>{address.name}</span>
                  <span style={marginLeft(1)}>{address.phone}</span>
                </div> : <div>{title}</div>
            }
          </div>
        </div>
      </Grid.Item>
      <Grid.Item className={'flex-row flex-center'} span={1}>
        <div style={{background: '#DFDFDF', width: '1px', height: '100%'}}/>
      </Grid.Item>
      <Grid.Item span={5}>
        <div className={'flex-row height100p'} onClick={(e) => {
          this.showSelectAddressDialog('addressStatus', addressType, index, t)
        }}>
          <div className={'text-center font-size13'}>{t('chooseAddress')}</div>
        </div>
      </Grid.Item>
    </Grid>
  }

  showSelectAddressDialog(addressKey, addressType, index, t) {
    if (!this.state.customerId && localCache.identity === localCache.SERVER) {
      showToast(t('selectCustomer'), 'warning');
      return;
    }
    this.setState({
      [addressKey]: true,
      addressType: addressType,
      addressIndex: index,
    })
  }


  /**
   * 获取产品列表
   */
  getProductList(countryId, index, weight) {
    let request;
    if (localCache.identity === localCache.CUSTOMER) {
      request = customerHelper.getProductList(this, weight, countryId);
    } else if (localCache.identity === localCache.SERVER) {
      request = serverHelper.getProductList(this, weight, countryId);
    } else {
      return;
    }
    request.then(res => {
      let product = [];
      for (let item of res.value) {
        product = product.concat({
          label: item.enterpriseName,//选择群组名称
          value: `${item.enterpriseName}&&${item.id}&&${item.price || '0'}`,//选择群组id
        })
      }
      let consigneeInfos = this.state.consigneeInfos;
      consigneeInfos[index].productList = product;
      consigneeInfos[index].productId = '';
      consigneeInfos[index].productName = '';
      this.setState({
        consigneeInfos: consigneeInfos
      })
    })
  }


  /**
   * 下一步按钮的选择状态
   */
  getNextBtnStatus() {
    if (this.state.current === 0) {
      if (localCache.identity === localCache.CUSTOMER) {
        return !this.state.senderCountryName || !this.state.deliveryAddress.name || !this.state.classId
      } else if (localCache.identity === localCache.SERVER) {
        return !this.state.senderCountryName || !this.state.customerName || !this.state.classId
      }
    } else if (this.state.current === 1) {
      if (this.state.orderCargoList.length === 0) {
        return false;
      }

      for (let item of this.state.orderCargoList) {
        if (!item.cargoNameEn) {
          return true;
        }
        if (!item.cargoWeight) {
          return true;
        }

      }
    } else if (this.state.current === 2) {
      for (let item of this.state.consigneeInfos) {
        if (!item.name) {
          return true;
        }

        if (!item.phone) {
          return true;
        }

        if (item.checked) {
          if (!item.productId) {
            return true;
          }

          if (item.cargoList.length === 0) {
            return true;
          }
        }
      }
    }
    return false;
  }


  /**
   * 点击
   * 下订单
   */
  bookToOrder(t) {
    if (!this.state.agreementStatus) {
      showToast(t('agreeFirst'), 'warning')
      return
    }

    let request;
    if (localCache.identity === localCache.CUSTOMER) {
      request = customerHelper.bookToOrder(this);
    } else if (localCache.identity === localCache.SERVER) {
      request = serverHelper.bookToOrder(this);
    } else {
      return;
    }
    request.then(res => {
      showToast(t('successOrder'), 'success')
      this.props.history.goBack();
    })
  }
}

const topStepView = {
  paddingTop: '16px',
  paddingLeft: '16px',
  paddingRight: '16px',
}

function proOrNextBtn(width, leftMargin) {
  return Object.assign({
    width: width,
    height: '44px',
    borderRadius: '8px',
  }, marginTop(3))
}


const bottomView = {
  background: '#fff',
  width: '100%',
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}

const agreementView = {
  '--icon-size': '16px',
  '--font-size': '13px',
  '--gap': '6px',
  marginLeft: '8px',
  marginBottom: '8px'
}

const addBtnView = {
  background: 'rgba(0,151,167,0.10)',
  '--border-color': 'rgba(0,151,167,0.10)',
  color: 'var(--color)',
  width: '80%',
  marginLeft: '10%',
  height: '44px',
  marginTop: '8px'
}

const addCargoStyle = {
  width: '100%',
  height: '36px',
  background: ' #f5f5f5',
  borderRadius: '4px',
  paddingLeft: '8px',
  paddingRight: '8px',
  fontSize: '15px',
  color: '#666'
}


const delView = {
  paddingTop: '8px',
  paddingBottom: '8px'
}

const smallRadio = {
  '--icon-size': '18px',
  '--font-size': '14px',
  '--gap': '6px',
}
