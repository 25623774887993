import React from 'react';
import {Modal, Button, Image, Row, Col} from 'antd';
import {marginBottom, marginTop} from "../../common/script";

export default class SelectModelDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            inModelStatus:false,
            outModelStatus:false,
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
                inModelStatus:this.props.inModelStatus,
                outModelStatus:this.props.outModelStatus,
            })
        }
    }

    onClose = (e) => {
        this.props.onClose(e);
    };

    render() {
        return (
            <div>
                <Modal
                    wrapClassName={'border-radius32-model'}
                    centered
                    visible={this.state.visible}
                    onCancel={this.onClose}
                    width={'60%'}
                    footer={null}
                >
                    <Row>
                        <Col span={9} style={Object.assign(marginBottom(13), marginTop(4))}>
                            <div className={'flex-row justify-end'}>
                                <Image width={307} height={320} src={'images/bg_select_modal.webp'} preview={false}/>
                            </div>
                        </Col>
                        <Col offset={1} span={14}>
                            <div className={'font-size32 color263238 font-bold'} style={marginTop(5)}>Select the
                                business you want to do
                            </div>
                            {
                                this.state.inModelStatus?<Button type={'primary'} style={btnView(6)} onClick={(e)=>{
                                    window.location = `http://crm.onecargo.net/#/goToTail?md7=${JSON.parse(window.localStorage.getItem('msg')).token}`
                                }}>Import Business</Button>:<div/>
                            }
                            <br/>
                            {
                                this.state.outModelStatus?<Button type={'primary'} ghost={true} style={btnView(4)} onClick={(e)=>{
                                    window.location = `http://imp.woodx3.com/#/goToFast?md7=${JSON.parse(window.sessionStorage.getItem('msg')).token}`
                                }}>Export Business</Button>:<div/>
                            }
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

function btnView(marginTopNum) {
    return Object.assign({
        width: '448px',
        height: '64px',
        borderRadius: '8px',
        fontSize: '24px'
    }, marginTop(marginTopNum))
}
