let fastBaseUrl = '';
let tailBaseUrl = '';
let customerJoinId = '';

if (process.env.NODE_ENV === 'development') {
    fastBaseUrl = 'http://fast.mrcargo.com/app-api/'; //线上 kgtest 987321专线产品列表
    // fastBaseUrl = 'http://114.67.124.186:8081/app-api/'; //线上 kgtest 987321专线产品列表
    // baseUrl = "http://114.67.68.72:8082/app-api/" //测试 18721858272  123456 京东云空运运力时效看板
    // fastBaseUrl = "http://192.168.1.117:8084/app-api/" //测试 18721858272  123456 本地
    // fastBaseUrl = "http://114.67.124.186:8081/app-api/";//测试 18721858272  123456 本地
    // fastBaseUrl = 'http://192.168.1.127:8080/app-api/'; //曹
    // baseUrl = 'http://192.168.1.125/'; //孙
    tailBaseUrl = 'http://114.67.124.186:8083/app-api/'; //京东 testdota 123456
    // customerJoinId = 'da887c4dff75452081c761d8a4223985';
    customerJoinId = '1e703496b5034cb68f0483d87856e708';//生产测试
} else {
    // 线上322323
    fastBaseUrl = 'https://fast.mrcargo.com/app-api/';
    // baseUrl = "http://114.67.68.72:8082/app-api/" //线上测试环境
    tailBaseUrl = 'https://crm.onecargo.net/app-api/';
    customerJoinId = '9d9bf7d727bc423b8f583736f9a4a4f4';
}

export {
    fastBaseUrl,
    tailBaseUrl,
    customerJoinId,
}
