import {getDataFromServer} from "../../../../api/request";
import {hideLoading, showLoading} from "../../../../common/utils";
import {localCache} from "../../../../api/localCache";

export const customerHelper = {
    getDiscountList(that){
        return getDataFromServer('discount/member/getDiscountGroupInfo', {
            token: localCache.token,
            joinId:localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    },

    getProductList(that) {
        return getDataFromServer('order/getProductByCountryId', {
            token: localCache.token,
            memberCode: that.state.classId,
            mailType: that.state.mailType,
            weight: that.state.estimateWeight,
            cargoType: that.state.cargoType,
            storeId: '',
            amazonAddressId: '',
            zipCode: '',
            cityId: '',
            countryId: that.state.consignee.countryId || '',
            senderCountryId: that.state.send.countryId || '',
            joinId:localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        });
    },

    /**
     * 获取下单参数
     */
    getBookOrderParams(that) {
        let params = {};
        params.token = localCache.token;
        params.mailType = that.state.mailType;
        params.cargoName = that.state.cargoName;
        params.trackingNumber = '';
        params.memberCode = that.state.classId;
        params.cargoQuantity = that.state.cargoQuantity;

        params.senderName = that.state.send.name;
        params.senderMobileZoneCode = that.state.send.mobileZoneCode;
        params.senderPhone = that.state.send.phone;
        params.senderCountry = that.state.send.countryName;
        params.senderProvinceName = that.state.send.provinceName;
        params.senderCityName = that.state.send.cityName;
        params.senderAddress = that.state.send.address;
        params.senderZipCode = that.state.send.zipCode;
        params.senderCompanyName = that.state.send.companyName;
        params.senderCountryId = that.state.send.countryId;
        params.consigneeName = that.state.consignee.name;
        params.consigneeMobileZoneCode = that.state.consignee.mobileZoneCode;
        params.consigneePhone = that.state.consignee.phone;
        params.consigneeCountry = that.state.consignee.countryName;
        params.consigneeProvinceName = that.state.consignee.provinceName;
        params.consigneeCityName = that.state.consignee.cityName;
        params.consigneeAddress = that.state.consignee.address;
        params.consigneeZipCode = that.state.consignee.zipCode;
        params.consigneeCompanyName = that.state.consignee.companyName;
        params.consigneeCountryId = that.state.consignee.countryId;

        params.servicePlatform = that.state.productId;
        params.isAutoCharge = 1;
        params.orderType = 0;
        params.storeId = '';
        params.needDelivery = 0;
        params.memberId = that.state.customerId;
        params.transferStoreIds = '';
        params.collectStoreId = that.state.storeId;
        params.cargoType = that.state.cargoType;
        params.estimateWeight = that.state.estimateWeight;
        params.taxMethod = that.state.taxMethod;
        params.isElectric = 0;
        params.estimateFreight = that.state.estimateFreight;
        params.joinId = localCache.joinId;
        let orderCargoList = [];
        for (let cargoItem of that.state.orderCargoList) {
            if (cargoItem.imgUrl && cargoItem.imgUrl.url) {
                cargoItem.imgUrl = cargoItem.imgUrl.url
            }
            orderCargoList = orderCargoList.concat(cargoItem);
        }
        params.preCargoPackageDetailListStr = JSON.stringify([
            {
                length: '', //长
                width: '', //宽
                height: '', //高
                weight: '', //重量
                quantity: '1', //数量
                sort: '', //顺序
                orderCargoListStr: JSON.stringify(orderCargoList) //cargoListArr
            }
        ]);
        let imageList = [];
        for (let imageItem of that.state.imageUrlList) {
            imageList = imageList.concat(imageItem.url);
        }
        params.imageUrls = imageList.join(",");
        return params;
    },

    bookToOrder(that) {
        return getDataFromServer('order/addOrder', this.getBookOrderParams(that), () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    }
}
