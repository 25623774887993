import React from 'react';
import {Translation} from "react-i18next";
import {pageColorView, scrollNowView} from "../../common/script";
import {videoList} from "../../api/helpInfo";
import {QuestionCircleOutline} from 'antd-mobile-icons';
import {helpVideoView} from "./HelpPage";
import i18n from "./../../config/i18n"
import Empty from "antd/es/empty";


export default class HelpSearchResultPage extends React.Component {


    constructor(props) {
        super(props);
        let dataList = [];
        if (props.location && props.location.search) {
            let narArr = props.location.search.replace("?", "").split("&");
            let searchInfo = narArr[0];
            dataList = this.getDataList(searchInfo);
        }
        this.state = {
            dataList: dataList,
        }
    }

    /**
     * 获取列表数据
     * @param searchInfo 搜索内容
     * @returns {[]}
     */
    getDataList(searchInfo) {
        let dataList = [];
        for (let item of videoList) {
            for (let videoItem of item.data) {
                if (videoItem.title.indexOf(searchInfo) !== -1) {
                    dataList = dataList.concat(videoItem)
                }
            }
        }
        return dataList;
    }

    render() {
        return (
            <Translation>
                {(t) => <div style={pageColorView()}>
                    <div style={scrollNowView}>
                        {
                            this.state.dataList.map((item, index) => {
                                return helpVideoView(item, index !== this.state.dataList.length - 1)
                            })
                        }


                        {
                            this.state.dataList.length === 0 ? <Empty
                                style={{padding: '64px 0'}}
                                image={
                                    <QuestionCircleOutline
                                        style={{
                                            color: 'var(--adm-color-light)',
                                            fontSize: 48,
                                        }}
                                    />
                                }
                                description={i18n.t('empty')}
                            /> : ''
                        }
                    </div>

                </div>}
            </Translation>
        );
    }


}

