import React from 'react';
import {Popup, Button, Empty} from 'antd-mobile';
import {RightOutline, DeleteOutline} from 'antd-mobile-icons';
import CargoEditDialog from './CargoEditDialog';
import {getDataFromServer} from "../../api/request";
import {localCache} from "../../api/localCache";
import {marginLeft, marginRight} from "../../common/script";
import i18n from "./../../config/i18n";

export default class CargoListDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            cargoEditStatus: false,//修改包裹信息弹窗
            cargoInfoList: [],//商品列表
            updateCargoInfo: {},//需要更新的cargoInfo
            updateCargoIndex: {},//需要更新的cargoInfo下标
            showSelectGoods: false,//显示物品列表弹窗
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
            })
        }
    }

    hideVisibleClick() {
        this.props.onClose(this.state.cargoInfoList);
    }

    searchCustomerByMobile(customer) {
        getDataFromServer('sysCustomerUser/getMemberListByJoinId', {
            token: localCache.token,
            mobile: customer,
            joinId: localCache.joinId
        }, () => {
            this.setState({
                loading: true,
            })
        }, () => {
            this.setState({
                loading: false,
            })
        }).then(res => {
            this.setState({
                customerList: res.value
            })
        })
    }

    render() {
        return (
            <div>
                <Popup visible={this.state.visible}
                       onMaskClick={() => {
                           this.hideVisibleClick()
                       }}
                       bodyStyle={dialogBody}
                       stopPropagation={['enter']}
                >
                    <div style={contentView}>
                        {
                            this.state.cargoInfoList.map((item, index) => {
                                return <div className={'flex-row justify-between'} style={itemView} key={index}>
                                    <div className={'flex-row'}>
                                        <DeleteOutline onClick={() => {
                                            let cargoInfoList = this.state.cargoInfoList;
                                            cargoInfoList.splice(index, 1);
                                            this.setState({
                                                cargoInfoList: cargoInfoList
                                            })
                                        }}/>
                                        <div style={marginLeft(1)}>{i18n.t('goods')}{index + 1}</div>
                                    </div>
                                    <div className={'flex-row'} onClick={() => {
                                        this.setState({
                                            updateCargoInfo: item,
                                            updateCargoIndex: index,
                                        }, () => {
                                            this.setState({
                                                cargoEditStatus: true
                                            })
                                        })
                                    }}>
                                        <div
                                            style={marginRight(1)}>{item.cargoNameEn || '-'}，{item.cargoQuantity || '-'} {i18n.t('piece')}，{item.cargoWeight || '-'} Kg
                                        </div>
                                        <RightOutline/>
                                    </div>
                                </div>
                            })
                        }
                        {
                            this.state.cargoInfoList.length === 0 ? <Empty description={i18n.t('noDataAdd')}/> : <div/>
                        }
                    </div>
                    <div className={'flex-row justify-center'}>
                        <Button color={'primary'} fill={'outline'} style={btnView(0)}
                                onClick={() => this.hideVisibleClick()}>{i18n.t('back')}</Button>
                        <Button color={'primary'} style={btnView(1)} onClick={() => {
                            this.setState({
                                updateCargoInfo: {},
                                updateCargoIndex: -1,
                            }, () => {
                                this.setState({
                                    cargoEditStatus: true
                                })
                            })
                        }}>{i18n.t('addGoods')}</Button>
                    </div>

                </Popup>

                <CargoEditDialog visible={this.state.cargoEditStatus}
                                 cargoInfo={this.state.updateCargoInfo}
                                 onMaskClick={() => {
                                     this.setState({
                                         cargoEditStatus: false
                                     })
                                 }}
                                 onClose={(e) => {
                                     let updateInfo = {cargoEditStatus: false};
                                     if (e) {
                                         if (this.state.updateCargoIndex !== -1) {
                                             updateInfo.cargoInfoList = this.state.cargoInfoList;
                                             updateInfo.cargoInfoList[this.state.updateCargoIndex] = e;
                                         } else {
                                             updateInfo.cargoInfoList = this.state.cargoInfoList.concat(e);
                                         }
                                     }
                                     this.setState(updateInfo);
                                 }}/>
            </div>
        )
    }
}


const dialogBody = {
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    height: "60vh",
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '8px',
    display:'flex',
    flexDirection:'column'
}

const contentView = {
    flex:1,
    overflowY: 'scroll',
    marginTop: '10px'
}

const itemView = {
    width: '100%',
    background: '#ffffff',
    boxShadow: '0px 4px 8px 0px rgba(51,51,51,0.2)',
    fontSize: '15px',
    marginBottom: '8px',
    padding: '10px'
}

function btnView(marginLeft) {
    return {
        width: '40%',
        marginBottom: '8px',
        marginTop: '10px',
        marginLeft: `${(marginLeft || 0) * 8}px`
    }
}
