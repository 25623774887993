import React from 'react';
import {Route, Router, Switch, Redirect} from "react-router";
import {createBrowserHistory} from 'history';
import routes from './router/index';
import {localCache} from "./api/localCache";
import './App.css';
import './ant.less';
import {routeWhiteList} from "./api/dataCommon";
import i18n from "./config/i18n";

window.settingUpdate = function (content) {
    if (window.settingCallBack !== undefined) {
        window.settingCallBack.settingUpdate(content)
    }
}

window.setSettingCallBack = function (callback) {
    window.settingCallBack = callback
}



class App extends React.Component {


    constructor(e) {
        super(e);
        this.state = {
            history: createBrowserHistory(),
            update: 0,
        };
        this.judgeRouter(this.state.history.location)
        this.state.history.listen(location => {
            this.judgeRouter(location)
        });
        this.updatePage.bind(this);
    }

    judgeRouter(location) {
        if (!localCache.token || location.pathname === '/') {
            if (/Android|webOS|iPhone|iPod|BlackBerry|XiaoMi/i.test(navigator.userAgent)) {
                if (routeWhiteList.indexOf(location.pathname) === -1) {
                    if (location.pathname !== '/selectIdentityPage') {
                        this.state.history.replace("/selectIdentityPage")
                    }
                }
            } else {
                if (location.pathname !== '/login') {
                    this.state.history.replace("/login")
                }
            }
        }
    }

    componentDidMount() {
        window.setSettingCallBack(this);
        window.addEventListener('resize', this.updatePage)
    }

    componentWillUnmount() {
        window.setSettingCallBack(null);
        window.removeEventListener('resize', this.updatePage)
    }

    updatePage = () => {
        this.setState({
            update: this.state.update + 1
        })
    }

    render() {
        return (
            <div id={this.state.update}>
                <Router history={this.state.history}>
                    <Switch>
                        {
                            routes.map((item, index) => {
                                return (
                                    <Route key={index} path={item.path}
                                           render={(props) => {
                                               document.title = i18n.t(item.name)
                                               return <item.component {...props} ></item.component>
                                           }}/>
                                )
                            })
                        }
                        <Redirect to="/login" from='/'/>
                    </Switch>
                </Router>
            </div>
        )
    }

    settingUpdate(content) {
        if (content === 'help') {
            this.state.history.push("/helpPage")
            return;
        }

        let contentArr = content.split("/-/");
        if (contentArr.length > 1) {
            if (contentArr[0] === 'language') {
                i18n.changeLanguage(contentArr[1]).then(r => {
                })
            }
        }
    }
}

export default App
