import axios from "axios";
import {fastBaseUrl, tailBaseUrl} from "../config/env";
import qs from 'qs';
import {notification} from 'antd';
import {getDeviceType} from "../common/utils";
import {Dialog} from "antd-mobile";
import i18n from "../config/i18n";


const key = 'keepOnlyOne';

/**
 *  接口请求数据时执行的方法
 *  接受参数为请求的路径apiUrl、请求接口配置参数configObj
 *
 * @param {String} apiUrl            用户传入的请求路径
 * @param params
 * @param startLoading
 * @param endLoading
 * @param baseType
 */
export function getDataFromServer(apiUrl, params, startLoading = () => {
}, endLoading = () => {
}, baseType = 'fast') {
    //用户传入的接口配置参数
    startLoading();
    let modeType = baseType === "fast" ? '出口业务：' : '进口业务：';
    /**
     * 返回的Promise对象含有then、catch方法
     */
    return new Promise(function (resolve, reject) {
        axios({
            url: `${baseType === 'fast' ? fastBaseUrl : tailBaseUrl}${apiUrl}`,
            method: "POST",
            data: qs.stringify(params),
            timeout: 10000,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }).then(function (response) {
            endLoading();
            if (response) {
                response = response.data;
                if (response && response.code === 200) {
                    resolve(response);
                } else {
                    if (getDeviceType() === 'web'){
                        notification.error({
                            key,
                            message: '操作失败',
                            description: modeType + (response.msg || '返回的数据格式有误')
                        });
                        reject(modeType + (response.msg || '返回的数据格式有误'));
                    }else{
                        Dialog.alert({
                            title:i18n.t('networkRequestError'),
                            content: (response.msg || i18n.t('returnDataWrongFormat')),
                            confirmText:i18n.t('submit'),
                        })
                        reject(modeType + (response.msg || i18n.t('returnDataWrongFormat')));
                    }
                }
            } else {
                //处理特殊的情况就是response返回什么也没有
                if (getDeviceType() === 'web'){
                    notification.error({
                        key,
                        message: '操作失败',
                        description: '服务器错误'
                    });
                }else{
                    Dialog.alert({
                        title:i18n.t('networkRequestError'),
                        content: i18n.t('serverError'),
                        confirmText:i18n.t('submit'),
                    })
                }
                reject(`${modeType}${i18n.t('serverError')}`);
            }
        }).catch(function (error) {
            endLoading();
            if (getDeviceType() === 'web'){
                notification.error({
                    key,
                    message: '操作失败',
                    description: modeType + '网络异常,请稍后重试'
                });
            }else{
                Dialog.alert({
                    title:i18n.t('networkRequestError'),
                    content: i18n.t('networkErrorTryAgain'),
                    confirmText:i18n.t('submit'),
                })
            }
            reject(`${modeType}${error}${i18n.t('networkErrorTryAgain')}`);
        })
    })
}


/**
 * 导出表中选中部分数据
 * 1.exportArray、downLoadURL为必填选项
 * 2.当downLoadFileName为null时，按默认名称下载
 * 3.当_this、stateName为null时，导出完成后不清空选中，不为null时，导出完成后清空选中
 *
 * @param {Array}  exportArray          部分导入传入的数组
 * @param {String} downLoadFileName     下载的下来的文件名字
 * @param {String} downLoadURL          服务请求的地址
 * @param {String} _this                this
 * @param {String} stateName            命名的state的名字
 */

export function getStreamData(exportArray, downLoadFileName, downLoadURL, _this, stateName) {

    //如果传入的数组为空直接返回
    if (!(exportArray.length > 0)) {
        notification.warn({
            message: '请先选择导出的数据',
            description: '没有选择要导出的数据无法进行导出'
        });
        return;
    }

    axios({
        method: 'post',
        url: downLoadURL,
        data: JSON.stringify(exportArray),
        headers: {
            'Content-Type': 'application/json'
        },
        responseType: 'blob'
    }).then((response) => {
        if (response) {
            const blob = new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'});
            //下载文件的名称
            let filename = 'excel.xlsx';
            if (downLoadFileName) {
                filename = downLoadFileName;
            }
            //非IE浏览器通过download属性进行下载
            if ('download' in document.createElement('a')) {
                let downloadElememt = document.createElement('a');
                let href = '';
                if (window.URL) {
                    href = window.URL.createObjectURL(blob);
                } else {
                    href = window.webkitURL.createObjectURL(blob);
                }
                downloadElememt.href = href;
                downloadElememt.download = filename;
                document.body.appendChild(downloadElememt);
                downloadElememt.click();
                if (window.URL) {
                    window.URL.revokeObjectURL(href);
                } else {
                    window.webkitURL.revokeObjectURL(href);
                }
                // 导出成功之后清空选中的数据
                if (_this && stateName) {
                    _this.setState({
                        [stateName]: []
                    });
                }
            } else {
                // IE浏览器,通过navigator进行下载,支持IE10+
                if (navigator.msSaveBlob) {
                    // 导出成功之后清空选中的数据
                    if (_this && stateName) {
                        _this.setState({
                            [stateName]: []
                        });
                    }
                    return navigator.msSaveBlob(blob, filename);
                }
            }
        } else {
            notification.warn({
                message: '导出选中数据失败',
                description: '调用接口导出选中的数据失败'
            });
        }

    }).catch(() => {
        notification.error({
            message: '操作失败',
            description: '网络异常,请稍后重试'
        });
    })
}
