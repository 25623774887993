import React from 'react'
import {Image, Input, Button} from "antd-mobile";
import {judgeParamsError} from "../../common/utils";
import {localCache} from "../../api/localCache";
import {marginRight, marginTop, pageColorView} from "../../common/script";
import {serverHelper} from './js/login/ServerLogin'
import {customerHelper} from './js/login/CustomerLogin'
import SelectCountryCode from "../../dialog/mobile/SelectCountryCode";
import {DownOutline} from 'antd-mobile-icons'
import {Translation} from "react-i18next";


export default class FastLoginPage extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight - 208,
            selectModelStatus: false,
            userName: '',
            passWord: '',
            loading: false,
            focusDiv: '',
            showMobileCode: false,
            mobileCode: '86',
        }
    }

    componentDidMount() {
        if (localCache.identity === localCache.CUSTOMER) {
            customerHelper.init();
        }
    }

    inputChange(res, type) {
        this.setState({
            [type]: res.target ? res.target.value : res
        })
    }

    navigationToMainPage(e,t) {
        if (judgeParamsError(this.state.userName, t('accountNotNull'))) {
            return;
        }

        if (judgeParamsError(this.state.passWord, t('pwdNotNull'))) {
            return;
        }

        if (localCache.identity === localCache.CUSTOMER) {
            customerHelper.login(this);
        } else if (localCache.identity === localCache.SERVER) {
            serverHelper.login(this);
        }
    }

    render() {
        return (
            <Translation>
                {(t) => <div style={pageColorView()}>

                    <div className={'flex-col justify-center'} style={{marginTop: '20%'}}>
                        <Image width={100} height={100} src={'/logo192.png'} preview={false}/>
                        <div className={'font-size24 color263238'} style={marginTop(3)}>
                            {
                                localCache.identity === localCache.CUSTOMER ? t('customerLogin') : t('courierLogin')
                            }
                        </div>
                    </div>
                    {
                        localCache.identity === localCache.CUSTOMER ? <div className={'flex-row no-focus-border-input'}
                                                                           style={inputItemView(8, this.state.focusDiv === 'userName')}>
                                <div style={{flex: 1}}>{t('phoneNumber')}</div>
                                <div className={'flex-row justify-between'} style={{flex: 1}} onClick={() => {
                                    this.setState({showMobileCode: true})
                                }}>
                                    <div>{this.state.mobileCode}</div>
                                    <DownOutline style={marginRight(2)}/></div>
                                <Input disabled={this.state.loading}
                                       style={inputView()}
                                       placeholder={t('enterPhone')} value={this.state.userName}
                                       onFocus={() => {
                                           this.updateFocusStyle('userName', true)
                                       }}
                                       onBlur={() => {
                                           this.updateFocusStyle('userName', false)
                                       }}
                                       onChange={(e) => this.inputChange(e, 'userName')}/>
                            </div> :
                            <div className={'flex-row no-focus-border-input'}
                                 style={inputItemView(8, this.state.focusDiv === 'userName')}>
                                <div style={{flex: 1}}>{t('account')}</div>
                                <Input disabled={this.state.loading}
                                       style={inputView()}
                                       placeholder={t('enterAccount')} value={this.state.userName}
                                       onFocus={() => {
                                           this.updateFocusStyle('userName', true)
                                       }}
                                       onBlur={() => {
                                           this.updateFocusStyle('userName', false)
                                       }}
                                       onChange={(e) => this.inputChange(e, 'userName')}/>
                            </div>
                    }
                    <div className={'flex-row no-focus-border-input'}
                         style={inputItemView(2, this.state.focusDiv === 'passWord')}>
                        <div style={{flex: 1}}>{t('password')}</div>
                        <Input disabled={this.state.loading}
                               style={inputView(localCache.identity === localCache.CUSTOMER ? 4 : 3)}
                               type={'password'}
                               onFocus={() => {
                                   this.updateFocusStyle('passWord', true)
                               }}
                               onBlur={() => {
                                   this.updateFocusStyle('passWord', false)
                               }}
                               placeholder={t('enterPwd')} value={this.state.passWord}
                               onChange={(e) => this.inputChange(e, 'passWord')}
                               onEnterPress={(e) => {
                                   this.navigationToMainPage(e,t)
                               }}/>
                    </div>


                    <div className={'flex-row justify-center'}>
                        <Button
                            style={Object.assign({
                                width: '80%',
                                height: '50px',
                                borderRadius: '10px',
                            }, marginTop(4))}
                            color={'primary'}
                            loading={this.state.loading}
                            disable={this.state.loading}
                            onClick={(e)=>{
                                this.navigationToMainPage(e,t)
                            }}>{t('login')}</Button>
                    </div>

                    {
                        localCache.identity === localCache.CUSTOMER ?
                            <SelectCountryCode visible={this.state.showMobileCode} onClose={(code) => {
                                let updateInfo = {showMobileCode: false}
                                if (code) {
                                    updateInfo.mobileCode = code
                                }
                                this.setState(updateInfo)
                            }}/> : ''
                    }
                </div>}
            </Translation>
        )
    }

    /**
     * 修改获取焦点样式
     */
    updateFocusStyle(focusDiv, Status) {
        this.setState({
            focusDiv: Status ? focusDiv : ''
        })
    }
}

function inputItemView(marginTopNum, focusStatus) {
    return Object.assign({
            height: '50px',
            width: '80%',
            marginLeft: '10%',
            fontSize: '15px',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid',
            borderBottomColor: focusStatus ? '#0097A7' : '#dfdfdf'
        },
        marginTop(marginTopNum))
}

function inputView(flex = 3) {
    return {
        fontSize: '15px',
        flex: flex,
        border: 0,
    }
}
