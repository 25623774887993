import React from "react";
import {BrowserQRCodeReader} from '@zxing/browser';
import {Button, NoticeBar} from 'antd-mobile';
import {errorMsgCode} from "../../api/dataCommon";


export default class ScanCodePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            videoDeviceID: "",
            codeReader: new BrowserQRCodeReader(),
            errorCode: 0
        }
        BrowserQRCodeReader.listVideoInputDevices()
            .then(videoInputDevices => {
                if (videoInputDevices.length <= 0) {
                    this.setState({
                        errorCode: 1011
                    })
                    return
                }
                this.setState({
                    videoDeviceID: videoInputDevices[0].deviceId,
                })
            })
            .catch(err => {
                console.log(err);
            })
    }

    decode() {
        this.state.codeReader.decodeOnceFromVideoDevice(this.state.videoDeviceID, 'video')
            .then((result) => {
                console.log(result);
                // setResult(result.text);
            }).catch((err) => {
            console.log(err.toString());
        })
    }

    render() {
        return (
            <div>
                {
                    this.state.errorCode > 0 ?
                        <NoticeBar content={errorMsgCode[this.state.errorCode]} color='error'/> : ''
                }
                <video id="video" width="200" height="200" style={{border: '1px solid gray', margin: '30px'}}/>
                <Button style={{margin: '30px'}} size="lg" color="primary"
                        onClick={() => this.decode(this.state.reader,)}>扫一扫</Button>
            </div>
        );
    }
}
