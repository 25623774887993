import React from 'react';
import {Popup, CheckList, SearchBar, Empty} from 'antd-mobile';
import {marginLeft, marginRight, marginTop} from "../../common/script";
import {getDataFromServer} from "../../api/request";
import {hideLoading, showLoading} from "../../common/utils";
import i18n from "./../../config/i18n";

export default class SelectCountry extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            dataList: [],//国家列表
            searchStr:'',//搜索内容
        }
    }

    componentDidMount() {
        this.getDataList();
    }

    getDataList() {
        getDataFromServer('system/getAllMobileCode', {}, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            this.setState({
                dataList: res.value
            })
        })
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            let updateInfo = {visible: this.props.visible};
            if (this.props.visible) {
                let list = this.state.dataList;
                for (let i = 0; i < list.length; i++) {
                    list[i].hidden = false;
                }
                updateInfo.searchStr = '';
                updateInfo.dataList = list;
            }
            this.setState(updateInfo)
        }
    }

    hideVisibleClick(selectInfo) {
        this.props.onClose(selectInfo);
    }

    searchCustomerByMobile(customer) {
        let dataList = this.state.dataList;
        let hidden;
        let searchInfo;
        for (let i = 0; i < dataList.length; i++) {
            searchInfo = `${dataList[i].countryName}-${dataList[i].countryEnName}-${dataList[i].shortName}`
            if (customer) {
                hidden = searchInfo.indexOf(customer) === -1
            } else {
                hidden = false;
            }
            dataList[i].hidden = hidden;
        }
        this.setState({
            dataList: dataList,
            searchStr:customer
        })
    }

    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={{
                       borderTopLeftRadius: '8px',
                       borderTopRightRadius: '8px',
                       maxHeight:"60vh",
                       minHeight: '40vh',
                   }}
                   stopPropagation={['enter']}
            >
                <SearchBar  value={this.state.searchStr} style={Object.assign(marginTop(2), marginLeft(1), marginRight(1))} placeholder={i18n.t('searchContent')}
                        showCancelButton onChange={(e) => this.searchCustomerByMobile(e)}/>
                <CheckList value={''} style={contentView} onChange={(selectInfo) => {
                    this.hideVisibleClick(selectInfo)
                }}>
                    {
                        this.state.dataList.map((item, index) => {
                            return item.hidden ? '' : <CheckList.Item
                                key={index}
                                value={`${item.countryName}&&${item.id}&&${item.mobileCode}&&${item.countryEnName}`}>
                                {item.countryName}-{item.countryEnName}-{item.shortName}
                            </CheckList.Item>
                        })
                    }
                    {
                        this.state.dataList.length === 0 ? <Empty description={i18n.t('empty')}/> : <div/>
                    }
                </CheckList>

            </Popup>
        )
    }
}


const contentView = {
    height: window.innerHeight * 0.4,
    overflowY: 'scroll',
    marginTop: '10px'
}

