import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {hideLoading, showLoading} from "../../../../common/utils";

export const serverHelper = {

    getDataList(that, pageIndex) {
        let url;
        if (that.state.tabIndex === 'direct') {
            url = 'orderAudit/getDirectOrderPage';
        } else if (that.state.tabIndex === 'collect') {
            url = 'collectionOrderAudit/getSingleOrderList';
        } else {
            return;
        }
        return getDataFromServer(
            url,
            {
                token: localCache.token,
                pageIndex: pageIndex,
                pageSize: 10,
                isMy: 1,
                orderType:0
            },
            () => {
                showLoading()
            },
            () => {
                hideLoading()
            }
        )
    }
}
