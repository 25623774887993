import i18n from "./../config/i18n";

export function getMailTypeList() {
    return [{label: i18n.t('document'), value: 0}, {
        label: i18n.t('package'),
        value: 1
    }, {label: i18n.t('waterproofBag'), value: 2}];//包裹类型
}

export function getCargoTypeList() {
    return [{label: 'FBA', value: 0}, {label: i18n.t('commercialExpress'), value: 1}, {label: i18n.t('smallPackage'), value: 2}, {
        label: i18n.t('eCommerceGoods'),
        value: 3
    }, {label: i18n.t('specialLine'), value: 4}, {label: i18n.t('generalCargo'), value: 5}];//货物类型
}


export const SUBMIT_CODE = "wms-submit-code";
export const INJECT_INFO = "woodx3-android-device";

export const errorMsgCode = {1011: '未检测到摄像头'};

//路径白名单不受跳转限制
export const routeWhiteList = ["/helpPage", "/helpSearchResultPage"];

export const languageList = [{text: '简体中文', key: 'zh'}, {text: 'English', key: 'en'}];


export const cargoInfo = {
    sort:0,
    cargoNameEn: '',
    cargoQuantity: 1,
    cargoWeight: '',
    cargoPrice: '',
    imgUrl: {url: ''},
    cargoBrand: "",
    cargoHsCode: "",
    cargoName: "",
    cargoOriginCountry: "",
    cargoTotalPrice: "",
    cargoType: 0,
    cargoUnit: "",
    dealEnterpriseId: "",
    dealEnterpriseName: "",
    enterpriseId: "",
    materialQuality: "",
    postalTax: "",
    purpose: "",
    sellUrl: "",
    specificationType: "",
    status: "",
    stockRemark: "",
}
