import {getDataFromServer} from "../../../../api/request";
import {localCache} from "../../../../api/localCache";
import {customerJoinId} from "../../../../config/env";

export const customerHelper = {
    init: function () {
        localCache.joinId = customerJoinId;
    },

    login: function (that) {
        getDataFromServer('user/member/webLogin', {
            phone: that.state.userName,
            mobileCode: that.state.mobileCode,
            password: that.state.passWord,
            joinId: localCache.joinId
        }, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        }).then(res => {
            localCache.token = res.value.member.token
            localCache.qiNiuToken = res.value.member.qinniutoken
            this._getJoinInfo(that);
        })
    },

    _getJoinInfo: function (that) {
        getDataFromServer('sysJoinBusiness/getClientJoinInfo', {
            joinId:localCache.joinId
        }, () => {
            that.setState({
                loading: true,
            })
        }, () => {
            that.setState({
                loading: false,
            })
        }).then(res => {
            localCache.joinCurrency = res.value.defaultCurrency
            that.props.history.push("/fastMainPage")
        })
    }
}
