import React from 'react';
import {itemView, marginBottom, marginLeft, marginRight, pageColorView, scrollNowView} from "../../common/script";
import {Button, Input, Picker, Tag,Dialog} from "antd-mobile";
import {RightOutlined} from '@ant-design/icons';
import {customerHelper} from "./js/placeOrder/Customer";
import SelectCountry from "../../dialog/mobile/SelectCountry";
import {getCargoTypeList, getMailTypeList} from "../../api/dataCommon";
import ProductDialog from "../../dialog/mobile/ProductDialog";
import {judgeNumberInput, judgeParamsError} from "../../common/utils";
import {Translation} from "react-i18next";
import i18n from "../../config/i18n";


export default class EstimateFreightPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            className: '',
            classId: '',
            selectCountryType: '',//选择国家类型 send 寄件  consignee收件
            send: {//寄件国家
                countryId: '',
                countryName: '',
                countryEnName: '',
            },
            consignee: {//收件国家
                countryId: '',
                countryName: '',
                countryEnName: '',
            },
            mailType: 1,//包装类型
            cargoType: 1,//货物类型
            showProductDialog: false,//显示产品弹窗
            productList: [],//产品列表
            originWeight: '',//初始重量
            estimateWeight: '',//预计重量
            cargoNum: 1,//包裹数目
        }
    }

    componentDidMount() {
        this.getDiscountListByMobile();
    }

    render() {
        let mailTypeList = getMailTypeList();
        let cargoTypeList = getCargoTypeList();
        return (
            <Translation>
                {
                    (t)=><div style={pageColorView("#F7F7F7")}>
                        <div style={scrollNowView}>
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('affGroup')}</div>
                                        <div className={'flex-row'} onClick={async () => {
                                            let value = await Picker.prompt({columns: this.state.classList})
                                            if (value != null && value.length > 0) {
                                                value = value[0].split("&&");
                                                this.setState({
                                                    className: value[0],//选择群组名称
                                                    classId: value[1],//选择群组id
                                                })
                                            }
                                        }}>
                                            <div style={marginRight(1)}>{this.state.className || t('choose')}</div>
                                            <RightOutlined/></div>
                                    </div>
                                ), 1)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('shippingCountry')}</div>
                                        <div className={'flex-row'} onClick={() => {
                                            this.setState({selectCountryStatus: true, selectCountryType: 'send'})
                                        }}>
                                            <div style={marginRight(1)}>
                                                {(i18n.language === 'en'?this.state.send.countryEnName:this.state.send.countryName) || t('choose')}
                                            </div>
                                            <RightOutlined/></div>
                                    </div>
                                ), 2)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('destCountry')}</div>
                                        <div className={'flex-row'} onClick={() => {
                                            this.setState({selectCountryStatus: true, selectCountryType: 'consignee'})
                                        }}>
                                            <div style={marginRight(1)}>
                                                {(i18n.language === 'en'?this.state.consignee.countryEnName:this.state.consignee.countryName) || t('choose')}
                                            </div>
                                            <RightOutlined/></div>
                                    </div>
                                ), 2)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('packType')}</div>
                                        <div className={'flex-row'} onClick={async () => {
                                            let value = await Picker.prompt({columns: [mailTypeList]});
                                            if (value != null && value.length > 0) {
                                                this.setState({
                                                    mailType: value[0],//包装类型
                                                })
                                            }
                                        }}>
                                            <div style={marginRight(1)}>{mailTypeList[this.state.mailType].label || t('choose')}</div>
                                            <RightOutlined/></div>
                                    </div>
                                ), 2)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('cargoType')}</div>
                                        <div className={'flex-row'} onClick={async () => {
                                            let value = await Picker.prompt({columns: [cargoTypeList]})
                                            if (value != null && value.length > 0) {
                                                this.setState({
                                                    cargoType: value[0],//包装类型
                                                })
                                            }
                                        }}>
                                            <div
                                                style={marginRight(1)}>{cargoTypeList[this.state.cargoType].label || t('choose')}</div>
                                            <RightOutlined/></div>
                                    </div>
                                ), 2)
                            }
                            {
                                itemView((
                                    <div className={'flex-row justify-between'}>
                                        <div>{t('estWeight')}(Kg)</div>
                                        <Input className={'mobile-input-text-end width50p'} type={'number'} placeholder={t('input')}
                                               value={this.state.originWeight}
                                               clearable onChange={(e) => this.inputChange(e, 'originWeight')}/>
                                    </div>
                                ), 2)
                            }
                        </div>

                        <div className={'font-size12 '} style={marginLeft(1)}><Tag>{t('shipEstInst')}</Tag></div>
                        <div className={'font-size12 color999'}
                             style={Object.assign(marginLeft(1), marginRight(1), marginBottom(1))}>{t('shipEstInstInfo')}<a href={'tel://400 000 0000'}>400 000 0000</a>
                        </div>
                        <Button color={'primary'} style={{borderRadius: 0, height: '50px'}} onClick={() => {
                            this.searchProductClick(t);
                        }}>{t('inquire')}</Button>
                        <SelectCountry visible={this.state.selectCountryStatus} onClose={(countryInfo) => {
                            let updateInfo = {selectCountryStatus: false};
                            if (countryInfo && countryInfo.length > 0) {
                                countryInfo = countryInfo[0].split("&&");
                                countryInfo = {
                                    countryId: countryInfo[1],
                                    countryName: countryInfo[0],
                                    countryEnName: countryInfo[3],
                                }
                                updateInfo[this.state.selectCountryType] = countryInfo
                            }
                            this.setState(updateInfo);
                        }}/>
                        <ProductDialog visible={this.state.showProductDialog}
                                       cargoNum={this.state.cargoNum}
                                       productList={this.state.productList}
                                       onClose={() => {
                                               this.setState({showProductDialog: false})
                                           }}/>
                    </div>
                }
            </Translation>

        );
    }

    getDiscountListByMobile() {
        customerHelper.getDiscountList(this).then(res => {
            let classList = [];
            let reposeList = res.value.discountGroupList;
            for (let item of reposeList) {
                classList = [...classList, {
                    label: item.groupName,
                    value: `${item.groupName}&&${item.memberCode}`
                }]
            }
            this.setState({
                classList: [classList]
            })
        })
    }

    inputChange(res, type) {
        let value = res.target ? res.target.value : res;
        if (type === 'originWeight') {
            value = judgeNumberInput(value, 0, '');
        }
        this.setState({
            [type]: value
        })
    }

    /**
     * 点击
     * 查询运价
     */
    searchProductClick(t) {
        let estimateWeight, cargoNum;
        if (judgeParamsError(this.state.classId,t('selectGroup'))){
            return
        }

        if (judgeParamsError(Number(this.state.originWeight || '0'),t('inputWeight'))){
            return
        }else if (this.state.originWeight > 5) {
            estimateWeight = 5;
            cargoNum = Number(this.state.originWeight) / 5
        } else {
            estimateWeight = this.state.originWeight;
            cargoNum = 1;
        }
        this.setState({
            estimateWeight: estimateWeight,
            cargoNum:cargoNum
        }, () => {
            customerHelper.getProductList(this).then(res => {
                if (res.value.length === 0) {
                    Dialog.alert({
                        content: t('noService'),
                        confirmText:t('submit'),
                        onConfirm: () => {

                        },
                    })
                    return
                }
                this.setState({
                    productList: res.value,
                    showProductDialog: true
                })
            })
        })
    }
}
