import React from 'react';
import {Popup, CheckList, SearchBar, Empty} from 'antd-mobile';
import {marginLeft, marginRight, marginTop} from "../../common/script";
import {getDataFromServer} from "../../api/request";
import {localCache} from "../../api/localCache";
import {hideLoading, showLoading} from "../../common/utils";
import i18n from "./../../config/i18n";

export default class SelectCustomer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            customerList: [],//客户列表
        }
    }

    componentDidUpdate() {
        if (this.state.visible !== this.props.visible) {
            this.setState({
                visible: this.props.visible,
            })
        }
    }

    hideVisibleClick(selectInfo) {
        this.props.onClose(selectInfo);
    }

    searchCustomerByMobile(customer) {
        getDataFromServer('sysCustomerUser/getMemberListByJoinId', {
            token: localCache.token,
            mobile: customer,
            joinId: localCache.joinId
        }, () => {
            showLoading()
        }, () => {
            hideLoading()
        }).then(res => {
            this.setState({
                customerList: res.value
            })
        })
    }

    render() {
        return (
            <Popup visible={this.state.visible}
                   onMaskClick={() => {
                       this.hideVisibleClick()
                   }}
                   bodyStyle={{
                       borderTopLeftRadius: '8px',
                       borderTopRightRadius: '8px',
                       maxHeight:"60vh",
                       minHeight: '40vh',
                   }}
                   stopPropagation={['enter']}
            >
                <SearchBar style={Object.assign(marginTop(2), marginLeft(1), marginRight(1))} placeholder={i18n.t('inputMobileNumber')}
                        showCancelButton onSearch={(e) => this.searchCustomerByMobile(e)}/>
                <CheckList style={contentView} onChange={(selectInfo) => {
                    this.hideVisibleClick(selectInfo)
                }}>
                    {
                        this.state.customerList.map((item, index) => {
                            return <CheckList.Item
                                key={index}
                                value={`${item.memberNumber}-${item.nickName}-${item.trueName||''}&&${item.id}&&${item.memberCode}&&${item.mobileCode}&&${item.mobile}`}>
                                {item.memberNumber}-{item.nickName}-{item.trueName||''}
                            </CheckList.Item>
                        })
                    }
                    {
                        this.state.customerList.length === 0 ? <Empty description={i18n.t('searchCustomerFirst')}/> : ''
                    }
                </CheckList>

            </Popup>
        )
    }
}


const contentView = {
    height: window.innerHeight * 0.4,
    overflowY: 'scroll',
    marginTop: '10px'
}
