import React from 'react';
import {
    blackTextView, imageListView,
    itemDetailView,
    itemView, marginBottom,
    marginLeft,
    marginTop,
    pageColorView,
    scrollNowView
} from "../../common/script";
import {localCache} from "../../api/localCache";
import {Button, Divider} from "antd-mobile";
import AddCargoDialog from "../../dialog/mobile/AddCargoDialog";
import {serverHelper} from "./js/orderDetail/Server";
import {customerHelper} from "./js/orderDetail/Customer";
import {decryptInfo} from "../../common/utils";
import ReceivingOrder from "../../dialog/mobile/ReceivingOrder";
import i18n from "./../../config/i18n"
import {getCargoTypeList} from "../../api/dataCommon";


export default class OrderDetailPage extends React.Component {

    constructor(props) {
        super(props);
        let navigationParams = {};
        if (props.location && props.location.search) {
            let narArr = props.location.search.replace("?", "").split("&");
            navigationParams.orderId = narArr[0];
            navigationParams.orderType = narArr[1];
        }
        this.state = {
            orderDetail: {},
            navigationParams: navigationParams,
            attrList: [],
            cargoStatus: false,
            receivingStatus: false,//揽收弹窗状态
            orderCargoList: []
        }
    }

    componentDidMount() {
        this.getOrderDetail();
    }


    getOrderDetail() {
        let request = {};
        if (localCache.identity === localCache.CUSTOMER) {
            request = customerHelper.getOrderDetail(this)
        } else if (localCache.identity === localCache.SERVER) {
            request = serverHelper.getOrderDetail(this)
        }

        if (!request) {
            return;
        }

        request.then(res => {
            let object = {};
            let orderCollectImageList = [];
            for (let item of res.value.orderCollectImageList) {
                orderCollectImageList = orderCollectImageList.concat(item.imageUrl)
            }
            document.title = res.value.serialNo
            res.value.orderCollectImageList = orderCollectImageList;
            if (this.state.navigationParams.orderType === 'direct') {
                object = this.setDirectPageInfo(res.value);
            } else if (this.state.navigationParams.orderType === 'collect') {
                object = this.setCollectPageInfo(res.value);
            }
            object.orderDetail = {
                progress: res.value.progress,
                servicePlatformNo: res.value.servicePlatformNo,
            }
            this.setState(object)
        }).catch(error => {
            this.props.history.goBack()
        })
    }

    render() {
        return <div style={pageColorView("#FAFAFA")}>
            <div style={scrollNowView}>
                {
                    this.state.attrList.map((item, index) => {
                        return <div key={`attrList${index}`}>
                            <div className={'font-size18 color263238'}
                                 style={Object.assign(marginLeft(1), marginTop(1), marginBottom(1))}>
                                {
                                    item.title
                                }
                            </div>
                            {
                                itemView(
                                    <div>
                                        {
                                            (item.itemAttrList || []).map((itemSon, indexSon) => {
                                                return this.itemPageView(itemSon, indexSon);
                                            })
                                        }
                                        {
                                            (item.itemAttrObject || []).map((itemSon, indexSon) => {
                                                return <div>
                                                    {
                                                        indexSon !== 0 ? <Divider/> : ''
                                                    }
                                                    {
                                                        itemSon.map((itemSS, indexSS) => {
                                                            return this.itemPageView(itemSS, indexSS);
                                                        })
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>, 0
                                )
                            }
                        </div>
                    })
                }
            </div>
            {
                this.state.orderDetail.progress && this.state.orderDetail.progress === 200 ?
                    <Button className={'border-radius0'} color={'primary'} size={'large'} onClick={() => {
                        this.setState({
                            receivingStatus: true
                        })
                    }}>{i18n.t('submitCollect')}</Button> : ''
            }
            <AddCargoDialog visible={this.state.cargoStatus} cargoList={this.state.orderCargoList} onlyShowInfo={true}
                            onClose={(cargo) => {
                                this.setState({cargoStatus: false})
                            }}/>
            <ReceivingOrder visible={this.state.receivingStatus} orderId={this.state.navigationParams.orderId}
                            servicePlatform={this.state.orderDetail.servicePlatformNo} onClose={(refresh) => {
                if (refresh) {
                    this.props.history.goBack()
                }
                this.setState({
                    receivingStatus: false
                })
            }}/>
        </div>
    }

    itemPageView(item, index) {
        return itemDetailView(item.title,
            item.imageList ? imageListView(item.imageList) : blackTextView(item.value, item.color, item.onClick),
            index === 0 ? 0 : 1)
    }

    /**
     * 设置直发订单页面显示参数
     * @param responseBean 直发接口返回对象
     */
    setDirectPageInfo(responseBean) {
        let cargoTypeList = getCargoTypeList();
        let senderCountry = "";
        let consigneeCountry = "";
        if (i18n.language === 'en'){
            senderCountry = responseBean.senderCountryEn;
            consigneeCountry = responseBean.consigneeCountryEn;
        }

        if (!senderCountry){
            senderCountry = responseBean.senderCountry;
        }

        if (!consigneeCountry){
            consigneeCountry = responseBean.consigneeCountry;
        }

        let object = {
            attrList: [
                {
                    title: i18n.t('orderInfo'),
                    itemAttrList: [
                        {
                            title: i18n.t('orderStatus'),
                            value: responseBean.progress === 800 || responseBean.progress === 900 ? i18n.t('completed') : i18n.t('inProgress'),
                            color: responseBean.progress === 800 || responseBean.progress === 900 ? '' : 'color52C41A'
                        },
                        {title: i18n.t('ownCustomer'), value: responseBean.trueName},
                        {
                            title: i18n.t('cargoType'),
                            value: responseBean.cargoType < cargoTypeList.length ? cargoTypeList[responseBean.cargoType].label : ''
                        },
                        {title: i18n.t('packageNumEst'), value: responseBean.cargoQuantity},
                        {title: i18n.t('estWeight'), value: responseBean.estimateWeight},
                        {title: i18n.t('service'), value: responseBean.serviceName},
                        {
                            title: i18n.t('paymentMethod'),
                            value: responseBean.taxMethod === 0 ? i18n.t('senderPays') : i18n.t('consigneeReceives')
                        },
                        {
                            title: i18n.t('customerSign'),
                            imageList: responseBean.orderConfirmSign ? [responseBean.orderConfirmSign] : []
                        },
                        {title: i18n.t('customerIdentity'), value: decryptInfo(responseBean.idencryption)},
                        {
                            title: i18n.t('collectPic'),
                            imageList: responseBean.orderCollectImageList || []
                        },
                    ]
                },
                {
                    title: i18n.t('senderInfo'),
                    itemAttrList: [
                        {title: i18n.t('sender'), value: responseBean.senderName},
                        {title: i18n.t('senderPhone'), value: responseBean.senderPhone},
                        {
                            title: i18n.t('senderAddress'),
                            value: `${senderCountry|| ''}${responseBean.senderProvinceName || ''}${responseBean.senderCityName || ''}${responseBean.senderAddress || ''}-${responseBean.senderZipCode || ''}`
                        },
                    ]
                },
                {
                    title: i18n.t('consigneeInfo'),
                    itemAttrList: [
                        {title: i18n.t('consignee'), value: responseBean.consigneeName},
                        {title: i18n.t('consigneePhone'), value: responseBean.consigneePhone},
                        {
                            title: i18n.t('consigneeAddress'),
                            value: `${consigneeCountry|| ''}${responseBean.consigneeProvinceName || ''}${responseBean.consigneeCityName || ''}${responseBean.consigneeAddress || ''}-${responseBean.consigneeZipCode || ''}`
                        },
                    ]
                },
                {
                    title: i18n.t('goodsInfo'),
                    itemAttrObject: []
                }
            ],
        };
        let itemAttrObject = [];
        for (let item of responseBean.preCargoPackageDetailVOList) {
            for (let cargoItem of item.orderCargoList) {
                itemAttrObject.push([
                    {title: i18n.t('goodsName'), value: cargoItem.cargoNameEn},
                    {title: i18n.t('quantity'), value: cargoItem.cargoQuantity},
                    {title: i18n.t('estWeight'), value: `${cargoItem.cargoWeight || '-'} Kg`},
                    {title: i18n.t('unitPrice'), value: `${cargoItem.cargoPrice || '-'} ${localCache.joinCurrency}`},
                    {title: i18n.t('productPicture'), imageList: cargoItem.imgUrl ? [cargoItem.imgUrl] : []},
                ]);
            }
        }
        object.attrList[3].itemAttrObject = itemAttrObject;
        return object;
    }

    /**
     * 设置集运订单页面显示参数
     * @param responseBean 集运接口返回对象
     */
    setCollectPageInfo(responseBean) {
        let object = {
            attrList: [
                {
                    title: i18n.t('orderInfo'),
                    itemAttrList: [
                        {
                            title: i18n.t('orderStatus'),
                            value: responseBean.progress === 800 || responseBean.progress === 900 ? i18n.t('completed') : i18n.t('inProgress'),
                            color: responseBean.progress === 800 || responseBean.progress === 900 ? '' : 'color52C41A'
                        },
                        {title: i18n.t('ownCountry'), value: responseBean.senderCountry},
                        {title: i18n.t('theWarehouse'), value: responseBean.storeName},
                        {title: i18n.t('packageNumEst'), value: responseBean.cargoQuantity},
                        {
                            title: i18n.t('customerSign'),
                            imageList: responseBean.orderConfirmSign ? [responseBean.orderConfirmSign] : []
                        },
                        {title: i18n.t('customerIdentity'), value: decryptInfo(responseBean.idencryption)},
                        {
                            title: i18n.t('collectPic'),
                            imageList: responseBean.orderCollectImageList || []
                        },
                    ]
                },
            ],
        };

        if (localCache.identity === localCache.SERVER) {
            object.attrList[0].itemAttrList = object.attrList[0].itemAttrList.concat({
                title: i18n.t('ownCustomer'),
                value: responseBean.memberTrueName
            });
        }

        if (responseBean.orderDeliveryAddress) {
            let address = responseBean.orderDeliveryAddress;
            object.attrList = object.attrList.concat({
                title: i18n.t('deliveryAddress'),
                itemAttrList: [
                    {title: i18n.t('name'), value: address.deliveryName},
                    {title: i18n.t('phone'), value: address.deliveryPhone},
                    {
                        title: i18n.t('address'),
                        value: `${address.deliveryAddress || ''}`
                    },
                ]
            })
        } else if (responseBean.deliveryName && responseBean.deliveryAddress) {
            object.attrList = object.attrList.concat({
                title: i18n.t('deliveryAddress'),
                itemAttrList: [
                    {title: i18n.t('name'), value: responseBean.deliveryName},
                    {title: i18n.t('phone'), value: responseBean.deliveryPhone},
                    {
                        title: i18n.t('address'),
                        value: `${responseBean.deliveryAddress || ''}`
                    },
                ]
            })
        }

        object.attrList = object.attrList.concat({
            title: i18n.t('consigneeInfo'),
            itemAttrObject: []
        })

        let itemAttrObject = [];
        for (let item of responseBean.collectionOrderAddressVOList || []) {
            let senderCountry = "";
            let consigneeCountry = "";
            if (i18n.language === 'en'){
                senderCountry = item.senderCountryEn;
                consigneeCountry = item.consigneeCountryEn;
            }

            if (!senderCountry){
                senderCountry = item.senderCountry;
            }

            if (!consigneeCountry){
                consigneeCountry = item.consigneeCountry;
            }
            itemAttrObject.push([
                {title: i18n.t('sender'), value: item.senderName},
                {title: i18n.t('senderPhone'), value: item.senderPhone},
                {
                    title: i18n.t('senderAddress'),
                    value: `${senderCountry || ''}${item.senderProvinceName || ''}${item.senderCityName || ''}${item.senderAddress || ''}-${item.senderZipCode || ''}`
                },
                {title: i18n.t('consignee'), value: item.consigneeName},
                {title: i18n.t('consigneePhone'), value: item.consigneePhone},
                {
                    title: i18n.t('consigneeAddress'),
                    value: `${consigneeCountry || ''}${item.consigneeProvinceName || ''}${item.consigneeCityName || ''}${item.consigneeAddress || ''}-${item.consigneeZipCode || ''}`
                },
                {title: i18n.t('service'), value: item.productName},
                {
                    title: i18n.t('goodsInfo'),
                    value: i18n.t('viewInfo'),
                    color: 'colorDefault text-underline',
                    onClick: () => {
                        this.setState({
                            orderCargoList: item.cargoList,
                            cargoStatus: true
                        })
                    }
                },
            ]);
        }
        object.attrList[object.attrList.length - 1].itemAttrObject = itemAttrObject;
        return object;
    }
}
