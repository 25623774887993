import React from 'react';
import {Translation} from "react-i18next";
import {marginLeft, marginTop, pageColorView, scrollNowView} from "../../common/script";
import {SearchBar, Tabs} from "antd-mobile";
import {videoList} from "../../api/helpInfo";
import {DeleteOutline, CloseOutline} from 'antd-mobile-icons';
import {showToast} from "../../common/utils";
import {localCache} from "../../api/localCache";
import i18n from "./../../config/i18n";


export default class HelpPage extends React.Component {


    constructor() {
        super();
        let tabList = [{title: "全部", key: -1}];
        for (let i = 0; i < videoList.length; i++) {
            tabList = tabList.concat({
                title: videoList[i].class, key: i
            })
        }
        this.state = {
            tabList: tabList,
            selectTab: -1,
            dataList: this.getDataList(-1),
            pageStatus: 'normal',//页面状态 normal 正常 search 搜索
            searchHistory: localCache.searchHelpHistory,
            delSearchStatus: false,//删除历史状态
        }
    }

    render() {
        return (
            <Translation>
                {(t) => <div style={pageColorView()}>
                    <div style={topView}>
                        <SearchBar
                            placeholder={t('enterSearch')}
                            showCancelButton
                            style={searchView}
                            cancelText={t('cancel')}
                            onSearch={val => {
                                this.searchData(val);
                            }}
                            onFocus={() => {
                                this.setState({
                                    pageStatus: 'search'
                                })
                            }}
                            onBlur={() => {

                            }}
                            onClear={() => {
                            }}
                            onCancel={() => {
                                this.setState({
                                    pageStatus: 'normal'
                                })
                            }}
                        />
                    </div>
                    {
                        this.state.pageStatus === 'normal' ?
                            <Tabs activeKey={this.state.selectTab} stretch={true} activeLineMode={'fixed'}
                                  onChange={(e) => {
                                      let index = Number.parseInt(e);
                                      this.setState({
                                          selectTab: index,
                                          dataList: this.getDataList(index)
                                      })
                                  }}>
                                {
                                    this.state.tabList.map((item, index) => {
                                        return (
                                            <Tabs.Tab title={index === 0 ? t('all') : t(item.title)} key={item.key}>
                                            </Tabs.Tab>
                                        )
                                    })
                                }
                            </Tabs> : ''
                    }
                    <div style={scrollNowView}>
                        {
                            this.state.pageStatus !== 'search' ? this.normalPage() : this.searchPage(t)
                        }
                    </div>

                </div>}
            </Translation>
        );
    }

    /**
     * 正常的列表页面
     */
    normalPage() {
        return (
            <div>
                {
                    this.state.dataList.map((item, index) => {
                        return helpVideoView(item, index !== this.state.dataList.length - 1)
                    })
                }
            </div>
        )
    }


    /**
     * 搜索模式页面
     */
    searchPage(t) {
        return (
            <div style={searchPage}>
                <div className={'flex-row justify-between font-size18 color999'}
                     style={marginTop(2)}>
                    <div>{t('searchHistory')}</div>
                    <div className={'flex-row'}>
                        {
                            this.state.delSearchStatus ? <div onClick={() => {
                                this.delSearchHistory(-1)
                            }}>{t('delALl')}</div> : ''
                        }
                        {
                            this.state.searchHistory.length > 0 ? <DeleteOutline style={marginLeft(2)} onClick={() => {
                                this.setState({
                                    delSearchStatus: !this.state.delSearchStatus
                                })
                            }}/> : ''
                        }

                    </div>
                </div>

                <div>
                    {this.state.searchHistory.map((item, index) => {
                        return <div className={'flex-row justify-between font-size18 color333'}
                                    style={marginTop(2)}>
                            <div onClick={() => {
                                this.searchData(item);
                            }}>{item}</div>
                            {
                                this.state.delSearchStatus ? <CloseOutline onClick={() => {
                                    this.delSearchHistory(index)
                                }}/> : ''
                            }
                        </div>
                    })}
                </div>
            </div>
        )
    }

    /**
     * 获取列表数据
     * @param selectTab tab下标 按下标搜索
     * @returns {[]}
     */
    getDataList(selectTab) {
        let dataList = [];
        if (selectTab === -1) {
            for (let item of videoList) {
                dataList = dataList.concat(item.data)
            }
        } else {
            dataList = videoList[selectTab].data
        }
        return dataList;
    }

    /**
     *搜索操作
     */
    searchData(searchStr) {
        if (searchStr.length === 0) {
            showToast(i18n.t('enterSearchNotify'));
            return;
        }
        let searchHistory = this.state.searchHistory;
        for (let i = 0; i < searchHistory.length; i++) {
            if (searchHistory[i] === searchStr) {
                searchHistory.splice(i, 1);
                break
            }
        }
        searchHistory.unshift(searchStr);
        if (searchHistory.length > 5) {
            searchHistory.pop()
        }
        localCache.searchHelpHistory = searchHistory;
        this.props.history.push(`/helpSearchResultPage?${searchStr}`)
    }

    /**
     * 删除历史搜索
     * @param index 需要删除的下标 -1 全部删除
     */
    delSearchHistory(index) {
        let searchHistory;
        let updateInfo = {};
        if (index === -1) {
            searchHistory = [];
            updateInfo.delSearchStatus = false;
        } else {
            searchHistory = this.state.searchHistory;
            searchHistory.splice(index, 1)
        }
        updateInfo.searchHistory = searchHistory;
        localCache.searchHelpHistory = searchHistory;
        this.setState(updateInfo);
    }
}

/**
 * 帮助视屏播放ItemView
 * @param item 视频对象
 * @param notLast 不是最后一个Item
 * @returns {*}
 */
export function helpVideoView(item, notLast) {
    return <div>
        <div className={'color333'}
             style={videoTitle}>{i18n.t(item.title) || '-'}</div>
        <div style={videoView}>
            <video src={item.url} width={window.innerWidth - 32} height={192} controls={true}></video>
        </div>
        {
            notLast ? <div style={lineView}></div> : ''
        }
    </div>
}

const topView = {
    width: '100%',
    height: '56px',
    background: '#0097a7',
}

const searchView = {
    width: 'auto',
    borderRadius: '20px',
    margin: '12px 20px 0 20px',
    '--border-radius': '100px',
    '--background': '#ffffff',
    '--height': '32px',
    '--padding-left': '12px',
}


const videoTitle = {
    width: 'auto',
    margin: '16px 16px 0 16px',
    fontSize: '22px'
}


const videoView = {
    width: 'auto',
    margin: '16px',
    height: '192px'
}

const lineView = {
    width: '100%',
    height: '8px',
    background: "#fafafa",
}


const searchPage = {
    width: 'auto',
    margin: '0 16px'
}
