import React from 'react';
import {marginLeft, marginRight, marginTop, pageColorView, scrollNowView, scrollOutView} from "../../common/script";
import {InfiniteScroll, Button, Card} from "antd-mobile";
import {DeleteOutline, EditSFill} from 'antd-mobile-icons'
import {getDataFromServer} from "../../api/request";
import {localCache} from "../../api/localCache";
import {Translation} from "react-i18next";

let pageIndex = 1;
let hasNext = true;

//地址列表
export default class AddressListPage extends React.Component {

    constructor(props) {
        super(props);
        pageIndex = 1;
        hasNext = true;
        this.state = {
            dataList: [],
            addressType: '',
            navigationType: ''
        }
        if (props.location.query) {
            this.state.addressType = props.location.query.addressType
            this.state.navigationType = props.location.query.type
        } else {
            this.props.history.goBack();
        }
    }

    loadMoreData() {
        if (!hasNext || this.state.loading) {
            return;
        } else if (pageIndex === 1) {
            this.setState({
                dataList: []
            })
        }

        let url;
        if (this.state.addressType === 'send') {
            url = 'memberSenderAddress/customerGetMemberSenderAddressList';
        } else if (this.state.addressType === 'consignee') {
            url = 'memberConsigneeAddress/customerGetMemberConsigneeAddressList';
        } else {
            return;
        }
        getDataFromServer(url, {
            token: localCache.token,
            pageSize: 10,
            pageIndex: pageIndex,
            amazonCode: '',
            zipCode: '',
            memberId: '6637383d45594c7dabcc9e11ee7c2eb5',
            countryId: ''
        }, () => {
            this.setState({
                loading: true,
            })
        }, () => {
            this.setState({
                loading: false,
            })
        }).then(res => {
            pageIndex++;
            let dataList = this.state.dataList;
            for (let item of res.value.data) {
                let addItem = {};
                if (this.state.addressType === 'send') {
                    addItem.name = item.senderName;
                    addItem.mobileZoneCode = item.senderMobileZoneCode;
                    addItem.phone = item.senderPhone;
                    addItem.countryName = item.senderCountryName;
                    addItem.countryEnName = item.senderCountryEnName;
                    addItem.provinceName = item.senderProvinceName;
                    addItem.cityName = item.senderCityName;
                    addItem.address = item.senderAddress;
                    addItem.zipCode = item.senderZipCode;
                } else if (this.state.addressType === 'consignee') {
                    addItem.name = item.consigneeName;
                    addItem.mobileZoneCode = item.consigneeMobileZoneCode;
                    addItem.phone = item.consigneePhone;
                    addItem.countryName = item.consigneeCountryName;
                    addItem.countryEnName = item.consigneeCountryEnName;
                    addItem.consigneeCompanyName = item.consigneeCompanyName;
                    addItem.provinceName = item.consigneeProvinceName;
                    addItem.cityName = item.consigneeCityName;
                    addItem.address = item.consigneeAddress;
                    addItem.zipCode = item.consigneeZipCode;
                }
                dataList = dataList.concat(addItem)
            }
            hasNext = res.value.hasNext;
            this.setState({
                dataList: dataList
            })
        })
    };

    selectItemClick(res, item) {
        if (this.state.navigationType === 'select') {
            window.sessionStorage.setItem(this.state.addressType, JSON.stringify(item));
            this.props.history.goBack();
        }
    }

    render() {
        return (
            <Translation>
                {
                    (t) => <div style={Object.assign(scrollOutView(), pageColorView("#F7F7F7"))}>
                        <div style={scrollNowView}>
                            {
                                this.state.dataList.map((item, index) => (
                                    <Card key={index}
                                          style={Object.assign(marginTop(1), marginLeft(1), marginRight(1))}
                                          onClick={(e) => {
                                              this.selectItemClick(e, item)
                                          }}>
                                        <div className={'color263238 font-size15'}>
                                            <span>{item.name}</span>
                                            <span style={marginLeft(1)}>{item.mobileZoneCode}-{item.phone}</span>
                                        </div>
                                        <div
                                            className={'color666666 font-size13'}>{item.countryName || ''}{item.provinceName || ''}{item.cityName || ''}{item.address || ''}{item.zipCode || ''}</div>
                                        <div style={cardFooter} onClick={e => e.stopPropagation()}>
                                            <Button color={'primary'} fill={'none'} size={'mini'}
                                                    style={{color: '#666666'}}>
                                                <EditSFill/>{t('edit')}
                                            </Button>
                                            <Button color={'primary'} fill={'none'} size={'mini'}
                                                    style={{color: '#666666'}}>
                                                <DeleteOutline/> {t('delete')}
                                            </Button>
                                        </div>
                                    </Card>
                                ))}
                            <InfiniteScroll loadMore={() => {
                                this.loadMoreData();
                            }} hasMore={hasNext}/>
                        </div>
                        <div className={'flex-row justify-center'} style={marginTop(1)}>
                            <Button style={btnView} color={'primary'} fill='outline'
                                    onClick={() => this.props.history.goBack()}> {t('back')}</Button>
                            <Button style={Object.assign(btnView, marginLeft(1))} color={'primary'} onClick={() => {
                                this.props.history.push({
                                    pathname: '/addressDetailPage',
                                    query: {addressType: this.state.addressType}
                                })
                            }}>{t('addContact')}</Button>
                        </div>

                    </div>
                }
            </Translation>

        )
    }
}

const cardFooter = {
    marginTop: '8px',
    paddingTop: '11px',
    borderTop: '1px solid #e5e5e5',
    display: 'flex',
    justifyContent: 'flex-end'
}

const btnView = {
    width: '40%',
    marginBottom: '8px'
}
