import { getDataFromServer } from "../../../../api/request";
import { hideLoading, showLoading } from "../../../../common/utils";

export const serverHelper = {
    saveAddress(that) {
        let url;
        
        if (that.props.addressType === 'send' || that.props.addressType === 'delivery') {
            url = 'memberSenderAddress/customerAddAndUpdateMemberSenderAddress';
        } else if (that.props.addressType === 'consignee') {
            url = 'memberConsigneeAddress/customerAddAndUpdateMemberConsigneeAddress';
        } else {
            return;
        }
        return getDataFromServer(url, that.getCurAddressItem(), () => {
            showLoading()
        }, () => {
            hideLoading()
        })
    },
}
